import AirplaneTicketIcon from '@mui/icons-material/AirplaneTicket';
import CarRentalIcon from '@mui/icons-material/CarRental';
import DirectionsIcon from '@mui/icons-material/Directions';
import InfoIcon from '@mui/icons-material/Info';
import LocalHotelIcon from '@mui/icons-material/LocalHotel';
import MapIcon from '@mui/icons-material/Map';
import { Button, Divider, Link } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { useDispatch } from 'react-redux';
import HotelTwoToneIcon from '@mui/icons-material/HotelTwoTone';
import LocalActivityTwoToneIcon from '@mui/icons-material/LocalActivityTwoTone';

import { Activity } from '../../generated/user_graphql';
import {
  setOpenHoursModal,
  setPlaceIdForOpenHours,
} from '../../store/StepSlice';
import { ResponsiveChip } from '../../theme-components/Buttons';
import {
  CustomBookingComWhiteIcon,
  GetYourGuideIcon,
  GetYourGuideIconLetter,
} from '../../theme-components/Icons';
import {
  generateGetYourGuideUrl,
  getPlaceMapUrls,
  openLinkInBrowserNewTab,
} from '../../utils/helpers';
import { CenteredModal, ModalPaper } from '../styling/modal';
import ModalHeader from '../../theme-components/ModalComponents';
import { useEffect, useState } from 'react';
import Space from '../../theme-components/Spacing';
import { PlaceOrActivityMoreInfoModal } from './PlaceMoreInfoModal';

interface ActivityActionsProps {
  activity: Activity;
  hideMoreInfo?: boolean;
  renderingOnFavourite?: boolean;
}

const ActivityCardCTAs: React.FC<ActivityActionsProps> = ({
  activity,
  hideMoreInfo,
  renderingOnFavourite,
}) => {
  const [selectedActivityId, setSelectedActivityId] = useState('');
  const [moreInfoOpened, setMoreInfoOpened] = useState(false);
  const closeModal = () => {
    setMoreInfoOpened(false);
  };

  if (!activity) {
    return null;
  }

  const handleCloseModal = () => {
    setSelectedActivityId('');
  };

  let bookingLink = '';
  if (activity.bookingLink) {
    const affiliateLink = generateGetYourGuideUrl(activity.bookingLink);
    bookingLink = affiliateLink;
  }
  let moreInfoForActivity = false;
  if (activity && activity.bookingLink) {
    moreInfoForActivity = true;
  }

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={1}>
        {!!bookingLink && (
          <Link
            onClick={() => {
              openLinkInBrowserNewTab(bookingLink);
              gtag('event', 'public-link-activity-book-click');
            }}
          >
            <ResponsiveChip
              primary={true}
              type="GET_YOUR_GUIDE"
              mobileCopy="Book"
              desktopCopy="Book"
              icon={LocalActivityTwoToneIcon}
            />
          </Link>
        )}
        {!!moreInfoForActivity && (
          <Link
            onClick={() => {
              gtag('event', 'public-place-more-info-click');
              setSelectedActivityId(activity.id);
            }}
          >
            <ResponsiveChip
              mobileCopy={'Route'}
              desktopCopy={renderingOnFavourite ? 'Maps' : 'Directions'}
              icon={DirectionsIcon}
            />
          </Link>
        )}
        {!hideMoreInfo && (
          <Link
            onClick={() => {
              gtag('event', 'public-place-more-info-click');
              setMoreInfoOpened(true);
            }}
          >
            <ResponsiveChip
              mobileCopy="More"
              desktopCopy={renderingOnFavourite ? 'More' : 'More info'}
              icon={InfoIcon}
            />
          </Link>
        )}
      </Stack>
      {activity &&
        (activity.rating ||
          activity.noOfReviews ||
          moreInfoForActivity ||
          bookingLink) && (
          <Box sx={{ mt: 1.5 }}>
            <Divider />
          </Box>
        )}
      {selectedActivityId === activity.id && (
        <ActivityMoreInfoModal activity={activity} onClose={handleCloseModal} />
      )}
      {moreInfoOpened && (
        <>
          {activity && (
            <PlaceOrActivityMoreInfoModal
              activity={activity}
              onClose={closeModal}
            />
          )}
        </>
      )}
    </>
  );
};

export default ActivityCardCTAs;

interface ActivityMoreInfoModalProps {
  activity: Activity | null;
  onClose: () => void; // Function to handle closing the modal
}
export const ActivityMoreInfoModal: React.FC<ActivityMoreInfoModalProps> = ({
  activity,
  onClose,
}) => {
  // useScript('https://widget.getyourguide.com/dist/pa.umd.production.min.js');

  // useEffect(() => {
  //   // Initialize the widget after the script is loaded
  //   if (window['GetYourGuideWidget']) {
  //     window['GetYourGuideWidget'].init();
  //   }
  // }, [activity]);

  if (!activity) {
    return null;
  }

  return (
    <CenteredModal
      open={true}
      onClose={onClose}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'scroll',
        zIndex: 100000000,
      }}
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent dark background
          backdropFilter: 'blur(10px)', // Apply the blur effect
        },
      }}
    >
      <ModalPaper>
        <ModalHeader
          title="Check Your Meeting Point"
          description=" Please visit the GetYourGuide website to view the exact meeting
              point for your activity."
        />
        <Space size="md" />
        <Button
          variant="contained"
          onClick={() => {
            openLinkInBrowserNewTab(
              generateGetYourGuideUrl(activity.bookingLink!),
            );
          }}
          sx={{
            backgroundColor: '#FF5533',
            borderRadius: '30px',
            paddingX: 3,
            paddingY: 1.5,
            '&:hover': {
              backgroundColor: '#e04e2c',
            },
          }}
          startIcon={<GetYourGuideIconLetter />}
        >
          Visit GetYourGuide
        </Button>
      </ModalPaper>
    </CenteredModal>
  );
};

export const useScript = (src: string) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [src]);
};
