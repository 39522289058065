import { useQuery } from '@apollo/client';
import { Instagram, YouTube } from '@mui/icons-material';
import LanguageTwoToneIcon from '@mui/icons-material/LanguageTwoTone';
import {
  Box,
  Avatar,
  Typography,
  Link,
  IconButton,
  Tooltip,
  Snackbar,
  Alert,
} from '@mui/material';
import { Stack } from '@mui/system';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ShareTwoToneIcon from '@mui/icons-material/ShareTwoTone';
import IosShareIcon from '@mui/icons-material/IosShare';

import { FullScreenBusAnimation } from '../components/animations/BusAnimation';
import VisitedCountriesMap from '../components/country/VisitedCountries';
import { QUERY_USER_PUBLIC_TRIPS } from '../components/gql-public/userPublicTrips';
import { RoutePage } from '../components/route';
import { FollowUser } from '../components/trip-details/TripDetails';
import { PublicTripItem } from '../components/trips-list/PublicTripItem';
import { UserPublicInfo } from '../generated/public_graphql';
import { Trip } from '../generated/user_graphql';
import { useUserData } from '../hooks/useUserData';
import { TikTokIcon } from '../theme-components/Icons';
import { TitleH1 } from '../theme-components/Typography';
import { unauthorisedClient } from '../utils/auth';
import {
  isAppleDevice,
  isRunningStandalone,
  openLinkInBrowserNewTab,
} from '../utils/helpers';

export default function UserPublicTrips() {
  useUserData();
  gtag('event', 'public-country-page');
  const { id } = useParams<{ id: string }>();
  const [isStandalone, setIsStandalone] = useState<boolean>(false); // Default true

  useEffect(() => {
    // Run isRunningStandalone only after the component has mounted
    if (typeof window !== 'undefined') {
      const result = isRunningStandalone();
      setIsStandalone(result);
    }
  }, []); // Empty dependency array ensures this runs only after initial render (on mount)

  const { data, error, loading } = useQuery(QUERY_USER_PUBLIC_TRIPS, {
    client: unauthorisedClient,
    variables: { user_id: id || '' }, // Use id if available
    skip: !id, // Skip query if id is not available
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = ''; // Restore scroll
    document.body.style.position = ''; // Reset positioning
  }, []);

  if (loading) {
    return <FullScreenBusAnimation copy="Almost there!" />;
  }

  if (error) {
    return <>Error loading trips</>;
  }

  const visitedCountries = getAllVisitedCountriesIso3(
    data.userPublicTrips.trips,
  );

  return (
    <Box
      sx={theme => ({
        width: '100%',
        paddingTop: '60px',
        marginTop: '-60px',
        minHeight: '100vh',
        height: '100%',
        backgroundImage: `
                radial-gradient(circle at 30% 50%, rgba(84, 40, 212, 0.2) 0%, rgba(84, 40, 212, 0) 20%),
                radial-gradient(circle at 30% 30%, rgba(100, 180, 255, 0.2) 0%, rgba(100, 180, 255, 0) 25%),
                radial-gradient(circle at 50% 50%, rgba(152, 251, 152, 0.2) 0%, rgba(152, 251, 152, 0) 20%),
                radial-gradient(circle at 60% 60%, rgba(84, 40, 212, 0.2) 0%, rgba(84, 40, 212, 0) 30%),
                radial-gradient(circle at 90% 50%, rgba(100, 180, 255, 0.2) 0%, rgba(100, 180, 255, 0) 35%),
                radial-gradient(circle at 10% 50%, rgba(84, 40, 212, 0.2) 0%, rgba(84, 40, 212, 0) 20%),
                linear-gradient(
                  0deg,
                  rgba(255, 215, 0, 0.15) 0%,
                  rgba(255, 223, 223, 0.1) 50%,
                  rgba(255, 215, 0, 0.15) 100%
                )
              `,
        backgroundPosition: 'center',
        backgroundSize: `100% 100vh`,
        backgroundRepeat: 'repeat',
        [theme.breakpoints.down('lg')]: {
          width: '100%',
          marginTop: '-60px',
          height: '100%',
          backgroundImage: `
                radial-gradient(circle at 30% 50%, rgba(84, 40, 212, 0.2) 0%, rgba(84, 40, 212, 0) 20%),
                radial-gradient(circle at 30% 30%, rgba(100, 180, 255, 0.2) 0%, rgba(100, 180, 255, 0) 25%),
                radial-gradient(circle at 50% 50%, rgba(152, 251, 152, 0.2) 0%, rgba(152, 251, 152, 0) 20%),
                radial-gradient(circle at 60% 60%, rgba(84, 40, 212, 0.2) 0%, rgba(84, 40, 212, 0) 30%),
                radial-gradient(circle at 90% 50%, rgba(100, 180, 255, 0.2) 0%, rgba(100, 180, 255, 0) 35%),
                radial-gradient(circle at 10% 50%, rgba(84, 40, 212, 0.2) 0%, rgba(84, 40, 212, 0) 20%),
                linear-gradient(
                  0deg,
                  rgba(255, 215, 0, 0.15) 0%,
                  rgba(255, 223, 223, 0.1) 50%,
                  rgba(255, 215, 0, 0.15) 100%
                )
              `,
          backgroundPosition: 'center',
          backgroundSize: `100% 100vh`,
          backgroundRepeat: 'repeat',
        },
        [theme.breakpoints.down('md')]: {
          width: '100%',
          marginTop: '-60px',
          paddingTop: '60px',
          height: '100%',
          backgroundImage: `
                  radial-gradient(circle at 10% 20%, rgba(84, 40, 212, 0.2) 0%, rgba(84, 40, 212, 0) 20%),
                  radial-gradient(circle at 30% 30%, rgba(100, 180, 255, 0.2) 0%, rgba(100, 180, 255, 0) 25%),
                  radial-gradient(circle at 50% 50%, rgba(152, 251, 152, 0.2) 0%, rgba(152, 251, 152, 0) 20%),
                  radial-gradient(circle at 70% 70%, rgba(84, 40, 212, 0.2) 0%, rgba(84, 40, 212, 0) 30%),
                  radial-gradient(circle at 90% 70%, rgba(100, 180, 255, 0.2) 0%, rgba(100, 180, 255, 0) 35%),
                  radial-gradient(circle at 80% 60%, rgba(152, 251, 152, 0.2) 0%, rgba(152, 251, 152, 0) 30%),
                  linear-gradient(
                    0deg, 
                    rgba(255, 215, 0, 0.15) 0%, 
                    rgba(255, 223, 223, 0.1) 50%, 
                    rgba(255, 215, 0, 0.15) 100%
                  )
                `,
          backgroundPosition: 'center',
          backgroundSize: `100% 100vh`,
          backgroundRepeat: 'repeat',
        },
      })}
    >
      <RoutePage>
        {/* <OuterContainer>
          <GradientContainer> */}
        <Box sx={{ minHeight: '100vh', paddingBottom: 10, width: '100%' }}>
          {data &&
            data.userPublicTrips &&
            data.userPublicTrips &&
            data.userPublicTrips.publicUserInfo && (
              <Stack
                direction="row"
                sx={theme => ({
                  width: '100%',
                  borderRadius: '40px',
                  // border: '2px solid rgba(211, 211, 211, 0.8)',
                  // backgroundColor: '#FFF8EE',
                  backgroundColor: 'rgba(255, 248, 238, 0.7)',
                  marginBottom: 3,
                  marginTop: 3,
                  paddingX: 4,
                  paddingY: 4,
                  // borderTopLeftRadius: '40px',
                  // borderTopRightRadius: '40px',
                  // borderBottomLeftRadius: '40px',
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                  [theme.breakpoints.down('md')]: { width: '100%' },
                  [theme.breakpoints.down('sm')]: {
                    marginBottom: 1.5,
                    paddingX: 2,
                    paddingY: 2,

                    // borderTopLeftRadius: '40px',
                    // borderTopRightRadius: '40px',
                    // borderBottomLeftRadius: '40px',
                    // backgroundColor: 'transparent',
                  },
                })}
              >
                {/* <TitleH1>Visited Countries</TitleH1> */}
                {/* <Box
                sx={{ height: '200px', width: '100%', borderRadius: '20px' }}
              >
                <VisitedCountriesMap visitedCountries={visitedCountries} />
              </Box> */}
                <Stack direction="column" spacing={2} sx={{ width: '100%' }}>
                  <Box sx={{ width: '100%', alignItems: 'center' }}>
                    <UserProfile
                      publicUserInfo={data.userPublicTrips.publicUserInfo}
                    />
                  </Box>
                  <Box
                    sx={theme => ({
                      height: '350px',
                      width: '100%',
                      borderRadius: '20px',
                      [theme.breakpoints.down('sm')]: { height: '200px' },
                    })}
                  >
                    <VisitedCountriesMap visitedCountries={visitedCountries} />
                  </Box>
                </Stack>
              </Stack>
            )}

          {data && data.userPublicTrips && data.userPublicTrips.trips && (
            <Stack
              direction="column"
              sx={theme => ({
                width: '100%',
                borderRadius: '40px',
                // border: '2px solid rgba(211, 211, 211, 0.8)',
                // backgroundColor: '#FFF8EE',
                backgroundColor: 'rgba(255, 248, 238, 0.7)',
                marginBottom: 3,
                paddingLeft: 4,
                paddingY: 4,
                // borderTopLeftRadius: '40px',
                // borderTopRightRadius: '40px',
                // borderBottomLeftRadius: '40px',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                [theme.breakpoints.down('sm')]: {
                  marginBottom: 1.5,
                  paddingX: 2,
                  paddingY: 2,
                  // borderTopLeftRadius: '40px',
                  // borderTopRightRadius: '40px',
                  // borderBottomLeftRadius: '40px',
                  // backgroundColor: 'transparent',
                },
              })}
            >
              <TitleH1>My Getaways</TitleH1>
              <PublicTripItem trips={data.userPublicTrips.trips} />
            </Stack>
          )}
          {isStandalone && (
            <Box
              sx={{
                paddingBottom: 'calc(70px + env(safe-area-inset-bottom))',
              }}
            />
          )}
          {/* </GradientContainer>
        </OuterContainer> */}
        </Box>
      </RoutePage>
    </Box>
  );
}

interface UserProfileProps {
  publicUserInfo: UserPublicInfo;
}

const UserProfile: React.FC<UserProfileProps> = ({ publicUserInfo }) => {
  const {
    nickName,
    tikTokUrl,
    instagramUrl,
    youTubeUrl,
    profilePictureUrl,
    blogUrl,
  } = publicUserInfo;
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  let isApple = false;
  if (isAppleDevice()) {
    isApple = true;
  }

  const handleCopyLink = () => {
    navigator.clipboard.writeText(window.location.href);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box
      // width="100%"
      sx={{
        // width: 'fit-content', // Adjust width to content
        // margin: '0 auto', // Center horizontally
        // width="100%"
        // p: 2, // Padding around the box
        display: 'flex', // Use flexbox for layout
        alignItems: 'center', // Align items vertically centered
        justifyContent: 'center',
      }}
    >
      {/* Profile picture on the left */}
      <Avatar
        src={profilePictureUrl ?? undefined} // Use profilePictureUrl if available
        alt={nickName ?? undefined} // Alt text for avatar
        sx={theme => ({
          width: 80,
          height: 80,
          marginRight: 2,
          [theme.breakpoints.down('sm')]: {
            width: 80,
            height: 80,
            marginRight: 2,
          },
        })} // Add margin to the right of the avatar
      />

      {/* Nickname and social media on the right */}
      <Box>
        {/* Nickname */}
        {nickName && (
          <Stack direction="row" sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              sx={theme => ({
                fontSize: 18,
                fontWeight: 600,
                marginRight: 2,
                [theme.breakpoints.down('sm')]: {
                  // fontSize: 16,
                },
              })}
            >
              @{nickName}
            </Typography>

            <FollowUser publicUser={publicUserInfo} />
          </Stack>
        )}

        {/* Social media icons in a row */}
        <Box display="flex" gap={0.5} ml={-1}>
          {blogUrl ? (
            <Link
              onClick={() => {
                openLinkInBrowserNewTab(blogUrl);
                gtag('event', 'public-link-maps-url');
              }}
              sx={theme => ({
                textDecoration: 'none',
                color: theme.palette.icon.main,
              })} // Remove underline
            >
              {/* <Stack
                direction="row"
                alignItems="center" // Align icon and text vertically
                spacing={1}
                sx={{}}
              > */}
              <IconButton sx={{ marginRight: 0 }}>
                <LanguageTwoToneIcon
                  sx={theme => ({
                    width: 25,
                    height: 25,
                    color: '#F39C6A',
                    [theme.breakpoints.down('sm')]: {},
                  })}
                />
              </IconButton>
            </Link>
          ) : (
            <Tooltip
              enterTouchDelay={0}
              leaveTouchDelay={3000}
              title="Blog not linked yet"
            >
              <span>
                {/* Wrapping in a span is necessary to handle disabled prop on IconButton */}
                <IconButton
                  disabled={true}
                  sx={{ color: '#d3d3d3', marginRight: 0 }}
                >
                  <LanguageTwoToneIcon
                    sx={theme => ({
                      width: 25,
                      height: 25,
                      [theme.breakpoints.down('sm')]: {},
                    })}
                  />
                </IconButton>
              </span>
            </Tooltip>
          )}
          {tikTokUrl ? (
            <Link
              onClick={() => {
                openLinkInBrowserNewTab(tikTokUrl);
                gtag('event', 'open-user-tiktok');
              }}
            >
              <IconButton sx={{ marginRight: 0 }}>
                <TikTokIcon
                  sx={theme => ({
                    width: 25,
                    height: 25,
                    color: '#F39C6A',
                    [theme.breakpoints.down('sm')]: {},
                  })}
                />
              </IconButton>
            </Link>
          ) : (
            <Tooltip
              enterTouchDelay={0}
              leaveTouchDelay={3000}
              title="TikTok account not linked yet"
            >
              <span>
                {/* Wrapping in a span is necessary to handle disabled prop on IconButton */}
                <IconButton
                  disabled={true}
                  sx={{ color: '#d3d3d3', marginRight: 0 }}
                >
                  <TikTokIcon
                    sx={theme => ({
                      width: 25,
                      height: 25,
                      [theme.breakpoints.down('sm')]: {},
                    })}
                  />
                </IconButton>
              </span>
            </Tooltip>
          )}

          {youTubeUrl ? (
            <Link
              onClick={() => {
                openLinkInBrowserNewTab(youTubeUrl);
                gtag('event', 'open-user-youtube');
              }}
            >
              <IconButton sx={{ marginRight: 0 }}>
                <YouTube
                  sx={theme => ({
                    width: 25,
                    height: 25,
                    color: '#F39C6A',
                    [theme.breakpoints.down('sm')]: {},
                  })}
                />
              </IconButton>
            </Link>
          ) : (
            <Tooltip
              enterTouchDelay={0}
              leaveTouchDelay={3000}
              title="YouTube account not linked yet"
            >
              <span>
                {/* Wrapping in a span is necessary to handle disabled prop on IconButton */}
                <IconButton
                  disabled={true}
                  sx={{ color: '#d3d3d3', marginRight: 0 }}
                >
                  <YouTube
                    sx={theme => ({
                      width: 25,
                      height: 25,
                      [theme.breakpoints.down('sm')]: {},
                    })}
                  />
                </IconButton>
              </span>
            </Tooltip>
          )}

          {instagramUrl ? (
            <Link
              onClick={() => {
                openLinkInBrowserNewTab(instagramUrl);
                gtag('event', 'open-user-instagram');
              }}
            >
              <IconButton sx={{ marginRight: 0 }}>
                <Instagram
                  sx={theme => ({
                    width: 25,
                    height: 25,
                    color: '#F39C6A',
                    [theme.breakpoints.down('sm')]: {},
                  })}
                />
              </IconButton>
            </Link>
          ) : (
            <Tooltip
              enterTouchDelay={0}
              leaveTouchDelay={3000}
              title="Instagram account not linked yet"
            >
              <span>
                {/* Wrapping in a span is necessary to handle disabled prop on IconButton */}
                <IconButton
                  disabled={true}
                  sx={{ color: '#d3d3d3', marginRight: 0 }}
                >
                  <Instagram
                    sx={theme => ({
                      width: 25,
                      height: 25,
                      [theme.breakpoints.down('sm')]: {},
                    })}
                  />
                </IconButton>
              </span>
            </Tooltip>
          )}

          {/* Wrapping in a span is necessary to handle disabled prop on IconButton */}
          <IconButton
            disabled={false}
            onClick={handleCopyLink}
            sx={{ marginRight: 0 }}
          >
            {isApple ? (
              <IosShareIcon
                sx={theme => ({
                  marginTop: -0.5,
                  width: 25,
                  height: 25,
                  color: theme.palette.primary.light,
                  [theme.breakpoints.down('sm')]: {},
                })}
              />
            ) : (
              <ShareTwoToneIcon
                sx={theme => ({
                  width: 25,
                  height: 25,
                  color: theme.palette.primary.light,

                  [theme.breakpoints.down('sm')]: {},
                })}
              />
            )}
          </IconButton>
        </Box>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ zIndex: 100000 }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          variant="filled"
        >
          Profile link copied to clipboard!
        </Alert>
      </Snackbar>
    </Box>
  );
};

const getAllVisitedCountriesIso3 = (trips: Trip[]): string[] => {
  const visitedCountriesIso3: Set<string> = new Set();

  trips.forEach(trip => {
    trip.countries?.forEach(country => {
      if (country) {
        visitedCountriesIso3.add(country.iso3Code); // Add the iso3Code
      }
    });
  });

  return Array.from(visitedCountriesIso3); // Convert to array to avoid duplicates
};
