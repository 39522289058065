import { gql } from '@apollo/client';
import { ACTIVITY_FIELDS } from './schemaActivity';
import { PLACE_FIELDS } from './schemaPlace';

export const RECOMMENDATION_FIELDS = gql`
  fragment RecommendationFields on Recommendation {
    id
    name
    type
    description
    url
    latitude
    longitude
    locationUrl
    place {
      ...PlaceFields
    }
    activity {
      ...ActivityFields
    }
    linkedImages {
      path
      id
      mediumPath
      smallPath
      previewPath
      fromOwner
    }
    images {
      path
      smallPath
      mediumPath
      previewPath
      id
    }
  }
  ${ACTIVITY_FIELDS}
  ${PLACE_FIELDS}
`;
