// import React, { useEffect, useRef, useState } from 'react';
// import { Button, Paper, Slide, Typography, useMediaQuery } from '@mui/material';
// import { useTheme } from '@mui/material/styles';
// import ContentCopyIcon from '@mui/icons-material/ContentCopy';

// const CopyTripBox: React.FC = () => {
//   const boxRef = useRef<HTMLDivElement>(null);
//   const [isSticky, setIsSticky] = useState(false);
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down('md'));

//   useEffect(() => {
//     const handleScroll = () => {
//       if (!boxRef.current) return;
//       const { top, bottom } = boxRef.current.getBoundingClientRect();
//       const windowHeight = window.innerHeight;

//       setIsSticky(bottom < 0 || top > windowHeight);
//     };

//     window.addEventListener('scroll', handleScroll);
//     return () => window.removeEventListener('scroll', handleScroll);
//   }, []);

//   const BoxContent = (
//     <>
//       <Typography color="text.primary" sx={{ fontWeight: 600, fontSize: 16 }}>
//         Copy this trip to <strong>your trips </strong>
//         and edit to your liking.
//       </Typography>
//       <Button
//         variant="contained"
//         startIcon={<ContentCopyIcon />}
//         sx={{
//           background: 'linear-gradient(135deg, #42A5F5 0%, #7E57C2 100%)',
//           color: 'white',
//           paddingX: 3,
//           minWidth: '100px',
//           paddingY: 1,
//           marginLeft: 1,
//           borderRadius: '20px',
//           textTransform: 'none',
//           fontWeight: 'bold',
//           '&:hover': {
//             background: 'linear-gradient(135deg, #2196F3 0%, #673AB7 100%)',
//           },
//         }}
//       >
//         Copy
//       </Button>
//     </>
//   );

//   return (
//     <>
//       {/* Main box (non-sticky) */}
//       <Paper
//         ref={boxRef}
//         elevation={3}
//         sx={{
//           display: 'flex',
//           justifyContent: 'space-between',
//           alignItems: 'center',
//           backgroundColor: '#E0F7FA',
//           borderRadius: '20px',
//           paddingX: '16px',
//           paddingY: '12px',

//           maxWidth: '600px',
//           margin: 'auto',
//           position: 'relative',
//           transition: 'all 0.3s ease-in-out',
//         }}
//       >
//         {BoxContent}
//       </Paper>

//       {/* Sticky version (appears when scrolled out of view) */}
//       <Slide direction="up" in={isSticky} mountOnEnter unmountOnExit>
//         <Paper
//           elevation={4}
//           sx={{
//             display: 'flex',
//             justifyContent: 'space-between',
//             alignItems: 'center',
//             backgroundColor: '#E0F7FA',
//             borderRadius: '20px',
//             padding: '16px',
//             maxWidth: '600px',
//             marginX: isMobile ? 1 : 'auto',
//             position: 'fixed',
//             zIndex: 1000,
//             bottom: isMobile ? '80px' : 'auto',
//             top: isMobile ? 'auto' : '60px',
//             left: 0,
//             right: 0,
//           }}
//         >
//           {BoxContent}
//         </Paper>
//       </Slide>
//     </>
//   );
// };

// export default CopyTripBox;
import React, { useEffect, useRef, useState } from 'react';
import { Button, Paper, Slide, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { TripUser } from './TripItinerary';
import { useSelector } from 'react-redux';
import { publicTripsSelector } from '../../store/PublicTripSlice';
import { tripsSelector } from '../../store/TripSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/firebase';
import { useMutation } from '@apollo/client';
import { MUTATION_COPY_TRIP } from '../gql-user/copyPublicTripMutation';
import { Loader } from '../../theme-components/Loader';
import { CenteredModal, ModalPaper } from '../styling/modal';
import { RealisticConfetti } from '../styling/confetti';
import SignUp from '../auth/SignUp';
import { SuccessModalContentTripCoppied } from './CopyTrip';
import { isRunningStandalone } from '../../utils/helpers';

const CopyTripBox: React.FC<{ tripUser: TripUser }> = ({ tripUser }) => {
  const boxRef = useRef<HTMLDivElement>(null);
  const [isSticky, setIsSticky] = useState(false);
  const [boxStyle, setBoxStyle] = useState({ left: 0, width: 0 });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [hasSelectedTrip, setHasSelectedTrip] = useState(false);
  const location = useLocation(); // React Router hook (optional)
  const { isAuthenticated } = useAuth();
  const [isModalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const [isSignUpModalOpen, setSignUpModalOpen] = useState(false);

  const { publicTrip } = useSelector(publicTripsSelector);
  const { trip: userTrip } = useSelector(tripsSelector);
  const trip = tripUser === 'user' ? userTrip : publicTrip;

  const handleOpenSignUp = () => {
    setSignUpModalOpen(true);
  };

  const handleCloseSignUp = () => {
    setSignUpModalOpen(false);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const [copyTrip, { data, loading }] = useMutation(MUTATION_COPY_TRIP, {
    variables: {
      original_trip_id: trip?.id,
      sharable_trip_id: trip?.sharableId,
    },
  });

  useEffect(() => {
    // Get the query parameters from the current URL
    const queryParams = new URLSearchParams(location.search); // or window.location.search
    const hasSelectedTripParam = queryParams.has('selected-trip'); // Check if 'selected-trip' exists

    // Set the boolean value based on the presence of 'selected-trip'
    setHasSelectedTrip(hasSelectedTripParam);
  }, [location]);

  useEffect(() => {
    const handleScroll = () => {
      if (!boxRef.current) return;
      const { top, bottom, left, width } =
        boxRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      setIsSticky(bottom < 0 || top > windowHeight);

      if (!isSticky) {
        setBoxStyle({ left, width });
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isSticky]);

  let fromBottom = '0px';
  const isStandaone = isRunningStandalone();
  if (isMobile) {
    fromBottom = isStandaone
      ? 'calc(65px + env(safe-area-inset-bottom))'
      : '75px';
  } else {
    fromBottom = 'auto';
  }

  const BoxContent = (
    <>
      <Typography color="text.primary" sx={{ fontWeight: 500, fontSize: 16 }}>
        <strong>Copy</strong> this trip to <strong>your trips </strong>
        and edit to your liking.
      </Typography>
      <Button
        variant="contained"
        startIcon={
          loading ? <Loader size={15} color="inherit" /> : <ContentCopyIcon />
        }
        onClick={async () => {
          if (isAuthenticated) {
            gtag('event', 'trip-page-copy-trip');

            if (trip?.tripAccess?.fullViewGranted) {
              try {
                const res = await copyTrip();
                if (
                  res &&
                  res.data &&
                  res.data.copyTrip &&
                  res.data.copyTrip.id
                ) {
                  setModalOpen(true);
                }
              } catch (e) {
                //TODO Handle error to copy trip
              }
            } else {
              window.scrollTo({
                top: document.body.scrollHeight - window.innerHeight - 10,
                behavior: 'smooth', // This makes the scroll smooth
              });
            }
          } else {
            gtag('event', 'trip-page-copy-trip-login');
            // loginWithRedirect({
            //   appState: { targetUrl: window.location.pathname },
            // });
            handleOpenSignUp();
          }
        }}
        sx={{
          background: 'linear-gradient(135deg, #42A5F5 0%, #7E57C2 100%)',
          color: 'white',
          paddingX: 3,
          minWidth: '100px',
          paddingY: 1,
          marginLeft: 1,
          borderRadius: '20px',
          textTransform: 'none',
          fontWeight: 'bold',
          '&:hover': {
            background: 'linear-gradient(135deg, #2196F3 0%, #673AB7 100%)',
          },
        }}
      >
        Copy
      </Button>
    </>
  );

  return (
    <>
      {trip &&
        tripUser !== 'user' &&
        (!trip.tripType || trip.tripType !== 'guide') && (
          <>
            {/* Main box (non-sticky) */}
            <Paper
              ref={boxRef}
              elevation={3}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                backgroundColor: '#E0F7FA',
                borderRadius: '20px',
                paddingX: '16px',
                paddingY: '12px',
                maxWidth: '600px',
                margin: 'auto',
                position: 'relative',
                transition: 'all 0.3s ease-in-out',
              }}
            >
              {BoxContent}
            </Paper>
            {/* Sticky version (appears when scrolled out of view) */}
            <Slide direction="up" in={isSticky} mountOnEnter unmountOnExit>
              <Paper
                elevation={4}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  backgroundColor: '#E0F7FA',
                  borderRadius: '20px',
                  paddingX: '16px',
                  paddingY: '12px',
                  position: 'fixed',
                  zIndex: 1000,
                  bottom: fromBottom,
                  top: isMobile ? 'auto' : '62px',
                  left: `${boxStyle.left}px`,
                  width: `${boxStyle.width}px`,
                  transition: 'left 0.2s ease-in-out, width 0.2s ease-in-out',
                }}
              >
                {BoxContent}
              </Paper>
            </Slide>
          </>
        )}
      {data && data.copyTrip && (
        <CenteredModal
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
          open={isModalOpen}
          onClose={handleCloseModal}
          BackdropProps={{
            style: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent dark background
              backdropFilter: 'blur(10px)', // Apply the blur effect
            },
          }}
        >
          <ModalPaper>
            <SuccessModalContentTripCoppied
              trip={data.copyTrip}
              handleCloseModal={handleCloseModal}
              navigate={navigate}
            />
          </ModalPaper>
        </CenteredModal>
      )}
      {isModalOpen && <RealisticConfetti />}
      <SignUp
        showInModal={true}
        loginDefault={true}
        open={isSignUpModalOpen}
        onClose={handleCloseSignUp}
      />
    </>
  );
};

export default CopyTripBox;
