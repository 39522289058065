import { useLazyQuery, useMutation, useApolloClient } from '@apollo/client';
import ShareTwoToneIcon from '@mui/icons-material/ShareTwoTone';
import IosShareIcon from '@mui/icons-material/IosShare';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import LinkIcon from '@mui/icons-material/Link';

import {
  Alert,
  Box,
  Button,
  Divider,
  FormControlLabel,
  IconButton,
  Snackbar,
  Stack,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setErrorModalOpen, setErrorTitle } from '../../store/ErrorSlice';
import {
  LinkButtonOld,
  PrimaryButton,
  SecondaryButton,
} from '../../theme-components/Buttons';
import {
  Loader,
  LoaderFullScreen,
  LoadingDots,
} from '../../theme-components/Loader';
import ModalHeader from '../../theme-components/ModalComponents';
import Space from '../../theme-components/Spacing';
import TextDescription from '../../theme-components/Typography';
import { isAppleDevice, validateEmail } from '../../utils/helpers';
import { StyledFormControl } from '../create-trip/manage-trip-modals/Styles';
import { MUTATION_UPDATE_SHARED_TRIP_INFO } from '../gql-user/editSharedTripInfoMutation';
import {
  MUTATION_SHARE_TRIP,
  MUTATION_TRIP_SHARABLE_LINK,
} from '../gql-user/shareTrip';
import { QUERY_GET_TRIP_SHARED_INFO } from '../gql-user/tripSharedInfoQuery';
import { CenteredModal, ModalPaper } from '../styling/modal';

import { TripButtons } from './UserTrips';
import {
  QUERY_GET_USER_TRIPS,
  QUERY_GET_USER_TRIPS_WITH_DAYS_ONLY,
} from '../gql-user/userTripsQuery';
import { setUserTrips, tripsSelector } from '../../store/TripSlice';
import { palette, styled, useMediaQuery, useTheme } from '@mui/system';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../auth/firebase';

export default function ShareTripModal(props: {
  tripId: string;
  sharableId: string | null | undefined;
  published: boolean;
  shareButton: 'menu' | 'floating' | 'myTripCard';
}) {
  const { isLoading, isAuthenticated } = useAuth();
  const dispatch = useDispatch();
  const location = useLocation();
  // Check if the current path includes 'editor'
  const isCreateRoutePage = location.pathname.includes('/editor');
  // Check if the current path includes 'editor'
  const isPreviewRoutePage = location.pathname.includes('/preview');
  const { cache } = useApolloClient();
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [updateEmail, setUpdateEmail] = useState('');
  const [canView, setCanView] = useState(true);
  const [canEdit, setCanEdit] = useState(false);
  const [isSharable, setIsSharable] = useState(props.sharableId ? false : true);
  const [sharableId, setSharableId] = useState(props.sharableId);
  const [editId, setEditId] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const { userTrips } = useSelector(tripsSelector);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [shareTrip, { loading }] = useMutation(MUTATION_SHARE_TRIP, {
    variables: {
      trip_id: props.tripId,
      shareWithEmail: email,
      canEdit: canEdit,
      canView: canView,
    },
  });

  const [makeTripSharableLink, { loading: sharableLinkLoading }] = useMutation(
    MUTATION_TRIP_SHARABLE_LINK,
    {
      variables: {
        trip_id: props.tripId,
        sharable: props.sharableId ? false : true,
      },
      refetchQueries: [
        {
          query: QUERY_GET_USER_TRIPS,
          variables: {}, // Add any variables your query needs
          // fetchPolicy: 'network-only',
        },
      ],
      awaitRefetchQueries: true,
      onCompleted: async () => {
        interface QueryResult {
          userTripsList: Array<{ id: string; sharableId?: string }>;
        }
        try {
          // Fetch the updated cache data after refetch completes
          const queryResult = cache.readQuery<QueryResult>({
            query: QUERY_GET_USER_TRIPS,
            variables: {}, // Same variables as in refetchQueries
          });

          if (queryResult && queryResult.userTripsList) {
            const trip = queryResult.userTripsList.find(
              t => t.id === props.tripId,
            );
            if (trip) {
              setSharableId(trip.sharableId ? trip.sharableId : '');
            }
          }
        } catch (error) {
          console.error('Error fetching updated data from cache:', error);
        }
      },
    },
  );

  const [updateSharedTripInfo, { loading: updateLoading }] = useMutation(
    MUTATION_UPDATE_SHARED_TRIP_INFO,
    {
      variables: {
        shared_id: editId,
        trip_id: props.tripId,
        shareWithEmail: updateEmail,
        canEdit: canEdit,
        canView: canView,
      },
    },
  );

  const [getSharedInfo, { data: sharedInfoData, loading: sharedDataLoading }] =
    useLazyQuery(QUERY_GET_TRIP_SHARED_INFO, {
      fetchPolicy: 'no-cache',
      variables: {
        trip_id: props.tripId,
      },
    });

  const handleOpen = async () => {
    setOpen(true);
    try {
      await getSharedInfo();
    } catch (error) {
      console.error('Error fetching shared info:', error);
      // Optionally, display an error message to the user here
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [value, setValue] = useState(0);

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);

    if (newValue === 0) {
      setCanView(true);
      setCanEdit(false);
      setEmail('');
    } else {
      setEditId('');
    }
  };

  let sharableLink = '';

  if (props.published) {
    sharableLink =
      process.env.NODE_ENV === 'development'
        ? 'https://localhost:4001/trip/' + props.tripId
        : 'https://rooutie.com/trip/' + props.tripId;
  } else {
    sharableLink =
      process.env.NODE_ENV === 'development'
        ? 'https://localhost:4001/trip/' + sharableId + '/' + props.tripId
        : 'https://rooutie.com/trip/' + sharableId + '/' + props.tripId;

    // Used for preview only
    // sharableLink =
    //   process.env.NODE_ENV === 'development'
    //     ? 'https://localhost:4001/shared-link/' + sharableId + '/' + props.tripId
    //     : 'https://rooutie.com/shared-link/' + sharableId + '/' + props.tripId;
  }

  const handleCopyLink = () => {
    if (sharableId || props.published) {
      navigator.clipboard.writeText(sharableLink);
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  let isApple = false;
  if (isAppleDevice()) {
    isApple = true;
  }

  let showInviteFriends = false;

  if (isCreateRoutePage && isAuthenticated) {
    showInviteFriends = true;
  }

  if (isPreviewRoutePage && isAuthenticated) {
    userTrips?.forEach(userTrip => {
      if (userTrip.id === props.tripId) {
        showInviteFriends = true;
      }
    });
  }

  if (!showInviteFriends && !props.published && !props.sharableId) {
    return <></>;
  }
  if (sharedDataLoading) {
    return <></>;
  }

  return (
    <>
      {props.shareButton === 'myTripCard' && (
        <Button
          onClick={() => {
            handleOpen();
            gtag('event', 'user-trip-share-chip');
          }}
          sx={{
            padding: 0,
            backgroundColor: 'transparent',
            color: theme => theme.palette.icon.main,
            display: 'flex',
            alignItems: 'center',
            '&:hover': {
              backgroundColor: theme => theme.palette.action.hover,
            },
          }}
        >
          <ShareTwoToneIcon
            sx={{
              width: 20,
              height: 20,
              marginRight: 0.5,
            }}
          />
          <TripButtons>Share</TripButtons>
        </Button>
      )}
      {props.shareButton === 'menu' && (
        // <Button
        //   variant="contained"
        //   onClick={() => {
        //     handleOpen();
        //     gtag('event', 'user-trip-share-chip');
        //   }}
        //   startIcon={
        //     <ShareTwoToneIcon
        //       sx={{
        //         width: 15,
        //         height: 15,
        //       }}
        //     />
        //   }
        //   sx={theme => ({
        //     paddingX: 2,
        //     backgroundColor: theme.palette.primary.light,
        //     borderRadius: '30px',

        //     display: 'flex',
        //     fontSize: 12,
        //     fontWeight: 700,
        //     color: 'white',
        //     alignItems: 'center',
        //     '&:hover': {},
        //   })}
        // >
        //   Share This Trip
        // </Button>
        <Button
          variant="outlined"
          color="primary"
          size="large"
          startIcon={
            isApple ? (
              <IosShareIcon sx={{ marginLeft: 0.5 }} />
            ) : (
              <ShareTwoToneIcon sx={{ marginLeft: 0.5 }} />
            )
          }
          onClick={() => {
            handleOpen();
            gtag('event', 'user-trip-share-chip');
          }}
          sx={theme => ({
            textTransform: 'none',
            borderRadius: '30px',
            fontWeight: 700,
            width: '100%',
            mb: 1,
            fontSize: 13,
            borderWidth: 2,
            borderColor: theme.palette.primary.main,
            // px: 3,
            // ml: 2,
          })}
        >
          Share This Trip
        </Button>
      )}
      {props.shareButton === 'floating' && (
        <>
          <Box
            sx={theme => ({
              position: 'fixed',
              top: '2px',
              left: '0px', // Touch the right edge
              paddingY: isMobile ? 0 : 0.5, // Larger padding to increase size
              paddingX: 0.5,
              zIndex: 10000,
              display: { xs: 'flex', sm: 'none' }, // Show only on small screens
              flexDirection: 'column', // Align icons and titles vertically
              backgroundColor: '#e0ebf5', // Use a light color that blends with the app (adjust to your theme)
              color: '#1c3a57', // Match the text color with your theme
              fontWeight: 'bold', // Make the text more prominent
              borderTopRightRadius: '12px', // Larger radius for a smoother corner
              borderBottomRightRadius: '12px', // Same as above
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)', // Slightly stronger shadow to stand out more
              border: '2px solid rgb(118, 83, 220, 0.6)', // Optional: subtle border to match theme
              '&:hover': {
                backgroundColor: '#e0ebf5', // Slightly darker on hover
              },
            })}
          >
            <IconButton
              onClick={() => {
                handleOpen();
                gtag('event', 'user-trip-share-chip');
              }}
              sx={theme => ({
                // backgroundColor: theme.palette.background.paper, // Using theme color for background
                display: { xs: 'flex', sm: 'none' }, // Show only on small screens
                backgroundColor: 'transparent',
              })}
            >
              {isApple ? (
                <IosShareIcon sx={{ color: '#F39C6A' }} />
              ) : (
                <ShareTwoToneIcon sx={{ color: '#F39C6A' }} />
              )}
            </IconButton>
          </Box>
        </>
      )}
      <CenteredModal
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent dark background
            backdropFilter: 'blur(10px)', // Apply the blur effect
          },
        }}
      >
        <ModalPaper>
          <ModalHeader title="Share with your friends" />
          <Box
            sx={theme => ({
              marginTop: 2,
              width: '100%',
              paddingY: 2,
              paddingX: 3,
              border: '1px solid #ccc',
              borderRadius: 2,
              backgroundColor: '#f9f9f9',
              maxWidth: 500,
              [theme.breakpoints.down('sm')]: {
                paddingX: 2,
              },
            })}
          >
            <Stack spacing={0}>
              <Typography
                textAlign="center"
                sx={{ fontSize: 16, fontWeight: 600, color: '#646464' }}
              >
                Anyone with this link can view this trip
              </Typography>
              <Space size="sm" />

              <Space size="md" />
              {!sharableId && !props.published ? (
                <Button
                  variant="contained"
                  color="primary"
                  // onClick={handleCreateLink}
                  onClick={async () => {
                    gtag('event', 'share-trip-share-with-friend');
                    try {
                      await makeTripSharableLink();
                      await new Promise(resolve => setTimeout(resolve, 500));
                    } catch (e) {
                      dispatch(setErrorTitle('Error sharing trip'));
                      dispatch(setErrorModalOpen(true));
                    }
                  }}
                  disabled={sharableLinkLoading}
                  startIcon={<LinkIcon />}
                  sx={{
                    paddingY: 1,
                    width: '100%',
                    borderRadius: '20px',
                    backgroundColor: '#676BEE',
                    '&:hover, &:visited, &:link, &:active': {
                      textDecoration: 'none',
                    },
                    '&:hover': {
                      backgroundColor: '#4447B1',
                    },
                    '&:disabled': {
                      backgroundColor: '#D6D9FC',
                    },
                  }}
                >
                  {sharableLinkLoading ? (
                    <LoadingDots />
                  ) : (
                    'Create Sharable Link'
                  )}
                </Button>
              ) : (
                <>
                  <TextField
                    label="Sharable Link"
                    value={sharableLink}
                    onChange={e => e.preventDefault()}
                    fullWidth
                  />
                  <Space size="md" />

                  <Stack direction="row" spacing={1} justifyContent="center">
                    {/* {!props.published && (
                      <Button
                        variant="text"
                        color="error"
                        sx={{ width: '100%', borderRadius: '20px' }}
                        onClick={async () => {
                          gtag('event', 'share-trip-share-with-friend');
                          try {
                            await makeTripSharableLink();
                            await new Promise(resolve =>
                              setTimeout(resolve, 500),
                            );
                          } catch (e) {
                            dispatch(setErrorTitle('Error sharing trip'));
                            dispatch(setErrorModalOpen(true));
                          }
                        }}
                        startIcon={<DeleteIcon />}
                        disabled={sharableLinkLoading}
                      >
                        {sharableLinkLoading ? <LoadingDots /> : 'Unshare'}
                      </Button>
                    )} */}

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleCopyLink}
                      startIcon={<ContentCopyIcon />}
                      sx={{
                        paddingY: 1,
                        width: '100%',
                        borderRadius: '20px',
                        backgroundColor: '#676BEE',
                        '&:hover, &:visited, &:link, &:active': {
                          textDecoration: 'none',
                        },
                        '&:hover': {
                          backgroundColor: '#4447B1',
                        },
                        '&:disabled': {
                          backgroundColor: '#D6D9FC',
                        },
                      }}
                    >
                      Copy link
                    </Button>
                  </Stack>
                </>
              )}
            </Stack>
          </Box>
          {showInviteFriends && (
            <>
              <Divider
                sx={theme => ({
                  marginTop: 3,
                  marginBottom: 1,
                  minWidth: 350,
                  [theme.breakpoints.down('md')]: {
                    minWidth: '100%',
                    width: '100%',
                  },
                })}
              >
                <Typography
                  color="text.secondary"
                  sx={theme => ({
                    px: 0,
                    whiteSpace: 'nowrap',
                    fontSize: 13,
                    fontWeight: 600,
                  })}
                >
                  {'OR - SHARE WITH FRIENDS ONLY'}
                </Typography>
              </Divider>
              <Box
                sx={theme => ({
                  marginTop: 2,
                  width: '100%',
                  paddingBottom: 2,
                  paddingX: 3,
                  border: '1px solid #ccc',
                  borderRadius: 2,
                  backgroundColor: '#f9f9f9',
                  maxWidth: 500,
                  [theme.breakpoints.down('sm')]: {
                    paddingX: 2,
                  },
                })}
              >
                <Box width="100%">
                  <Tabs
                    value={value}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChange}
                  >
                    <Tab
                      sx={{ width: '50%', fontWeight: 600, fontSize: 16 }}
                      label="Add friend"
                    />
                    <Tab
                      sx={{ width: '50%', fontWeight: 600, fontSize: 16 }}
                      label="Permissions"
                    />
                  </Tabs>
                </Box>
                <Space size="md" />
                {value === 0 && (
                  <>
                    <Typography
                      sx={theme => ({
                        fontSize: 15,
                        fontWeight: 500,
                        color: theme.palette.grey[600],
                      })}
                    >
                      Share this trip with friends by adding their email.
                      They’ll see the details once they log in.
                    </Typography>
                    <Space size="sm" />
                    <StyledFormControl>
                      <Box mb={1}>
                        <TextField
                          label="Friend email"
                          name="title"
                          fullWidth={true}
                          value={email}
                          variant="outlined"
                          onChange={e => {
                            setEmail(e.target.value);
                          }}
                          sx={{ backgroundColor: '#FAFAFA' }}
                        />
                      </Box>
                      <Stack
                        direction="row"
                        spacing={2}
                        alignItems="flex-start"
                      >
                        <Typography variant="subtitle1" fontWeight="bold">
                          Allow access to
                        </Typography>
                      </Stack>
                      <Stack direction="row" spacing={2}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={canView}
                              onChange={() => setCanView(!canView)}
                              color="primary"
                              inputProps={{
                                'aria-label': 'Allow view access',
                              }}
                            />
                          }
                          label="View"
                        />
                        <FormControlLabel
                          control={
                            <Switch
                              checked={canEdit}
                              onChange={() => setCanEdit(!canEdit)}
                              color="primary"
                              inputProps={{
                                'aria-label': 'Allow edit access',
                              }}
                            />
                          }
                          label="Edit"
                        />
                      </Stack>
                      {/* </Stack> */}
                    </StyledFormControl>
                    <Space size="sm" />
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={!validateEmail(email) || loading}
                      onClick={async () => {
                        gtag('event', 'share-trip-share-with-friend');
                        try {
                          await shareTrip();
                          await getSharedInfo();
                          setValue(1);
                          setEditId('');
                        } catch (e) {
                          dispatch(setErrorTitle('Error sharing trip'));
                          dispatch(setErrorModalOpen(true));
                        }
                      }}
                      startIcon={<LinkIcon />}
                      sx={{
                        paddingY: 1,
                        width: '100%',
                        borderRadius: '20px',
                        backgroundColor: '#676BEE',
                        '&:hover, &:visited, &:link, &:active': {
                          textDecoration: 'none',
                        },
                        '&:hover': {
                          backgroundColor: '#4447B1',
                        },
                        '&:disabled': {
                          backgroundColor: '#D6D9FC',
                        },
                      }}
                    >
                      {loading ? <Loader /> : 'Share trip'}
                    </Button>
                  </>
                )}
                {value === 1 && (
                  <>
                    {sharedInfoData &&
                    sharedInfoData.userTripSharedWithUsers &&
                    sharedInfoData.userTripSharedWithUsers.length > 0 ? (
                      <>
                        <Typography
                          sx={theme => ({
                            fontSize: 15,
                            fontWeight: 500,
                            color: theme.palette.grey[600],
                          })}
                        >
                          When people with access login, they will be able to
                          see this trip details.
                        </Typography>
                        <Space size="md" />
                        {/* <Divider /> */}
                        {sharedInfoData.userTripSharedWithUsers.map(
                          (user, index) => (
                            <>
                              {user && !editId && (
                                <Fragment key={index}>
                                  <Box
                                    display="flex"
                                    flexDirection="row"
                                    justifyContent="space-between"
                                    alignItems="flex-end"
                                    width="100%"
                                    sx={{
                                      backgroundColor: '#FAFAFA',
                                    }}
                                  >
                                    <Box
                                      display="flex"
                                      flexDirection="column"
                                      alignItems="flex-start"
                                    >
                                      <Box
                                        sx={theme => ({
                                          color: theme.palette.secondary.dark,
                                          fontSize: 18,
                                          fontWeight: 500,
                                          marginBottom: theme.spacing(0.5),
                                          marginTop: theme.spacing(0.5),
                                        })}
                                      >
                                        {user.sharedWithEmail}
                                      </Box>
                                      {user.canEdit ? (
                                        <Box
                                          sx={theme => ({
                                            color:
                                              theme.palette.secondary.light,
                                            fontSize: 16,
                                            fontWeight: 400,
                                            marginBottom: theme.spacing(1),
                                          })}
                                        >
                                          Access to view and edit
                                        </Box>
                                      ) : (
                                        <Box>
                                          {user.canView ? (
                                            <Box
                                              sx={theme => ({
                                                color:
                                                  theme.palette.secondary.light,
                                                fontSize: 16,
                                                fontWeight: 400,
                                                marginBottom: theme.spacing(1),
                                              })}
                                            >
                                              Access to view
                                            </Box>
                                          ) : (
                                            <Box
                                              sx={theme => ({
                                                color:
                                                  theme.palette.secondary.light,
                                                fontSize: 16,
                                                fontWeight: 400,
                                                marginBottom: theme.spacing(1),
                                              })}
                                            >
                                              No Access
                                            </Box>
                                          )}
                                        </Box>
                                      )}
                                    </Box>

                                    <LinkButtonOld
                                      onClick={() => {
                                        setEditId(user.id);
                                        setUpdateEmail(user.sharedWithEmail);
                                        setCanView(user.canView);
                                        setCanEdit(user.canEdit);
                                        gtag(
                                          'event',
                                          'share-trip-edit-permissions',
                                        );
                                      }}
                                    >
                                      Edit
                                    </LinkButtonOld>
                                  </Box>
                                  {/* <Divider /> */}
                                </Fragment>
                              )}

                              {user.id === editId && (
                                <>
                                  <StyledFormControl>
                                    <Box mb={1}>
                                      <TextField
                                        label="Friend email"
                                        name="title"
                                        fullWidth={true}
                                        value={updateEmail}
                                        variant="outlined"
                                        onChange={e => {
                                          setUpdateEmail(e.target.value);
                                        }}
                                        sx={{
                                          backgroundColor: '#FAFAFA',
                                        }}
                                      />
                                    </Box>

                                    <Stack
                                      direction="row"
                                      spacing={2}
                                      alignItems="flex-start"
                                    >
                                      <Typography
                                        variant="subtitle1"
                                        fontWeight="bold"
                                      >
                                        Allow access to
                                      </Typography>
                                    </Stack>
                                    <Stack direction="row" spacing={2}>
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            checked={canView}
                                            onChange={() =>
                                              setCanView(!canView)
                                            }
                                            color="primary"
                                            name="checkedB"
                                            inputProps={{
                                              'aria-label': 'primary checkbox',
                                            }}
                                          />
                                        }
                                        label="View"
                                      />
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            checked={canEdit}
                                            onChange={() =>
                                              setCanEdit(!canEdit)
                                            }
                                            color="primary"
                                            name="checkedB"
                                            inputProps={{
                                              'aria-label': 'primary checkbox',
                                            }}
                                          />
                                        }
                                        label="Edit"
                                      />
                                    </Stack>
                                  </StyledFormControl>
                                  <Stack
                                    direction="row"
                                    spacing={1}
                                    justifyContent="center"
                                  >
                                    <Button
                                      variant="text"
                                      color="error"
                                      sx={{
                                        width: '100%',
                                        borderRadius: '20px',
                                      }}
                                      onClick={async () => {
                                        setEditId('');
                                        gtag(
                                          'event',
                                          'share-trip-edit-permissions-go-back',
                                        );
                                      }}
                                    >
                                      Back
                                    </Button>
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      startIcon={<LinkIcon />}
                                      disabled={
                                        !validateEmail(updateEmail) ||
                                        updateLoading
                                      }
                                      onClick={async () => {
                                        gtag(
                                          'event',
                                          'share-trip-edit-permissions-save',
                                        );
                                        try {
                                          await updateSharedTripInfo();
                                          setEditId('');
                                        } catch (e) {
                                          dispatch(
                                            setErrorTitle(
                                              'Error saving changes',
                                            ),
                                          );
                                          dispatch(setErrorModalOpen(true));
                                        }

                                        try {
                                          await getSharedInfo();
                                        } catch (e) {}
                                      }}
                                      sx={{
                                        paddingY: 1,
                                        width: '100%',
                                        borderRadius: '20px',
                                        backgroundColor: '#676BEE',
                                        '&:hover, &:visited, &:link, &:active':
                                          {
                                            textDecoration: 'none',
                                          },
                                        '&:hover': {
                                          backgroundColor: '#4447B1',
                                        },
                                        '&:disabled': {
                                          backgroundColor: '#D6D9FC',
                                        },
                                      }}
                                    >
                                      {updateLoading ? <Loader /> : 'Save'}
                                    </Button>
                                  </Stack>
                                </>
                              )}
                            </>
                          ),
                        )}
                      </>
                    ) : (
                      <>
                        <TextDescription size="md" sx={{ textAlign: 'left' }}>
                          This trip is not shared with anyone yet.
                        </TextDescription>
                        <Space size="xl" />
                      </>
                    )}
                  </>
                )}
              </Box>
            </>
          )}
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity="success"
              variant="filled"
            >
              Link copied to clipboard!
            </Alert>
          </Snackbar>
        </ModalPaper>
      </CenteredModal>
    </>
  );
}
