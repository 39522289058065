import { gql } from 'graphql-tag';

export const QUERY_GET_USER_TRIPS = gql`
  query userTripsList {
    userTripsList {
      id
      name
      length
      published
      tripType
      sharableId
      publish_pending
      images {
        id
        path
        previewPath
        mediumPath
        smallPath
        header
        main
        secondary
        tertiary
      }
      cities {
        id
        name
      }
      countries {
        id
        name
        description
        url
        iso2Code
        iso3Code
      }
      itineraryCountries {
        iso3Code
        countryName
        itineraryId
      }
    }
  }
`;

export const QUERY_GET_USER_FAVOURITE_TRIPS = gql`
  query userLikedTrips {
    userLikedTrips {
      id
      name
      length
      published
      tripType
      publish_pending
      images {
        id
        path
        mediumPath
        smallPath
        previewPath
        header
        main
        secondary
        tertiary
      }
      countries {
        id
        name
        description
        url
        iso2Code
        iso3Code
      }
      itineraryCountries {
        iso3Code
        countryName
        itineraryId
      }
      cities {
        id
        name
      }
      userDetails {
        id
        nickName
        tikTokUrl
        instagramUrl
        youTubeUrl
        blogUrl
        profilePictureUrl
      }
    }
  }
`;

export const QUERY_GET_USER_TRIPS_WITH_DAYS_ONLY = gql`
  query userTripsWithDaysOnly {
    userTripsWithDaysOnly {
      id
      name
      length
      published
      sharableId
      tripType
      publish_pending
      countries {
        id
        name
        description
        url
        iso2Code
        iso3Code
      }
      itineraryCountries {
        iso3Code
        countryName
        itineraryId
      }
      cities {
        id
        name
      }
      itinerary {
        id
        days {
          id
          dayNumber
          cities {
            cityName
          }
        }
      }
    }
  }
`;
