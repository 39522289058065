import DescriptionTwoToneIcon from '@mui/icons-material/DescriptionTwoTone';
import MapsHomeWorkTwoToneIcon from '@mui/icons-material/MapsHomeWorkTwoTone';
import StarsTwoToneIcon from '@mui/icons-material/StarsTwoTone';
import TodayTwoToneIcon from '@mui/icons-material/TodayTwoTone';
import { Box, Link, Typography, Stack, styled } from '@mui/material';
import { ReactNode, useEffect, useRef, useState } from 'react';

import { Day } from '../../generated/public_graphql';
import { Body1, TitleH3 } from '../../theme-components/Typography';
import { openLinkInBrowserNewTab } from '../../utils/helpers';
import { RenderHtmlFromResponseNoMargin } from '../RenderHtmlFromResponse';

import { AddAccomodationDirections } from './AddAccomodationDirections';
import DayFullness from './DayFullnessIndicator';
import { PublicActivityCard } from './PublicActivityCard';
import { PublicPlaceCard } from './PublicPlaceCard';
import { SideScrollRecommendations } from './Recommendation';
import { TransportStepDetails } from './TransportStepDetails';
import { TripUser } from './TripItinerary';
import { PublicPlaceCardNew } from './place-details/PublicPlaceCardNew';

export function TripDay(props: {
  day: Day;
  tripType: string;
  tripUser: TripUser;
  date?: string;
  selectedStepId?;
  stepRefs?;
  dayRefs?;
  onCardInView?: (stepId: string) => void;
  onDayInView?: (dayId: string) => void;
  isRenderedOnLockedPublicTrip: boolean;
}) {
  const [visibleStep, setVisibleStep] = useState(false);
  const observerRef = useRef<IntersectionObserver | null>(null);
  const stepRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [stepInView, setStepInView] = useState('');

  const handleCardInView = (stepId: string) => {
    setStepInView(stepId);
    if (props.onCardInView) {
      props.onCardInView(stepId);
    }
  };

  useEffect(() => {
    observerRef.current = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setVisibleStep(true);
            observerRef.current?.unobserve(entry.target);
          }
        });
      },
      {
        root: null,
        rootMargin: '400px',
        threshold: 0.1,
      },
    );

    stepRefs.current.forEach(ref => {
      if (ref && observerRef.current) {
        observerRef.current.observe(ref);
      }
    });

    return () => {
      observerRef.current?.disconnect(); // Cleanup observer on unmount
    };
  }, [props.day.steps]);

  return (
    <>
      {(props.tripType || props.tripType !== 'guide') && (
        <Stack
          direction="column"
          width="100%"
          // ref={el => {
          //   if (props && props.dayRefs && props.dayRefs.current) {
          //     props.dayRefs.current[props.day!.id] = el; // Safely assign the ref
          //   }
          // }}
          ref={el => {
            if (props.dayRefs?.current) {
              props.dayRefs.current[props.day.id] = el;
            }
          }}
          sx={{
            position: 'relative',
            overflow: 'hidden',
            borderRadius: '20px',
            // border: '2px solid rgba(211, 211, 211, 0.4)', // Light gray with 50% opacity
            padding: 1,
            marginTop: 1.5,
            paddingLeft: 1.5,
            marginBottom: 1.5,
            backgroundColor: '#FFFEFD',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.07)',
          }}
        >
          <Stack
            direction="row"
            alignItems="left"
            sx={theme => ({
              width: '100%',
            })}
          >
            <Box
              sx={theme => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                alignSelf: 'center',
                // marginRight: 1,
                [theme.breakpoints.down('sm')]: {
                  // marginRight: 0.5,
                },
              })}
            >
              {props.tripType && props.tripType === 'guide' ? (
                <>
                  <StarsTwoToneIcon
                    sx={theme => ({
                      width: 25,
                      height: 25,
                      // marginLeft: 1,
                      marginRight: 1,
                      // color: theme.palette.icon.main,
                      color: '#F39C6A',
                      [theme.breakpoints.down('sm')]: {
                        // marginRight: 0.5,
                        // width: 20,
                        // height: 20,
                      },
                    })}
                  />

                  <TitleH3 color="#001B30">Guide</TitleH3>
                </>
              ) : (
                <>
                  <TodayTwoToneIcon
                    sx={theme => ({
                      width: 25,
                      height: 25,
                      marginRight: 1,
                      marginTop: 0.5,
                      // marginLeft: 1,
                      // color: theme.palette.icon.main,
                      color: '#F39C6A',
                      [theme.breakpoints.down('sm')]: {
                        // marginRight: 0.5,
                        // width: 20,
                        // height: 20,
                      },
                    })}
                  />

                  <TitleH3 color="#001B30">Day {props.day.dayNumber}</TitleH3>
                </>
              )}
            </Box>
          </Stack>
          {props.day.description &&
          props.day.description !== '' &&
          props.day.description !== '<p></p>' ? (
            <Stack
              direction="row"
              width="100%"
              sx={theme => ({
                [theme.breakpoints.down('sm')]: {
                  // paddingLeft: '40px',
                },
              })}
            >
              <DescriptionTwoToneIcon
                sx={theme => ({
                  marginRight: 1,
                  width: 25,
                  height: 25,
                  color: '#F39C6A',
                })}
              />
              <Box
                sx={{
                  marginTop: '-0.5em',
                  marginBottom: '-0.5em',
                }}
              >
                <Body1>
                  <RenderHtmlFromResponseNoMargin
                    content={props.day.description}
                    limitToLines={3}
                  />
                </Body1>
              </Box>
            </Stack>
          ) : (
            <>
              {props.day && props.day.cities && props.day.cities.length > 0 && (
                <>
                  <Stack direction="row" width="100%">
                    <MapsHomeWorkTwoToneIcon
                      sx={theme => ({
                        width: 25,
                        height: 25,
                        marginRight: 1,
                        color: '#F39C6A',
                        [theme.breakpoints.down('sm')]: {},
                      })}
                    />
                    <Typography
                      sx={theme => ({
                        fontWeight: 600,
                        fontSize: 16,
                        color: '#2E3C44',
                        marginRight: 1,
                        [theme.breakpoints.down('sm')]: {
                          fontSize: 16,
                        },
                      })}
                    >
                      {props.day.cities.length === 1 ? 'City' : 'Cities'}{' '}
                      {props.day.cities.map((city, i) => (
                        <Typography
                          key={i}
                          component="span"
                          sx={theme => ({
                            fontWeight: 600,
                            fontSize: 16,
                            color: '#6E7191',
                            marginRight: 0.5,
                            [theme.breakpoints.down('sm')]: {
                              fontSize: 16,
                            },
                          })}
                        >
                          {city && (
                            <>
                              {city.cityName}
                              {i !== props.day.cities!.length - 1 && <>{','}</>}
                            </>
                          )}
                        </Typography>
                      ))}
                    </Typography>
                  </Stack>
                </>
              )}
            </>
          )}
          <DayFullness
            dayId={props.day.id}
            numberOfStepsInDay={props.day.steps?.length ?? 0}
            tripType={props.tripType ? props.tripType : 'itinerary'}
          />
          {/* </Box> */}
        </Stack>
      )}
      <AddAccomodationDirections
        dayId={props.day.id}
        isFirstDirection={true}
        isLastDirection={false}
        tripUser={props.tripUser}
        tripType={props.tripType ? props.tripType : 'itinerary'}
      />
      <Box width="100%">
        {props.day &&
          props.day.steps &&
          props.day.steps.length > 0 &&
          props.day.steps.map((step, i) => {
            return (
              <Box
                key={step!.id}
                width="100%"
                // ref={el => (props.stepRefs.current[step!.id] = el)} // Assign refs using step IDs
                ref={el => {
                  if (props && props.stepRefs && props.stepRefs.current) {
                    props.stepRefs.current[step!.id] = el; // Safely assign the ref
                  }
                }}
                className={`itinerary-step ${props.selectedStepId === step!.id ? 'selected' : ''}`}
              >
                <div
                  key={i}
                  style={{ width: '100%' }}
                  ref={el => (stepRefs.current[i] = el)}
                  data-index={i}
                >
                  {visibleStep ? (
                    <>
                      {step?.activity ? (
                        // <Box
                        //   sx={{
                        //     width: '100%',
                        //     flexGrow: 1,
                        //     minWidth: 0,
                        //   }}
                        // >
                        <PublicActivityCard
                          step={step}
                          dayId={props.day.id}
                          tripType={props.tripType}
                          onCardInView={handleCardInView}
                          selected={
                            props.selectedStepId === step!.id ? true : false
                          }
                          tripUser="public"
                          highlightedStepId={stepInView}
                          isRenderedOnLockedPublicTrip={
                            props.isRenderedOnLockedPublicTrip
                          }
                        />
                      ) : (
                        // </Box>
                        <>
                          {step && (
                            // <Box
                            //   sx={{
                            //     width: '100%',
                            //     flexGrow: 1,
                            //     minWidth: 0,
                            //   }}
                            // >
                            <PublicPlaceCardNew
                              step={step}
                              dayId={props.day.id}
                              selected={
                                props.selectedStepId === step!.id ? true : false
                              }
                              tripType={props.tripType}
                              tripUser="public"
                              onCardInView={handleCardInView}
                              highlightedStepId={stepInView}
                              isRenderedOnLockedPublicTrip={
                                props.isRenderedOnLockedPublicTrip
                              }
                            />
                            // </Box>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <div
                        ref={el => {
                          if (
                            props &&
                            props.stepRefs &&
                            props.stepRefs.current
                          ) {
                            props.stepRefs.current[step!.id] = el; // Safely assign the ref
                          }
                        }}
                        className={`itinerary-step ${props.selectedStepId === step!.id ? 'selected' : ''}`}
                        style={{
                          background: '#f0f0f0',
                          border: '1px',
                          height: '400px',
                        }}
                      />
                    </>
                  )}
                </div>
                {/*    Debug for scrolling
               <div
                  ref={el => {
                    if (props && props.stepRefs && props.stepRefs.current) {
                      props.stepRefs.current[step!.id] = el; // Safely assign the ref
                    }
                  }}
                  className={`itinerary-step ${props.selectedStepId === step!.id ? 'selected' : ''}`}
                  style={{
                    background: '#f0f0f0',
                    // height: '100%',
                    border: '1px',
                    height: '400px',
                  }}
                >
                  Loading step...
                </div> */}
                <>
                  {props.day.steps && props.day.steps[i + 1] && (
                    <TransportStepDetails
                      currentStep={step}
                      nextStep={props.day.steps[i + 1]}
                      tripType={props.tripType}
                      dayId={props.day.id}
                      tripOwner="public"
                    />
                  )}
                </>
              </Box>
            );
          })}
      </Box>
      <AddAccomodationDirections
        dayId={props.day.id}
        isFirstDirection={false}
        isLastDirection={true}
        tripUser={props.tripUser}
        tripType={props.tripType ? props.tripType : 'itinerary'}
      />
      {props.day.recommendations &&
        props.day.recommendations.length > 0 &&
        (props.day.recommendations[0]?.place ||
          props.day.recommendations[0]?.activity) && (
          <SideScrollRecommendations
            recommentations={props.day.recommendations}
            type="day"
            tripUser="public"
          />
        )}
    </>
    // </Box>
  );
}

export function StepDescription(props: { children: ReactNode }) {
  return <StepDescriptionBox>{props.children}</StepDescriptionBox>;
}

const StepDescriptionBox = styled(Box)(({ theme }) => ({
  paddingLeft: 0,
  width: '100%',
  fontWeight: 500,
  fontSize: 18,
  color: '#14142B',
  marginBottom: 15,
  [theme.breakpoints.down('sm')]: {
    fontSize: 16,
    marginBottom: 5,
    paddingLeft: 30,
  },
}));

export function ExternalLinkRegular(props: { url: string; copy: string }) {
  return (
    <Link
      onClick={() => {
        openLinkInBrowserNewTab(props.url);
        gtag('event', 'public-ext-link-maps-url');
      }}
      sx={theme => ({
        fontWeight: 500,
        fontSize: 14,
        alignSelf: 'center',
        color: '#8A8D9C',
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'none',
          color: '#6E7191',
        },
        [theme.breakpoints.down('sm')]: {
          fontSize: 12,
        },
      })}
    >
      {props.copy}
    </Link>
  );
}
