// import React from 'react';
// import Realistic from 'react-canvas-confetti/dist/presets/realistic';

// export function RealisticConfetti() {
//   return (
//     <Realistic
//       autorun={{ speed: 0.3, duration: 2, delay: 0.5 }}
//       style={{
//         position: 'fixed',
//         top: 0,
//         left: '50%',
//         transform: 'translateX(-50%)', // Centers it horizontally
//         zIndex: 9999999, // Ensures it's above all other content
//         pointerEvents: 'none', // Makes sure it doesn't interfere with clicking other elements
//         width: '100%', // Full width for the confetti effect
//         height: '100vh', // Full viewport height for the confetti effect
//       }}
//     />
//   );
// }
import React from 'react';
import { createPortal } from 'react-dom';
import Realistic from 'react-canvas-confetti/dist/presets/realistic'; // Replace with actual import

export function RealisticConfetti() {
  return createPortal(
    <Realistic
      autorun={{ speed: 0.3, duration: 2, delay: 0.5 }}
      style={{
        position: 'fixed',
        top: 0,
        left: '50%',
        transform: 'translateX(-50%)', // Centers it horizontally
        zIndex: 9999999999, // Ensures it's above all modals
        pointerEvents: 'none', // Allows interactions with underlying elements
        width: '100%',
        height: '100vh',
      }}
    />,
    document.body, // Mounts confetti outside the modal stacking context
  );
}
