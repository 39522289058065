import { Box, Stack } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { publicTripsSelector } from '../../store/PublicTripSlice';
import { tripsSelector } from '../../store/TripSlice';
import { returnFormattedDate } from '../../utils/helpers';

import { TripDay } from './TripDay';
import { AddAccomodationDirections } from './AddAccomodationDirections';
import { TitleH3, TitleH4 } from '../../theme-components/Typography';

export type TripUser = 'public' | 'user' | 'preview';

type TripItineraryProps = {
  tripUser: TripUser;
  startDate?: Date;
  selectedStepId;
  stepRefs;
  dayRefs;
  onCardInView?: (stepId: string) => void;
  onDayInView?: (dayId: string) => void;
};

export function TripItinerary({
  tripUser,
  startDate,
  selectedStepId,
  stepRefs,
  dayRefs,
  onCardInView,
  onDayInView,
}: TripItineraryProps) {
  const { publicTrip } = useSelector(publicTripsSelector);
  const { trip: userTrip } = useSelector(tripsSelector);
  const [dayInView, setDayInView] = useState('');

  const fullDayRefs = useRef<Record<string, HTMLDivElement | null>>({});
  const isFirstRender = React.useRef(true);

  const trip = tripUser === 'user' ? userTrip : publicTrip;

  // Select the most visible day in the menu
  useEffect(() => {
    const calculateVisibility = () => {
      if (!fullDayRefs?.current || isFirstRender.current) return;

      let isDayHandled = false;
      let maxVisibleArea = 0;
      let mostVisibleDayId = '';

      Object.entries(fullDayRefs.current).forEach(([dayId, dayRef]) => {
        const element = dayRef as HTMLDivElement;
        if (!element) return;

        const rect = element.getBoundingClientRect();
        // const elementHeight = rect.height;
        const visibleHeight = Math.min(
          window.innerHeight - rect.top,
          rect.bottom,
        );
        const visibleArea = Math.max(0, visibleHeight);

        if (visibleArea > maxVisibleArea) {
          maxVisibleArea = visibleArea;
          mostVisibleDayId = dayId;
        }
      });

      if (mostVisibleDayId && mostVisibleDayId !== dayInView && !isDayHandled) {
        isDayHandled = true;
        setTimeout(() => {
          // setDayInView(mostVisibleDayId);
          // onDayInView?.(mostVisibleDayId);
          if (!fullDayRefs?.current) return;

          const element = fullDayRefs.current[
            mostVisibleDayId
          ] as HTMLDivElement;
          if (!element) return;

          // Recheck visibility after the timeout
          const rect = element.getBoundingClientRect();
          // const elementHeight = rect.height;
          const visibleHeight = Math.min(
            window.innerHeight - rect.top,
            rect.bottom,
          );
          const visibleArea = Math.max(0, visibleHeight);

          if (visibleArea > 0 && mostVisibleDayId !== dayInView) {
            setDayInView(mostVisibleDayId);
            onDayInView?.(mostVisibleDayId);
          }
        }, 1000);
      }
    };

    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    window.addEventListener('scroll', calculateVisibility);
    calculateVisibility();

    return () => window.removeEventListener('scroll', calculateVisibility);
  }, [fullDayRefs, dayInView, onDayInView]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignSelf: 'center',
        height: '100%',
        width: '100%',
      }}
    >
      {trip && trip.itinerary && (
        <Stack
          direction="row"
          alignItems="left"
          sx={theme => ({
            width: '100%',
          })}
        >
          <Box width="100%" mb={3}>
            {trip && (!trip.tripType || trip.tripType !== 'guide') && (
              <Box sx={{ paddingLeft: 1, marginBottom: -1 }}>
                <TitleH3>Itinerary</TitleH3>
              </Box>
            )}

            {trip.itinerary.days &&
              trip.itinerary.days.length > 0 &&
              trip.itinerary.days.map((day, i) => {
                return (
                  <>
                    {day && (
                      <Box
                        width="100%"
                        key={day.id}
                        ref={(el: HTMLDivElement | null) => {
                          if (fullDayRefs?.current) {
                            fullDayRefs.current[day.id] = el;
                          }
                        }}
                        data-day-id={day.id}
                      >
                        {day && (
                          <>
                            {startDate ? (
                              <TripDay
                                key={i + day.id}
                                day={day}
                                date={returnFormattedDate(startDate, i)}
                                selectedStepId={selectedStepId}
                                tripUser={tripUser}
                                stepRefs={stepRefs}
                                dayRefs={dayRefs}
                                tripType={
                                  trip.tripType ? trip.tripType : 'itinerary'
                                }
                                onCardInView={onCardInView}
                                onDayInView={onDayInView}
                                isRenderedOnLockedPublicTrip={
                                  trip.tripAccess?.fullViewGranted
                                    ? false
                                    : true
                                }
                              />
                            ) : (
                              <TripDay
                                key={i + day.id}
                                day={day}
                                selectedStepId={selectedStepId}
                                tripUser={tripUser}
                                stepRefs={stepRefs}
                                dayRefs={dayRefs}
                                tripType={
                                  trip.tripType ? trip.tripType : 'itinerary'
                                }
                                onCardInView={onCardInView}
                                onDayInView={onDayInView}
                                isRenderedOnLockedPublicTrip={
                                  trip.tripAccess?.fullViewGranted
                                    ? false
                                    : true
                                }
                              />
                            )}
                          </>
                        )}
                      </Box>
                    )}
                  </>
                );
              })}
          </Box>
        </Stack>
      )}
    </Box>
  );
}
