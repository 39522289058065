import { useLazyQuery, useQuery } from '@apollo/client';
import { Box } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { setDays } from '../../store/DaySlice';
import { errorsSelector } from '../../store/ErrorSlice';
import {
  tripsSelector,
  setTrip,
  setTripDescription,
  setTripName,
  setUserTrips,
} from '../../store/TripSlice';
import { setUser } from '../../store/UserSlice';
import { FullScreenBusAnimation } from '../animations/BusAnimation';
import { useAuth } from '../auth/firebase';
import LoginButton from '../auth/LoginButton';
import ErrorModal from '../Error';
import { QUERY_GET_USER } from '../gql-user/user';
import { QUERY_USER_TRIP } from '../gql-user/userTrip';
import { RoutePage, RouteTitle, RouteContent } from '../route';

import { DragAndDropUserItinerarySingleTrip } from './drag-and-drop/DragAndDropUserItinerarySingleTrip';
import { DragAndDropUserItineraryTwoTrips } from './drag-and-drop/DragAndDropUserItineraryTwoTrips';
import { QUERY_GET_USER_TRIPS_WITH_DAYS_ONLY } from '../gql-user/userTripsQuery';
import { User } from 'firebase/auth';
import { useEffect, useState } from 'react';

export function CreateUserTrip(props: {
  onCardInView?: (stepId: string) => void;
}) {
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth();
  const { userTripId } = useParams<{ userTripId: string }>();
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);

  const params = new URLSearchParams(window.location.search);
  const selectedTripIdOnLoad: string | null = params.get('selected-trip');

  const { loading } = useQuery(QUERY_USER_TRIP, {
    variables: { trip_id: userTripId },
    fetchPolicy: 'network-only', // Ensure it fetches only on initial load
    skip: initialLoadComplete,
    onCompleted: fetchedData => {
      if (fetchedData && fetchedData.userTrip) {
        dispatch(setTrip(fetchedData.userTrip));
        dispatch(setTripName(fetchedData.userTrip.name));
        dispatch(setTripDescription(fetchedData.userTrip.description));
        dispatch(setDays(fetchedData.userTrip.itinerary.days));
        setInitialLoadComplete(true);
      }
    },
  });

  const { loading: userLoading } = useQuery(QUERY_GET_USER, {
    variables: { trip_id: userTripId },
    fetchPolicy: 'network-only', // Ensure it fetches only on initial load
    skip: initialLoadComplete,
    onCompleted: fetchedUserData => {
      if (fetchedUserData && fetchedUserData.getUser) {
        dispatch(setUser(fetchedUserData.getUser));
      }
    },
  });

  const { loading: userTripsLoading } = useQuery(
    QUERY_GET_USER_TRIPS_WITH_DAYS_ONLY,
    {
      variables: { trip_id: userTripId },
      fetchPolicy: 'network-only', // Ensure it fetches only on initial load
      skip: initialLoadComplete,
      onCompleted: fetchedUserTripsData => {
        if (
          fetchedUserTripsData &&
          fetchedUserTripsData.userTripsWithDaysOnly
        ) {
          dispatch(setUserTrips(fetchedUserTripsData.userTripsWithDaysOnly));
        }
      },
    },
  );

  const { errorModalOpen } = useSelector(errorsSelector);
  const { trip } = useSelector(tripsSelector);

  if (loading || userLoading || userTripsLoading) {
    return <FullScreenBusAnimation copy="Almost there!" />;
  }

  if (!isAuthenticated) {
    return <LoginButton />;
  }

  if (!trip && !loading) {
    return (
      <RoutePage>
        <RouteTitle>Trip</RouteTitle>
        <RouteContent>
          Trip not found. If this is your trip get in touch with us at
          help@rooutie.com
        </RouteContent>
      </RoutePage>
    );
  }

  return (
    <>
      {errorModalOpen && <ErrorModal />}

      {selectedTripIdOnLoad ? (
        <DragAndDropUserItineraryTwoTrips />
      ) : (
        <DragAndDropUserItinerarySingleTrip />
      )}
    </>
  );
}
