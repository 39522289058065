import AirplaneTicketIcon from '@mui/icons-material/AirplaneTicket';
import CarRentalTwoToneIcon from '@mui/icons-material/CarRentalTwoTone';
import DirectionsTwoToneIcon from '@mui/icons-material/DirectionsTwoTone';
import HotelTwoToneIcon from '@mui/icons-material/HotelTwoTone';
import MapTwoToneIcon from '@mui/icons-material/MapTwoTone';
import { Link, Stack } from '@mui/material';

import { Place } from '../../../generated/user_graphql';
import { PlaceCardButtons } from '../../../theme-components/Buttons';
import {
  generateBookingComUrl,
  generateCarRentalUrl,
  generateFlightUrl,
  getPlaceMapUrls,
  openLinkInBrowserNewTab,
} from '../../../utils/helpers';

interface PlaceActionsProps {
  place?: Place;
  lat?: number;
  lng?: number;
  hideMoreInfo?: boolean;
  renderingOnFavourite?: boolean;
}

const PlaceCardCTAsNew: React.FC<PlaceActionsProps> = ({
  place,
  lat,
  lng,
  // hideMoreInfo = false,
  renderingOnFavourite,
}) => {
  let bookingLink = '';
  let carRentalLink = '';
  let bookFlightLink = '';

  if (
    place?.placeType === 'CATEGORY_ACCOMMODATION' ||
    place?.category === 'hotel'
  ) {
    bookingLink = generateBookingComUrl(
      place.lat ?? undefined,
      place.lng ?? undefined,
      place.city ?? undefined,
      place.title ?? undefined,
    );
  }

  if (
    place?.dataSource === 'accomodation' &&
    place?.bookingLink != null &&
    place?.bookingLink.startsWith('https://')
  ) {
    bookingLink = place.bookingLink;
  }

  if (place?.category?.toLowerCase().includes('car_rental')) {
    carRentalLink = generateCarRentalUrl(
      place.city ?? undefined,
      place.country ?? undefined,
    );
  }

  if (place?.category?.toLowerCase().includes('airport')) {
    bookFlightLink = generateFlightUrl();
  }

  if (!place && lat && lng) {
    return (
      <>
        <Link
          onClick={() => {
            openLinkInBrowserNewTab(
              'https://www.google.com/maps/search/?api=1&query=' +
                lat +
                '%2C' +
                lng,
            );
            gtag('event', 'public-link-navigate-click');
          }}
        >
          <PlaceCardButtons
            mobileCopy="Navigate"
            desktopCopy="Navigate"
            icon={DirectionsTwoToneIcon}
          />
        </Link>
      </>
    );
  }

  if (!place) {
    return <></>;
  }
  const { takeMeThereUrl, openGoogleMapsUrl } = getPlaceMapUrls(place);

  return (
    <>
      <Stack
        direction="row"
        width="100%"
        spacing={1.5}
        // justifyContent={'space-evenly'}
        // mt={2}
      >
        {!!bookingLink && (
          <>
            <Link
              onClick={() => {
                openLinkInBrowserNewTab(bookingLink);
                gtag('event', 'public-link-navigate-click');
              }}
            >
              <PlaceCardButtons
                primary={true}
                mobileCopy="Book Now"
                desktopCopy={renderingOnFavourite ? 'Book Now' : 'Book Now'}
                icon={HotelTwoToneIcon}
              />
            </Link>
          </>
        )}
        {!!carRentalLink && (
          <>
            <Link
              onClick={() => {
                openLinkInBrowserNewTab(carRentalLink);
                gtag('event', 'public-link-navigate-click');
              }}
            >
              <PlaceCardButtons
                primary={true}
                mobileCopy="Rent a car"
                desktopCopy={renderingOnFavourite ? 'Rent' : 'Rent a car'}
                icon={CarRentalTwoToneIcon}
              />
            </Link>
          </>
        )}
        {!!bookFlightLink && (
          <>
            <Link
              onClick={() => {
                openLinkInBrowserNewTab(bookFlightLink);
                gtag('event', 'public-link-navigate-click');
              }}
            >
              <PlaceCardButtons
                primary={true}
                mobileCopy="Book"
                desktopCopy={renderingOnFavourite ? 'Book' : 'Book flight'}
                icon={AirplaneTicketIcon}
              />
            </Link>
          </>
        )}
        {!!takeMeThereUrl && (
          <>
            <Link
              onClick={() => {
                openLinkInBrowserNewTab(takeMeThereUrl);
                gtag('event', 'public-link-navigate-click');
              }}
            >
              <PlaceCardButtons
                mobileCopy={'Directions'}
                desktopCopy={renderingOnFavourite ? 'Maps' : 'Directions'}
                icon={DirectionsTwoToneIcon}
              />
            </Link>
          </>
        )}
        {!!takeMeThereUrl &&
          !bookFlightLink &&
          !carRentalLink &&
          !bookingLink && (
            <>
              <Link
                onClick={() => {
                  openLinkInBrowserNewTab(takeMeThereUrl);
                  gtag('event', 'public-link-navigate-click');
                }}
              >
                <PlaceCardButtons
                  mobileCopy={'Google Maps'}
                  desktopCopy={renderingOnFavourite ? 'Maps' : 'Google Maps'}
                  icon={MapTwoToneIcon}
                />
              </Link>
            </>
          )}
        {/* {!!openGoogleMapsUrl &&
          !bookingLink &&
          !carRentalLink &&
          !bookFlightLink && (
            <>
              <Link
                onClick={() => {
                  openLinkInBrowserNewTab(openGoogleMapsUrl);
                  gtag('event', 'public-link-maps-url');
                }}
              >
                <PlaceCardButtons
                  mobileCopy="Maps"
                  desktopCopy="Maps"
                  icon={MapIcon}
                />
              </Link>
            </>
          )} */}
        {/* {!hideMoreInfo && (
          <>
            <Link
              onClick={() => {
                gtag('event', 'public-place-more-info-click');
                setMoreInfoOpened(true);
              }}
            >
              <PlaceCardButtons
                mobileCopy="More"
                desktopCopy="More Info"
                icon={InfoTwoToneIcon}
              />
            </Link>
          </>
        )} */}
      </Stack>

      {/* {moreInfoOpened && (
        <>
          {place && (
            <PlaceOrActivityMoreInfoModal place={place} onClose={closeModal} />
          )}
        </>
      )} */}
    </>
  );
};

export default PlaceCardCTAsNew;
