import { useMutation } from '@apollo/client';
import {
  Box,
  Typography,
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Stack,
  styled,
  Divider,
} from '@mui/material';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Trip } from '../../../generated/user_graphql';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../theme-components/Buttons';
import { Loader } from '../../../theme-components/Loader';
import { TitleH3 } from '../../../theme-components/Typography';
import { MUTATION_COPY_ACTIVITY_OR_PLACE_TO_DAY } from '../../gql-user/copyPlaceToDayMutation';
import SuccessIllustration from '../../illustrations/Success';
import { CenteredModal, ModalPaper } from '../../styling/modal';
import { useDispatch } from 'react-redux';
import { setDays } from '../../../store/DaySlice';
import ModalHeader from '../../../theme-components/ModalComponents';
import Space from '../../../theme-components/Spacing';
import { setTrip } from '../../../store/TripSlice';

interface TripModalProps {
  onClose: () => void;
  onStepAdded?: (stepId: string) => void;
  trip: Trip;
  placeId?: string;
  activityId?: string;
  showSecondaryButtons?: boolean;
  addingToCurrentUserTrip?: boolean;
}

export const AddPlaceActivityToDayModal: React.FC<TripModalProps> = ({
  onClose,
  onStepAdded,
  trip,
  placeId,
  activityId,
  showSecondaryButtons = false,
  addingToCurrentUserTrip = false,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedDayId, setSelectedDayId] = useState('');

  const [selectedDayValue, setSelectedDayValue] = useState('');
  const [copyPlaceToDay, { loading: loadingStep }] = useMutation(
    MUTATION_COPY_ACTIVITY_OR_PLACE_TO_DAY,
  );

  const [added, setAdded] = useState(false);

  const handleDayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDayValue(event.target.value);
    const [dayId, position] = event.target.value.split('|'); // Split the value by the delimiter
    setSelectedDayId(dayId); // Update the state with the parsed dayId
  };

  const handleCopyStep = async () => {
    try {
      const response = await copyPlaceToDay({
        variables: {
          place_id: placeId,
          activity_id: activityId,
          day_id: selectedDayId,
        },
      });

      if (addingToCurrentUserTrip === true) {
        const targetDay =
          response.data?.copyActivityOrPlaceToDay?.itinerary?.days?.find(
            day => day.id === selectedDayId,
          );

        if (targetDay && targetDay.steps.length > 0) {
          // Retrieve the last step
          const lastStep = targetDay.steps[targetDay.steps.length - 1];
          const lastStepId: string = lastStep.id;

          // Invoke the callback with the lastStepId
          if (onStepAdded) {
            onStepAdded(lastStepId);
          }
        }
        handleCloseModal();
      } else {
        setAdded(true);
      }

      setSelectedDayValue('');
      if (
        response.data?.copyActivityOrPlaceToDay?.itinerary?.days?.length > 0
      ) {
        dispatch(
          setDays(response.data.copyActivityOrPlaceToDay.itinerary.days),
        );
        dispatch(setTrip(response.data.copyActivityOrPlaceToDay));
      }
    } catch (e) {
      setAdded(false);
    }
  };

  const handleCloseModal = () => {
    document.body.style.overflow = 'unset';
    setAdded(false);
    setSelectedDayValue('');
    onClose();
  };

  return (
    <CenteredModal
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      open={true}
      onClose={handleCloseModal}
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent dark background
          backdropFilter: 'blur(10px)', // Apply the blur effect
        },
      }}
    >
      <ModalPaper>
        {added ? (
          <>
            <SuccessModalContent
              trip={trip}
              handleCloseModal={handleCloseModal}
              showSecondaryButtons={showSecondaryButtons}
            />
          </>
        ) : (
          <>
            <ModalHeader
              title="Add to your day"
              description={
                <>
                  <strong>Choose the day</strong> you want to add to
                </>
              }
            />
            <Box
              sx={theme => ({
                width: '80%',
                [theme.breakpoints.down('md')]: {
                  width: '100%',
                },
              })}
            >
              <Divider sx={{ width: '100%', mx: 'auto' }} />
            </Box>
            <Space size="md" />
            <RadioGroup
              aria-label="add-option"
              name="add-option"
              value={selectedDayValue}
              onChange={handleDayChange}
            >
              {trip &&
                trip.itinerary &&
                trip.itinerary.days &&
                trip.itinerary.days.length > 0 &&
                [...trip.itinerary.days]
                  .sort((a, b) => a!.dayNumber - b!.dayNumber) // Order by dayNumber
                  .map((day, index) => (
                    <Stack
                      direction="row"
                      key={index}
                      flexWrap="wrap"
                      sx={{
                        paddingTop: 0,
                        paddingBottom: 1,
                      }}
                    >
                      <StyledFormControlLabel
                        key={index}
                        sx={{
                          paddingTop: 0,
                          paddingBottom: 0,
                          alignItems: 'flex-start',
                        }}
                        value={`${day!.id}|${day!.dayNumber + 1}`}
                        control={
                          <Radio sx={{ paddingTop: 0, paddingBottom: 0 }} />
                        }
                        label={
                          <Stack direction="row" key={index} flexWrap="wrap">
                            <Box
                              sx={{
                                fontWeight: 700,
                                marginRight: 1,
                                color: '#2F4F4F',
                              }}
                            >
                              Day {day!.dayNumber}
                            </Box>

                            {day &&
                              day.cities &&
                              day.cities.length > 0 &&
                              day.cities.map((city, index) => (
                                <Box key={index} sx={{ color: '#555555' }}>
                                  {city!.cityName}
                                  {index < day.cities!.length - 1 && (
                                    <Box
                                      component="span"
                                      sx={{ marginRight: 0.5 }}
                                    >
                                      ,
                                    </Box>
                                  )}
                                </Box>
                              ))}
                          </Stack>
                        }
                      />
                    </Stack>
                  ))}
            </RadioGroup>
            <Space size="md" />

            <PrimaryButton
              disabled={loadingStep || selectedDayId === ''}
              onClick={() => {
                handleCopyStep();
              }}
            >
              {loadingStep ? <Loader /> : 'Add'}
            </PrimaryButton>
            <Space size="sm" />
            <Button
              onClick={handleCloseModal}
              sx={theme => ({
                backgroundColor: 'transparent',
                color: theme.palette.primary.main,
                borderRadius: '20px',
                //   border: `2px solid ${theme.palette.primary.main}`,
                paddingX: 3,
                paddingY: 0.75,
                textTransform: 'none',
                fontSize: 16,
                fontWeight: 600,
                //   '&:hover': {
                //     backgroundColor: 'rgba(255, 85, 51, 0.1)',
                //     borderColor: theme.palette.primary.dark,
                //     color: theme.palette.primary.dark,
                //   },
              })}
            >
              Close
            </Button>
          </>
        )}
      </ModalPaper>
    </CenteredModal>
  );
};

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start', // Align radio button to the top
  marginBottom: 1,
  '& .MuiFormControlLabel-label': {
    fontSize: '16px',
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  '& .MuiRadio-root': {
    color: theme.palette.primary.main,
    '&.Mui-checked': {
      color: theme.palette.primary.main,
    },
  },
}));

const IllustrationContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center', // Center the content horizontally
  alignItems: 'center', // Center the content vertically
  width: '100%',
  //   marginTop: theme.spacing(-3),
  //   marginBottom: theme.spacing(-3),
  //   [theme.breakpoints.down('md')]: {
  //     marginTop: theme.spacing(-3),
  //     marginBottom: theme.spacing(-3),
  //   },
}));

const SuccessModalContent: React.FC<{
  trip: any;
  showSecondaryButtons: boolean;
  handleCloseModal: () => void;
}> = ({ trip, showSecondaryButtons, handleCloseModal }) => {
  const url = window.location.pathname;
  // const tripId = url.split('/').pop();
  const navigate = useNavigate();
  const tripId = url.split('/').pop();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const sharableId = params.get('shared_trip_id');
  let sharableIdURL = '';
  if (!!sharableId) {
    sharableIdURL = '&shared_trip_id=' + sharableId;
  }

  return (
    <>
      <IllustrationContainer>
        <SuccessIllustration style={{ maxWidth: '200px', height: 'auto' }} />
      </IllustrationContainer>
      <Space size="lg" />
      {/* <Stack spacing={3} alignItems="center" sx={{ p: 3, textAlign: 'center' }}> */}
      <ModalHeader
        title="Well done, this has been added!"
        description={
          <>
            Successfully added this to the{' '}
            <strong>end of the selected day</strong>
          </>
        }
      />

      <Space size="md" />
      <Stack
        // direction={isSm ? 'column' : 'row'}
        direction={'column'}
        spacing={2}
        justifyContent="center"
        width="100%"
      >
        {showSecondaryButtons ? (
          <>
            <SecondaryButton
              onClick={handleCloseModal}
              color="secondary"
              variant="contained"
              sx={{
                fontSize: 16,
                boxShadow: 2,
                '&:hover': {
                  boxShadow: 4,
                },
              }}
            >
              Continue exploring
            </SecondaryButton>
            <Space size="sm" />
            <PrimaryButton
              onClick={() => {
                handleCloseModal();
                navigate('/editor/' + trip.id);
              }}
              color="primary"
              variant="contained"
              sx={{
                fontSize: 16,
                boxShadow: 2,
                '&:hover': {
                  boxShadow: 4,
                },
              }}
            >
              Open my trip
            </PrimaryButton>
            {/* {trip.id && tripId && (
              <Box
                sx={{
                  display: {
                    xs: 'none',
                    sm: 'none',
                    md: 'block',
                  },
                }}
              >
                <PrimaryButton
                  onClick={() => {
                    handleCloseModal();
                    navigate(
                      '/editor/' +
                        trip.id +
                        '?selected-trip=' +
                        tripId +
                        sharableIdURL,
                    );
                  }}
                  color="secondary"
                  variant="contained"
                  sx={{
                    fontSize: 16,
                    boxShadow: 2,
                    '&:hover': {
                      boxShadow: 4,
                    },
                  }}
                >
                  Open Drag & Drop
                </PrimaryButton>
              </Box>
            )} */}
          </>
        ) : (
          <PrimaryButton
            onClick={handleCloseModal}
            color="secondary"
            variant="contained"
            sx={{
              fontSize: 16,
              boxShadow: 2,
              '&:hover': {
                boxShadow: 4,
              },
            }}
          >
            Close
          </PrimaryButton>
        )}
      </Stack>
      {/* </Stack> */}
    </>
  );
};
