import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { Img } from 'react-image';
import { useSelector } from 'react-redux';

import { publicTripsSelector } from '../../store/PublicTripSlice';
import { tripsSelector } from '../../store/TripSlice';
import AddTripImagesModal from '../create-trip/manage-trip-modals/AddTripImages';

type TripType = 'public' | 'user' | 'preview';

type TripHeaderProps = {
  tripType: TripType;
};

export function TripHeaderImages({ tripType }: TripHeaderProps) {
  const { publicTrip } = useSelector(publicTripsSelector);
  const { trip: userTrip } = useSelector(tripsSelector);
  const isMobile = window.innerWidth <= 600;

  const trip = tripType === 'user' ? userTrip : publicTrip;

  let firstImage,
    lastImage,
    middleImageOne,
    middleImageTwo: string | null | undefined = null;

  if (trip?.images && trip.images.length > 0) {
    // Find the index of the main image, if it exists
    const mainImageIndex = trip.images.findIndex(image => image?.main);

    // Set the main image as firstImage if it exists
    if (mainImageIndex !== -1) {
      const image = trip.images[mainImageIndex];
      firstImage = isMobile
        ? image?.mediumPath ||
          image?.previewPath ||
          image?.path ||
          image?.smallPath
        : image?.mediumPath ||
          image?.previewPath ||
          image?.path ||
          image?.smallPath;

      // Create a new array excluding the main image for the other slots
      const remainingImages = [
        ...trip.images.slice(0, mainImageIndex),
        ...trip.images.slice(mainImageIndex + 1),
      ];

      // Assign other images based on remainingImages
      const middleImage1 = remainingImages[0];
      middleImageOne = isMobile
        ? middleImage1?.smallPath ||
          middleImage1?.previewPath ||
          middleImage1?.mediumPath ||
          middleImage1?.path
        : middleImage1?.previewPath ||
          middleImage1?.smallPath ||
          middleImage1?.mediumPath ||
          middleImage1?.path;

      const middleImage2 = remainingImages[1];
      middleImageTwo = isMobile
        ? middleImage2?.smallPath ||
          middleImage2?.previewPath ||
          middleImage2?.mediumPath ||
          middleImage2?.path
        : middleImage2?.previewPath ||
          middleImage2?.smallPath ||
          middleImage2?.mediumPath ||
          middleImage2?.path;

      const lastImage1 = remainingImages[2];
      lastImage = isMobile
        ? lastImage1?.previewPath ||
          lastImage1?.mediumPath ||
          lastImage1?.smallPath ||
          lastImage1?.path
        : lastImage1?.mediumPath ||
          lastImage1?.previewPath ||
          lastImage1?.smallPath ||
          lastImage1?.path;
    } else {
      // If no main image, assign images in order
      firstImage = isMobile
        ? trip.images[0]?.mediumPath ||
          trip.images[0]?.previewPath ||
          trip.images[0]?.path ||
          trip.images[0]?.smallPath
        : trip.images[0]?.mediumPath ||
          trip.images[0]?.previewPath ||
          trip.images[0]?.path ||
          trip.images[0]?.smallPath;

      middleImageOne = isMobile
        ? trip.images[1]?.previewPath ||
          trip.images[1]?.mediumPath ||
          trip.images[1]?.smallPath ||
          trip.images[1]?.path
        : trip.images[1]?.mediumPath ||
          trip.images[1]?.previewPath ||
          trip.images[1]?.smallPath ||
          trip.images[1]?.path;

      middleImageTwo = isMobile
        ? trip.images[2]?.previewPath ||
          trip.images[2]?.mediumPath ||
          trip.images[2]?.smallPath ||
          trip.images[2]?.path
        : trip.images[2]?.mediumPath ||
          trip.images[2]?.previewPath ||
          trip.images[2]?.smallPath ||
          trip.images[2]?.path;

      lastImage = isMobile
        ? trip.images[3]?.previewPath ||
          trip.images[3]?.mediumPath ||
          trip.images[3]?.smallPath ||
          trip.images[3]?.path
        : trip.images[3]?.mediumPath ||
          trip.images[3]?.previewPath ||
          trip.images[3]?.smallPath ||
          trip.images[3]?.path;
    }
  }

  return (
    <Box
      sx={theme => ({
        position: 'relative',
        paddingLeft: 2,
        borderRadius: '20px',
        marginBottom: 1,
        [theme.breakpoints.down('sm')]: {
          paddingLeft: 0,
        },
      })}
    >
      <Box
        sx={theme => ({
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
          borderRadius: '20px',
          backgroundColor: '#F5E8D3',
          [theme.breakpoints.down('sm')]: {
            borderRadius: 0,
          },
        })}
      >
        {trip && trip.images && trip.images && trip.images.length > 0 ? (
          <>
            {firstImage && middleImageOne && middleImageTwo && lastImage && (
              <Box
                sx={theme => ({
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  [theme.breakpoints.down('md')]: {},
                  [theme.breakpoints.down('sm')]: {
                    marginTop: 0,
                  },
                })}
              >
                <Box
                  sx={theme => ({
                    borderTopLeftRadius: '20px',
                    borderBottomLeftRadius: '20px',
                    width: '100%',
                    marginRight: 0.5,
                    height: 280,
                    [theme.breakpoints.down('md')]: {
                      height: 280,
                    },
                    [theme.breakpoints.down('sm')]: {
                      marginRight: 0.25,
                      height: 280,
                      borderRadius: 0,
                    },
                  })}
                >
                  <Box
                    sx={theme => ({
                      borderTopLeftRadius: '20px',
                      borderBottomLeftRadius: '20px',
                      width: '100%',
                      height: 280,
                      [theme.breakpoints.down('md')]: {
                        height: 280,
                      },
                      [theme.breakpoints.down('sm')]: {
                        borderRadius: 0,
                        height: 280,
                      },
                    })}
                  >
                    <Box
                      sx={theme => ({
                        borderTopLeftRadius: '20px',
                        borderBottomLeftRadius: '20px',
                        width: '100%',
                        height: 280,
                        [theme.breakpoints.down('md')]: {
                          height: 280,
                        },
                        [theme.breakpoints.down('sm')]: {
                          borderRadius: 0,
                          height: 280,
                        },
                      })}
                    >
                      <Img
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                          borderRadius: 'inherit',
                        }}
                        src={firstImage}
                        loader={
                          <Skeleton
                            variant="rectangular"
                            sx={theme => ({
                              width: '100%',
                              height: '100%',
                              borderRadius: 'inherit',
                            })}
                          />
                        }
                      />
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={theme => ({
                    borderTopRightRadius: '20px',
                    borderBottomRightRadius: '20px',
                    marginRight: 0.5,
                    width: '30%',
                    display: 'flex',
                    flexDirection: 'column',
                    height: 280,
                    [theme.breakpoints.down('md')]: {
                      height: 280,
                    },
                    [theme.breakpoints.down('sm')]: {
                      width: '40%',
                      borderRadius: 0,
                      marginRight: 0,
                      height: 280,
                    },
                  })}
                >
                  <Box
                    sx={theme => ({
                      maxWidth: 300,
                      width: '100%',
                      marginBottom: '5px',
                      height: 145,
                      [theme.breakpoints.down('md')]: {
                        height: 145,
                      },
                      [theme.breakpoints.down('sm')]: {
                        marginBottom: '2px',
                        borderRadius: 0,
                        height: 148,
                      },
                    })}
                  >
                    <Img
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        borderRadius: 'inherit',
                      }}
                      src={middleImageOne}
                      loader={
                        <Skeleton
                          variant="rectangular"
                          sx={theme => ({
                            width: '100%',
                            height: '100%',
                            borderRadius: 'inherit',
                          })}
                        />
                      }
                    />
                  </Box>
                  <Box
                    sx={theme => ({
                      maxWidth: 300,
                      width: '100%',
                      height: 130,
                      [theme.breakpoints.down('md')]: {
                        height: 130,
                      },
                      [theme.breakpoints.down('sm')]: {
                        height: 130,
                        borderRadius: 0,
                      },
                    })}
                  >
                    <Img
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        borderRadius: 'inherit',
                      }}
                      src={middleImageTwo}
                      loader={
                        <Skeleton
                          variant="rectangular"
                          sx={theme => ({
                            width: '100%',
                            height: '100%',
                            borderRadius: 'inherit',
                          })}
                        />
                      }
                    />
                  </Box>
                </Box>
                <Box
                  sx={theme => ({
                    display: { xs: 'none', sm: 'block' },
                    borderTopRightRadius: '20px',
                    borderBottomRightRadius: '20px',
                    width: '40%',
                    height: 280,
                    [theme.breakpoints.down('md')]: {
                      height: 280,
                    },
                    [theme.breakpoints.down('sm')]: {
                      borderRadius: 0,
                      height: 280,
                    },
                  })}
                >
                  <Box
                    sx={theme => ({
                      borderTopRightRadius: '20px',
                      borderBottomRightRadius: '20px',
                      width: '100%',
                      height: 280,
                      [theme.breakpoints.down('md')]: {
                        height: 280,
                      },
                      [theme.breakpoints.down('sm')]: {
                        borderRadius: 0,
                        height: 280,
                      },
                    })}
                  >
                    <Img
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        borderRadius: 'inherit',
                      }}
                      src={lastImage}
                      loader={
                        <Skeleton
                          variant="rectangular"
                          sx={theme => ({
                            width: '100%',
                            height: '100%',
                            borderRadius: 'inherit',
                          })}
                        />
                      }
                    />
                  </Box>
                </Box>
              </Box>
              // </Box>
            )}
            {firstImage && middleImageOne && middleImageTwo && !lastImage && (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={theme => ({
                    borderTopLeftRadius: '20px',
                    borderBottomLeftRadius: '20px',
                    width: '100%',
                    marginRight: 0.5,
                    height: 280,
                    [theme.breakpoints.down('md')]: {
                      height: 280,
                    },
                    [theme.breakpoints.down('sm')]: {
                      borderRadius: 0,
                      height: 280,
                    },
                  })}
                >
                  <Box
                    sx={theme => ({
                      borderTopLeftRadius: '20px',
                      borderBottomLeftRadius: '20px',
                      width: '100%',
                      height: 280,
                      [theme.breakpoints.down('md')]: {
                        height: 280,
                      },
                      [theme.breakpoints.down('sm')]: {
                        borderRadius: 0,
                        height: 280,
                      },
                    })}
                  >
                    <Img
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        borderRadius: 'inherit',
                      }}
                      src={firstImage}
                      loader={
                        <Skeleton
                          variant="rectangular"
                          sx={theme => ({
                            width: '100%',
                            height: '100%',
                            borderRadius: 'inherit',
                          })}
                        />
                      }
                    />
                  </Box>
                </Box>
                <Box
                  sx={theme => ({
                    borderTopRightRadius: '20px',
                    borderBottomRightRadius: '20px',
                    width: '40%',
                    display: 'flex',
                    flexDirection: 'column',
                    height: 280,
                    [theme.breakpoints.down('md')]: {
                      height: 280,
                    },
                    [theme.breakpoints.down('sm')]: {
                      borderRadius: 0,
                      height: 280,
                    },
                  })}
                >
                  <Box
                    sx={theme => ({
                      borderTopRightRadius: '20px',
                      width: '100%',
                      height: 140,
                      marginBottom: '5px',
                      [theme.breakpoints.down('md')]: {
                        height: 140,
                      },
                      [theme.breakpoints.down('sm')]: {
                        marginBottom: '4px',
                        borderRadius: 0,
                        height: 140,
                      },
                    })}
                  >
                    <Img
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        borderRadius: 'inherit',
                      }}
                      src={middleImageOne}
                      loader={
                        <Skeleton
                          variant="rectangular"
                          sx={theme => ({
                            width: '100%',
                            height: '100%',
                            borderRadius: 'inherit',
                          })}
                        />
                      }
                    />
                  </Box>
                  <Box
                    sx={theme => ({
                      borderBottomRightRadius: '20px',
                      width: '100%',
                      height: 135,
                      [theme.breakpoints.down('md')]: {
                        height: 135,
                      },
                      [theme.breakpoints.down('sm')]: {
                        borderRadius: 0,
                        height: 136,
                      },
                    })}
                  >
                    <Img
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        borderRadius: 'inherit',
                      }}
                      src={middleImageTwo}
                      loader={
                        <Skeleton
                          variant="rectangular"
                          sx={theme => ({
                            width: '100%',
                            height: '100%',
                            borderRadius: 'inherit',
                          })}
                        />
                      }
                    />
                  </Box>
                </Box>
              </Box>
            )}
            {firstImage && middleImageOne && !middleImageTwo && !lastImage && (
              <>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={theme => ({
                      borderTopLeftRadius: '20px',
                      borderBottomLeftRadius: '20px',
                      width: '100%',
                      marginRight: 0.5,
                      height: 240,
                      [theme.breakpoints.down('md')]: {
                        height: 240,
                      },
                      [theme.breakpoints.down('sm')]: {
                        borderRadius: 0,
                        height: 280,
                      },
                    })}
                  >
                    <Box
                      sx={theme => ({
                        borderTopLeftRadius: '20px',
                        borderBottomLeftRadius: '20px',
                        width: '100%',
                        height: 240,
                        [theme.breakpoints.down('md')]: {
                          height: 240,
                        },
                        [theme.breakpoints.down('sm')]: {
                          borderRadius: 0,
                          height: 280,
                        },
                      })}
                    >
                      <Img
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                          borderRadius: 'inherit',
                        }}
                        src={firstImage}
                        loader={
                          <Skeleton
                            variant="rectangular"
                            sx={theme => ({
                              width: '100%',
                              height: '100%',
                              borderRadius: 'inherit',
                            })}
                          />
                        }
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={theme => ({
                      borderTopRightRadius: '20px',
                      borderBottomRightRadius: '20px',
                      width: '45%',
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                    })}
                  >
                    <Box
                      sx={theme => ({
                        borderTopRightRadius: '20px',
                        borderBottomRightRadius: '20px',
                        width: '100%',
                        height: 240,
                        [theme.breakpoints.down('md')]: {
                          height: 240,
                        },
                        [theme.breakpoints.down('sm')]: {
                          borderRadius: 0,
                          height: 280,
                        },
                      })}
                    >
                      <Img
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                          borderRadius: 'inherit',
                        }}
                        src={middleImageOne}
                        loader={
                          <Skeleton
                            variant="rectangular"
                            sx={theme => ({
                              width: '100%',
                              height: '100%',
                              borderRadius: 'inherit',
                            })}
                          />
                        }
                      />
                    </Box>
                  </Box>
                </Box>
              </>
            )}

            {firstImage && !lastImage && !middleImageOne && !middleImageTwo && (
              <>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={theme => ({
                      borderRadius: '20px',
                      width: '100%',
                      height: 240,
                      [theme.breakpoints.down('md')]: {
                        height: 240,
                      },
                      [theme.breakpoints.down('sm')]: {
                        borderRadius: 0,
                        height: 280,
                      },
                    })}
                  >
                    <Box
                      sx={theme => ({
                        borderRadius: '20px',
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        [theme.breakpoints.down('md')]: {
                          height: '100%',
                        },
                        [theme.breakpoints.down('sm')]: {
                          borderRadius: 0,
                          height: '100%',
                        },
                      })}
                    >
                      <Img
                        src={firstImage}
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                          borderRadius: 'inherit',
                        }}
                        loader={
                          <Skeleton
                            variant="rectangular"
                            sx={theme => ({
                              width: '100%',
                              height: '100%',
                              borderRadius: 'inherit',
                            })}
                          />
                        }
                      />
                    </Box>
                  </Box>
                </Box>
              </>
            )}
          </>
        ) : (
          <>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box
                sx={theme => ({
                  width: '100%',
                  height: 240,
                  [theme.breakpoints.down('md')]: {
                    height: 240,
                  },
                  [theme.breakpoints.down('sm')]: {
                    borderRadius: 0,
                    height: 280,
                  },
                })}
              >
                <Box
                  sx={theme => ({
                    borderRadius: '20px',
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    [theme.breakpoints.down('md')]: {
                      height: '100%',
                    },
                    [theme.breakpoints.down('sm')]: {
                      borderRadius: 0,
                      height: '100%',
                    },
                  })}
                >
                  <Img
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      borderRadius: 'inherit',
                    }}
                    src={'/assets/image/placeholder-svg.svg'}
                    loader={
                      <Skeleton
                        variant="rectangular"
                        sx={theme => ({
                          width: '100%',
                          height: '100%',
                          borderRadius: 'inherit',
                        })}
                      />
                    }
                  />
                </Box>
              </Box>
            </Box>
          </>
        )}

        {trip && tripType === 'user' && (
          <AddTripImagesModal tripId={trip.id} trip={trip} />
        )}
      </Box>
    </Box>
  );
}
