import { gql } from 'graphql-tag';

export const QUERY_USER_PUBLIC_TRIPS = gql`
  query userPublicTrips($user_id: String!) {
    userPublicTrips(user_id: $user_id) {
      publicUserInfo {
        id
        nickName
        tikTokUrl
        instagramUrl
        youTubeUrl
        blogUrl
        profilePictureUrl
      }
      trips {
        id
        name
        tripType
        length
        images {
          id
          path
          previewPath
          mediumPath
          smallPath
          name
          header
          main
          secondary
          tertiary
        }
        countries {
          id
          name
          description
          url
          iso2Code
          iso3Code
        }
        cities {
          id
          name
        }
        itineraryCountries {
          iso3Code
          countryName
          itineraryId
        }
        userDetails {
          id
          nickName
          tikTokUrl
          instagramUrl
          youTubeUrl
          blogUrl
          profilePictureUrl
        }
      }
    }
  }
`;
