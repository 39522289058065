import ListIcon from '@mui/icons-material/List';
import MapIcon from '@mui/icons-material/Map';
import {
  Box,
  Stack,
  IconButton,
  Drawer,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';

import { TripMap, TripMapProps } from './TripMap';

export function MapDrawer({
  tripOwner,
  onMarkerClick,
  onCloseClick,
  onDayClick,
  clickedDayId,
  stepInView,
  mapId,
}: TripMapProps) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <Box>
      <Box
        sx={theme => ({
          position: 'fixed',
          top: isMobile ? '2px' : '4px',
          right: '0px', // Touch the right edge
          paddingY: isMobile ? 0 : 0.5,
          paddingX: isMobile ? 0.2 : 0.5,
          zIndex: 40000,
          display: { xs: 'flex', sm: 'none' }, // Show only on small screens
          flexDirection: 'column', // Align icons and titles vertically
          // backgroundColor: '#E4DBFF', // Use a light color that blends with the app (adjust to your theme)
          backgroundColor: '#e0ebf5',
          color: '#1c3a57', // Match the text color with your theme
          fontWeight: 'bold', // Make the text more prominent
          borderTopLeftRadius: '12px', // Larger radius for a smoother corner
          borderBottomLeftRadius: '12px', // Same as above
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)', // Slightly stronger shadow to stand out more
          border: '2px solid rgb(118, 83, 220, 0.6)', // Optional: subtle border to match theme
          '&:hover': {
            backgroundColor: '#e0ebf5', // Slightly darker on hover
          },
        })}
      >
        <IconButton
          onClick={toggleDrawer}
          sx={theme => ({
            // backgroundColor: theme.palette.background.paper, // Using theme color for background
            display: { xs: 'flex', sm: 'none' }, // Show only on small screens
            backgroundColor: 'transparent',
          })}
        >
          {isDrawerOpen ? (
            <Stack direction="row" spacing={1}>
              <ListIcon sx={{ color: '#F39C6A' }} />
              <Typography
                sx={theme => ({
                  fontWeight: 600,
                  color: '#1C3A57',
                })}
              >
                Back
              </Typography>
            </Stack>
          ) : (
            <Stack direction="row" spacing={1}>
              <MapIcon sx={{ color: '#F39C6A' }} />
              <Typography
                sx={theme => ({
                  fontWeight: 600,
                  color: '#1C3A57',
                })}
              >
                Map
              </Typography>
            </Stack>
          )}
        </IconButton>
      </Box>
      {/* Drawer Component */}
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={toggleDrawer}
        sx={{
          width: '100vw',
          touchAction: 'none',
          height: '100vh',
          '& .MuiDrawer-paper': {
            width: '100vw',
            height: '100vh',
            position: 'fixed',
            overscrollBehavior: 'contain', // Prevents scroll chaining
          },
        }} // Set the width of the drawer
      >
        <Box
          sx={theme => ({
            width: '100vw',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            touchAction: 'none',
          })}
        >
          {/* Placeholder for Mapbox */}
          <Box
            style={{
              width: '100%',
              height: '100%',
              backgroundColor: '#e0e0e0', // Light gray background
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '24px',
              fontWeight: 'bold',
            }}
            onClick={e => e.stopPropagation()}
            onTouchStart={e => e.stopPropagation()}
            onWheel={e => e.stopPropagation()}
          >
            <TripMap
              tripOwner={tripOwner}
              onMarkerClick={onMarkerClick}
              onCloseClick={onCloseClick}
              onDayClick={onDayClick}
              openOnMobile={isDrawerOpen}
              clickedDayId={clickedDayId}
              stepInView={stepInView}
            />
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
}

export default MapDrawer;
