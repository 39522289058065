import CreditCardTwoToneIcon from '@mui/icons-material/CreditCardTwoTone';
import EventTwoToneIcon from '@mui/icons-material/EventTwoTone';
import HistoryToggleOffTwoToneIcon from '@mui/icons-material/HistoryToggleOffTwoTone';
import LocalActivityTwoToneIcon from '@mui/icons-material/LocalActivityTwoTone';
import PlaceTwoToneIcon from '@mui/icons-material/PlaceTwoTone';
import UpdateTwoToneIcon from '@mui/icons-material/UpdateTwoTone';
import { Box, Typography, Stack, Rating, Divider, Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import StarRateTwoToneIcon from '@mui/icons-material/StarRateTwoTone';
import StarTwoToneIcon from '@mui/icons-material/StarTwoTone';

import {
  Maybe,
  Place,
  Step,
  Image,
  StepPlaceImage,
} from '../../generated/public_graphql';
import { LinkButton } from '../../theme-components/Buttons';
import {
  CategoryIcon,
  CustomBookingComBlueIcon,
  GetYourGuideIcon,
} from '../../theme-components/Icons';
import Space from '../../theme-components/Spacing';
import TextDescription, {
  Body1,
  TitleH4,
  TitleH5,
} from '../../theme-components/Typography';
import { formatReviewString } from '../../utils/helpers';
import { ActionsStep } from '../create-trip/edit-trip/ActionsStep';
import { LikePlace } from '../favourite/likePlace';
import GoogleIcon from '../icons/GoogleIcon';
import {
  RenderHtmlFromResponseNoMargin,
  RenderHtmlTest,
} from '../RenderHtmlFromResponse';
import { ExternalLinkRegular } from '../trip-details/TripDay';

import { AddToTripButton } from './AddToTrip';
import {
  PlaceOrActivityMoreInfoModal,
  TodaysHours,
} from './PlaceMoreInfoModal';
import InstagramEmbedModal, {
  PhotosModal,
  TikTokEmbedModal,
  YouTubeEmbedModal,
} from './InstagramEmbeded';
import PlaceCardCTAs from './PublicPlaceCardCTA';
import { SideScrollRecommendationsForStep } from './Recommendation';
import { StepPlaceImages, PlaceSingleImage } from './StepImages';
import { TripUser } from './TripItinerary';
import { useEffect, useRef, useState } from 'react';

interface VisibleElement {
  stepId: string;
  distance: number;
}

declare global {
  interface Window {
    visibleElements?: VisibleElement[];
  }
}

interface PlaceCardProps {
  step: Step;
  dayId?: string;
  selected?: boolean;
  tripId?: string;
  showEdit?: boolean;
  collapsedDetails?: boolean;
  tripType: string;
  tripUser: TripUser;
  onCardInView?: (stepId: string) => void;
  highlightedStepId?: string;
  isRenderedOnLockedPublicTrip?: boolean;
}

export const PublicPlaceCard: React.FC<PlaceCardProps> = ({
  step,
  dayId,
  selected,
  tripId,
  showEdit,
  collapsedDetails,
  tripType,
  tripUser,
  onCardInView,
  highlightedStepId,
  isRenderedOnLockedPublicTrip = false,
}) => {
  let place: Place | null = null;
  const cardRef = useRef<HTMLDivElement>(null);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (!cardRef.current || !onCardInView || !step.id) return;
    let isStepHandled = false;

    const checkPosition = () => {
      if (!cardRef.current) return;
      const rect = cardRef.current.getBoundingClientRect();
      const viewportHeight = window.innerHeight;
      const viewportCenter = viewportHeight / 2;
      const elementCenter = rect.top + rect.height / 2;
      const tolerance = rect.height / 2;

      // Check if element center is within tolerance of viewport center
      if (
        Math.abs(elementCenter - viewportCenter) < tolerance &&
        !isStepHandled
      ) {
        isStepHandled = true;
        setTimeout(() => {
          // if (step.id !== highlightedStepId) {
          //   onCardInView(step.id);
          // }
          if (!cardRef.current) return;

          // Recalculate the position to check visibility
          const updatedRect = cardRef.current.getBoundingClientRect();
          const updatedElementCenter = updatedRect.top + updatedRect.height / 2;

          if (Math.abs(updatedElementCenter - viewportCenter) < tolerance) {
            if (step.id !== highlightedStepId) {
              onCardInView(step.id);
            }
          }
        }, 500);
      }
    };

    // Use IntersectionObserver just to trigger position check
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            checkPosition();
          }
        });
      },
      { threshold: [0.5] },
    );

    observer.observe(cardRef.current);
    window.addEventListener('scroll', checkPosition);

    return () => {
      observer.disconnect();
      window.removeEventListener('scroll', checkPosition);
    };
  }, [step.id, onCardInView]);

  if (step && step.place) {
    place = step.place;
  }
  if (!step) {
    return <></>;
  }

  let customStepImages: Maybe<Image>[] = [];
  if (step.images && step.images.length > 0) {
    customStepImages = step.images;
  }

  let placeImages: Maybe<StepPlaceImage>[] = [];
  if (step && step.placeImages && step.placeImages.length > 0) {
    if (
      !step.place?.category?.toLowerCase().includes('car_rental') &&
      !step.place?.category?.toLowerCase().includes('airport')
    ) {
      placeImages = step.placeImages;
    }
  }

  let instagramLink: string | null = null;

  if (step && step.socialLinks && step.socialLinks.length > 0) {
    const instagramItem = step.socialLinks.find(
      item => item?.socialType === 'instagram',
    );
    if (instagramItem) {
      instagramLink = instagramItem.urlLink!;
    }
  }

  let tikTokLink: string | null = null;

  if (step && step.socialLinks && step.socialLinks.length > 0) {
    const tikTokItem = step.socialLinks.find(
      item => item?.socialType === 'tiktok',
    );
    if (tikTokItem) {
      tikTokLink = tikTokItem.urlLink!;
    }
  }

  let youTubeLink: string | null = null;

  if (step && step.socialLinks && step.socialLinks.length > 0) {
    const youTubeItem = step.socialLinks.find(
      item => item?.socialType === 'youtube',
    );
    if (youTubeItem) {
      youTubeLink = youTubeItem.urlLink!;
    }
  }

  const highlight = highlightedStepId === step.id || selected;

  const scrollToCenter = (element: HTMLElement) => {
    if (!element) return;

    // Calculate the top position of the element relative to the viewport
    const elementTop = element.getBoundingClientRect().top + window.scrollY;
    const middleOfScreen = window.innerHeight / 2;
    const elementCenter = elementTop + element.offsetHeight / 2;
    const scrollPosition = elementCenter - middleOfScreen;

    window.scrollTo({
      top: scrollPosition,
      behavior: 'smooth',
    });
  };

  return (
    <Stack
      ref={cardRef}
      className="step-card"
      direction="column"
      alignItems="left"
      sx={theme => ({
        width: '100%',
        paddingTop: 1.5,
        border: 2,
        borderColor: highlight ? theme.palette.primary.light : '#E4DBFF',
        paddingBottom: 2,
        paddingLeft: 1.5,
        paddingRight: 1.5,
        borderRadius: '20px',
        backgroundColor: '#f6f0ff',
        marginBottom: 0,
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        [theme.breakpoints.down('sm')]: {
          fontSize: 16,
          marginBottom: 0,
        },
      })}
    >
      <Stack
        direction="row"
        alignItems="left"
        sx={theme => ({
          width: '100%',
          [theme.breakpoints.down('sm')]: {
            fontSize: 16,
          },
        })}
      >
        <Box
          onClick={() => {
            if (cardRef.current) {
              scrollToCenter(cardRef.current);
            }
          }}
          sx={theme => ({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            alignSelf: 'flex-start',
            marginRight: 0.5,
            marginLeft: -0.5,
            [theme.breakpoints.down('sm')]: {
              paddingTop: 0.5,
              marginRight: 0.5,
            },
          })}
        >
          {/* <PlaceTwoToneIcon
            sx={theme => ({
              width: 25,
              height: 25,
              color: selected
                ? theme.palette.primary.main
                : theme.palette.icon.main,
            })}
          /> */}
          <Box sx={{ position: 'relative', display: 'inline-block' }}>
            <FmdGoodIcon
              sx={theme => ({
                width: 30,
                height: 30,
                color: highlight ? theme.palette.primary.main : '#9E7FEF',
              })}
            />

            <CategoryIcon
              type={place && place.placeType}
              category={place && place.category}
              source={place && place.dataSource}
              returnFallback={false}
              sx={theme => ({
                position: 'absolute',
                top: '37%',
                left: '50%',
                background: highlight ? theme.palette.primary.main : '#9E7FEF',
                transform: 'translate(-50%, -50%)',
                width: 14,
                height: 14,
                color: '#FFFFFF',
              })}
            />
          </Box>
        </Box>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="top"
          sx={{
            width: '100%',
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          {step.title ? (
            <Box
              onClick={() => {
                if (cardRef.current) {
                  scrollToCenter(cardRef.current);
                }
              }}
              sx={theme => ({
                [theme.breakpoints.down('sm')]: {
                  display: 'flex',
                  alignItems: 'center',
                },
              })}
            >
              <TitleH4>{step.title}</TitleH4>
            </Box>
          ) : (
            <Box />
          )}
          <Stack direction="row" spacing={0.75}>
            {place && <LikePlace placeId={place.id} />}
            {dayId ? (
              <Box>
                <AddToTripButton
                  stepId={step.id}
                  dayId={dayId}
                  buttonType="insideTitle"
                  buttonCopy="Save"
                  showDropDownIcon={true}
                  isRenderedOnLockedPublicTrip={isRenderedOnLockedPublicTrip}
                />
              </Box>
            ) : (
              <>
                {tripId && !collapsedDetails && (
                  <Box>
                    {showEdit && (
                      <ActionsStep
                        copy={'Edit'}
                        step={step}
                        tripId={tripId}
                        tripType={tripType}
                      />
                    )}
                  </Box>
                )}
              </>
            )}
          </Stack>
        </Stack>
      </Stack>

      <Box
        display={collapsedDetails === true ? 'none' : undefined}
        sx={theme => ({
          width: '100%',
          position: 'relative',
          [theme.breakpoints.down('sm')]: {},
        })}
      >
        {place?.rating && (
          <>
            <Stack direction="row" alignItems="center">
              <StarRateTwoToneIcon
                sx={theme => ({
                  width: 25,
                  height: 25,
                  marginRight: 1,
                  color: 'rgb(250, 175, 0)',
                })}
              />

              {place.rating && (
                <Stack direction="row" spacing={0.5}>
                  {place.noOfReviews && (
                    <>
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: 16,
                          alignSelf: 'center',
                          color: '#36454F',
                          marginRight: 0.5,
                        }}
                      >
                        {place.rating}{' '}
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: 16,
                          alignSelf: 'center',
                          color: '#6E7191',
                          marginRight: 0.5,
                        }}
                      >
                        ~{formatReviewString(place.noOfReviews)}
                      </Typography>
                    </>
                  )}
                </Stack>
              )}
            </Stack>
            <Space size="xs" />
          </>
        )}
        {/* Operating Hours */}
        {place?.openingHours && place?.openingHours.length > 0 && (
          <>
            <TodaysHours place={place} />
            <Space size="xs" />
          </>
        )}
        {place?.checkInTime && (
          <>
            <Stack direction="row" alignItems="center">
              <HistoryToggleOffTwoToneIcon
                sx={theme => ({
                  width: 25,
                  height: 25,
                  marginRight: 1,
                  color: theme.palette.icon.main,
                  // color: '#9E7FEF',
                  // [theme.breakpoints.down('sm')]: {
                  //   marginRight: 0.5,
                  //   width: 20,
                  //   height: 20,
                  // },
                })}
              />
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: 16,
                  alignSelf: 'center',
                  color: '#36454F',
                  marginRight: 0.5,
                }}
              >
                Check In:
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: 16,
                  alignSelf: 'center',
                  color: '#6E7191',
                  marginRight: 0.5,
                }}
              >
                {place.checkInTime && place.checkInTime}
              </Typography>
            </Stack>
            <Space size="xs" />
          </>
        )}
        {place?.checkOutTime && (
          <Stack direction="row" alignItems="center">
            <UpdateTwoToneIcon
              sx={theme => ({
                width: 25,
                height: 25,
                marginRight: 1,
                color: theme.palette.icon.main,
                // color: '#9E7FEF',

                // [theme.breakpoints.down('sm')]: {
                //   marginRight: 0.5,
                //   width: 20,
                //   height: 20,
                // },
              })}
            />
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: 16,
                alignSelf: 'center',
                color: '#36454F',
                marginRight: 0.5,
              }}
            >
              Check Out:
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 16,
                alignSelf: 'center',
                color: '#6E7191',
                marginRight: 0.5,
              }}
            >
              {place.checkInTime && place.checkOutTime}
            </Typography>
          </Stack>
        )}
        {place && (
          <PriceInfo
            minPrice={place.minPrice}
            maxPrice={place.maxPrice}
            admissionPrice={place.admissionPrice}
            priceCategory={place.priceCategory}
            priceCurrency={place.priceCurrency}
          />
        )}
        {place?.bookingLink && (
          <>
            <Stack direction="row" alignItems="center">
              <EventTwoToneIcon
                sx={theme => ({
                  width: 25,
                  height: 25,
                  marginRight: 1,
                  color: theme.palette.icon.main,
                  // color: '#9E7FEF',

                  // [theme.breakpoints.down('sm')]: {
                  //   marginRight: 0.5,
                  //   width: 20,
                  //   height: 20,
                  // },
                })}
              />
              <LinkButton
                url={place.bookingLink}
                type="external"
                prominent={true}
              >
                Check availability
              </LinkButton>
            </Stack>
            <Space size="xs" />
          </>
        )}
        <Space size="xs" />
        <PlaceCardCTAs
          place={place ? place : undefined}
          lat={step.latitude ? step.latitude : undefined}
          lng={step.longitude ? step.longitude : undefined}
        />

        {step.description && (
          <>
            <Box>
              <Box sx={{ mt: 1 }}>
                <Divider />
              </Box>
              <Body1>
                <RenderHtmlFromResponseNoMargin
                  content={step.description}
                  limitToLines={3}
                />
              </Body1>
            </Box>
          </>
        )}
        {/* // todo hide when no socials */}
        {(instagramLink || tikTokLink || youTubeLink) && (
          <>
            <TitleH5>Check out on</TitleH5>
            <Stack
              direction="row"
              alignItems="center"
              spacing={0.5}
              sx={{ mb: 1, mt: 0.5, overflow: 'hidden' }}
            >
              {step && step.placeImages && step.placeImages.length > 0 && (
                <PhotosModal images={step.placeImages} />
              )}
              {instagramLink && <InstagramEmbedModal url={instagramLink} />}
              {tikTokLink && <TikTokEmbedModal url={tikTokLink} />}
              {youTubeLink && <YouTubeEmbedModal url={youTubeLink} />}
            </Stack>
          </>
        )}
        {step.url && <ExternalLinkRegular url={step.url} copy={'Read more'} />}
        <Box>
          {customStepImages.length > 0 && (
            <>
              {customStepImages.length > 1 ? (
                <StepPlaceImages images={customStepImages} />
              ) : (
                <PlaceSingleImage images={customStepImages} />
              )}
            </>
          )}
          {customStepImages.length === 0 && placeImages.length > 0 && (
            <>
              {placeImages.length > 1 ? (
                <StepPlaceImages images={placeImages} />
              ) : (
                <PlaceSingleImage images={placeImages} />
              )}
            </>
          )}
        </Box>
        {step.recommendations &&
          step.recommendations.length > 0 &&
          (step.recommendations[0]?.place ||
            step.recommendations[0]?.activity) && (
            <SideScrollRecommendationsForStep
              recommentations={step.recommendations}
              type="step"
              step={step}
              tripUser={tripUser}
            />
          )}
      </Box>
    </Stack>
  );
};

interface PriceInfoProps {
  admissionPrice: number | null | undefined;
  minPrice: number | null | undefined;
  maxPrice: number | null | undefined;
  priceCurrency: string | null | undefined;
  priceCategory: string | null | undefined;
  renderSmall?: boolean;
}

export const PriceInfo: React.FC<PriceInfoProps> = ({
  admissionPrice,
  minPrice,
  maxPrice,
  priceCategory,
  priceCurrency,
  renderSmall = false,
}) => {
  // **Condition 1:** Free Entry
  if (admissionPrice === 0) {
    return (
      <Box>
        <Stack direction="row" alignItems="center">
          <LocalActivityTwoToneIcon
            sx={theme => ({
              width: renderSmall ? 20 : 20,
              height: renderSmall ? 20 : 20,
              marginRight: 0.5,
              // color: theme.palette.icon?.main || '#6E7191',
              color: '#9E7FEF',
            })}
          />
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: renderSmall ? 14 : 14,
              color: '#36454F',
              marginRight: 0.5,
            }}
          >
            Free entry
          </Typography>
        </Stack>
      </Box>
    );
  }

  // **Condition 2:** Tickets Available
  if (admissionPrice) {
    return (
      <Box>
        <Stack direction="row" alignItems="center">
          <LocalActivityTwoToneIcon
            sx={theme => ({
              width: renderSmall ? 20 : 20,
              height: renderSmall ? 20 : 20,
              marginRight: 0.5,
              color: '#9E7FEF',

              // color: theme.palette.icon?.main || '#6E7191',
            })}
          />
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: renderSmall ? 14 : 14,
              color: '#6E7191',
              marginRight: 0.5,
            }}
          >
            <Typography
              component="span"
              sx={{
                fontWeight: 600,
                fontSize: renderSmall ? 14 : 14,
                color: '#36454F',
                marginRight: 0.5,
              }}
            >
              Tickets from:
            </Typography>
            {admissionPrice}
            {priceCurrency === 'USD' && '$'} {priceCurrency === 'GBP' && '£'}{' '}
            {priceCategory ? priceCategory : 'per person'}
          </Typography>
        </Stack>
      </Box>
    );
  }

  // **Condition 3:** Price Range
  if (minPrice) {
    return (
      <Box>
        <Stack direction="row" alignItems="flex_start">
          <CreditCardTwoToneIcon
            sx={theme => ({
              width: renderSmall ? 20 : 20,
              height: renderSmall ? 20 : 20,
              marginRight: 0.5,
              color: '#9E7FEF',

              // color: theme.palette.icon?.main || '#6E7191',
            })}
          />
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: renderSmall ? 14 : 14,
              color: '#6E7191',
              marginRight: 0.5,
            }}
          >
            <Typography
              component="span"
              sx={{
                fontWeight: 600,
                fontSize: renderSmall ? 14 : 14,
                color: '#36454F',
                marginRight: 0.5,
              }}
            >
              From:
            </Typography>
            {minPrice}
            {priceCurrency === 'GBP' && '£'}
            {maxPrice && ` - ${maxPrice}`}
            {maxPrice && priceCurrency === 'GBP' && '£'}{' '}
            {priceCategory ? priceCategory : 'per person'}
          </Typography>
        </Stack>
      </Box>
    );
  }

  // **No Admission Information**
  return null;
};
