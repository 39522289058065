import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import HistoryToggleOffTwoToneIcon from '@mui/icons-material/HistoryToggleOffTwoTone';
import TourTwoToneIcon from '@mui/icons-material/TourTwoTone';

import {
  Box,
  Button,
  CardMedia,
  Grid,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import { Maybe } from 'graphql/jsutils/Maybe';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Trip } from '../../generated/public_graphql';
import { CardLabel } from '../../theme-components/Lables';
import { LikeTrip } from '../favourite/likeTrip';
import { TravelSvgWithBackground } from '../illustrations/Travel';
import { ContainedUserProfile } from '../trip-details/TripDetails';

interface PropsSharedTripsWithUserList {
  readonly trips: Maybe<Trip>[];
  readonly route?: string;
}

export function PublicTripItem(props: PropsSharedTripsWithUserList) {
  const navigate = useNavigate();

  let route = '/trip/';
  if (props.route) {
    route = props.route;
  }

  return (
    <Box>
      {props.trips && props.trips.length > 0 && (
        <>
          <Grid container sx={{ flexGrow: 1, display: 'flex', width: '100%' }}>
            {props.trips.map((trip, index) => (
              <React.Fragment key={index}>
                {trip && (
                  <Box
                    sx={theme => ({
                      cursor: 'pointer',
                      marginTop: 2,
                      marginBottom: 1,
                      paddingRight: 2,
                      width: '33%',

                      [theme.breakpoints.down('xl')]: {
                        width: '33.3%',
                      },
                      [theme.breakpoints.down('lg')]: {
                        width: '50%',
                      },
                      [theme.breakpoints.down('md')]: {
                        width: '50%',
                      },
                      [theme.breakpoints.down('sm')]: {
                        width: '100%',
                        paddingRight: 0,
                      },
                    })}
                  >
                    <Stack
                      key={index + trip.id}
                      direction="column"
                      sx={theme => ({
                        cursor: 'pointer',
                        height: '100%',
                        borderRadius: '20px',
                        backgroundColor: 'rgba(250, 250, 250, 0.6)',
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                      })}
                    >
                      <Box
                        onClick={() => {
                          navigate(route + trip.id);
                          gtag('event', 'user-trip-edit-card');
                        }}
                        key={index + trip.id}
                        sx={theme => ({
                          position: 'relative',
                          display: 'flex',
                          flexDirection: 'column',
                          flexGrow: 1,
                          // borderRadius: '20px',
                          // borderTopLeftRadius: '20px',
                          // borderTopRightRadius: '20px',
                          // borderBottomLeftRadius: !props.publicTrip ? 0 : '20px',
                          // borderBottomRightRadius: !props.publicTrip ? 0 : '20px',
                        })}
                      >
                        <Box sx={{ height: '230px' }}>
                          {trip.images && trip.images.length > 0 ? (
                            <CardMedia
                              component="img"
                              height="100%" // Increased height to accommodate the card content
                              sx={{
                                // borderRadius: '20px',
                                borderTopLeftRadius: '20px',
                                borderTopRightRadius: '20px',
                                //     borderBottomLeftRadius: !props.publicTrip
                                //       ? 0
                                //       : '20px',
                                //     borderBottomRightRadius: !props.publicTrip
                                //       ? 0
                                //       : '20px',
                              }}
                              // image={
                              //   trip.images?.find(image => image!.main)?.path ||
                              //   trip.images[0]?.path ||
                              //   '/assets/image/placeholder-svg.svg'
                              // }
                              image={
                                trip?.images?.find(image => image!.main)
                                  ?.mediumPath ||
                                trip?.images?.find(image => image!.main)
                                  ?.previewPath ||
                                trip?.images?.find(image => image!.main)
                                  ?.path ||
                                trip?.images?.find(image => image!.main)
                                  ?.smallPath ||
                                (trip?.images && trip?.images[0]?.mediumPath) ||
                                (trip?.images &&
                                  trip?.images[0]?.previewPath) ||
                                (trip?.images && trip?.images[0]?.path) ||
                                (trip?.images && trip?.images[0]?.smallPath) ||
                                '/assets/image/placeholder-svg.svg'
                              }
                            />
                          ) : (
                            <Box
                              sx={theme => ({
                                // borderRadius: '20px',
                                // backgroundColor: '#d3d3d3',
                                borderTopLeftRadius: '20px',
                                borderTopRightRadius: '20px',
                                // borderBottomLeftRadius: !props.publicTrip
                                //   ? 0
                                //   : '20px',
                                // borderBottomRightRadius: !props.publicTrip
                                //   ? 0
                                //   : '20px',
                                // padding: '25px',
                                // overflow: 'hidden',
                                backgroundColor: '#d3d3d3',
                                // borderRadius: '20px',
                                overflow: 'hidden',
                                // position: 'relative',
                                width: '100%',
                                height: '100%',
                                paddingTop: '15px',
                                paddingBottom: '20px',
                                paddingLeft: '0px',
                                paddingRight: '0px',
                                [theme.breakpoints.down('sm')]: {
                                  paddingTop: '5px',
                                  paddingBottom: '10px',
                                  paddingLeft: '5px',
                                  paddingRight: '5px',
                                },
                              })}
                            >
                              <CardMedia
                                component={TravelSvgWithBackground}
                                sx={{
                                  // width: '100%',
                                  // height: '100%',
                                  objectFit: 'cover',
                                  // borderRadius: 'inherit',
                                  // overflow: 'hidden',
                                  // borderTopLeftRadius: '20px',
                                  // borderTopRightRadius: '20px',
                                  // borderBottomLeftRadius: !props.publicTrip
                                  //   ? 0
                                  //   : '20px',
                                  // borderBottomRightRadius: !props.publicTrip
                                  //   ? 0
                                  //   : '20px',
                                }}
                              />
                            </Box>
                          )}
                        </Box>
                        <LikeTrip tripId={trip!.id} />
                        <CardLabel
                          text={
                            trip && trip.tripType && trip.tripType === 'guide'
                              ? 'Guide'
                              : 'Itinerary'
                          }
                        />

                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            flexGrow: 1,
                            paddingY: 2,
                            paddingX: 1.5,
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              flexGrow: 1,
                            }}
                          >
                            <Typography
                              sx={{
                                marginBottom: 0.5,
                                fontSize: 18,
                                fontWeight: 700,
                                color: '#001B30',
                                display: '-webkit-box',
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: 'vertical',
                                overflow: 'hidden',
                              }}
                            >
                              {trip && trip.name}
                            </Typography>
                          </Box>
                          {trip && trip.userDetails && (
                            <ContainedUserProfile tripUser={trip.userDetails} />
                          )}
                          {trip &&
                          trip.tripType &&
                          trip.tripType === 'guide' ? (
                            <></>
                          ) : (
                            <Stack
                              direction={'row'}
                              alignItems={'center'}
                              mb={0.5}
                            >
                              <HistoryToggleOffTwoToneIcon
                                sx={theme => ({
                                  width: 25,
                                  height: 25,
                                  marginRight: 1,
                                  color: '#F39C6A',
                                  [theme.breakpoints.down('sm')]: {},
                                })}
                              />
                              {trip && trip.length && trip.length > 0 && (
                                <Typography
                                  sx={theme => ({
                                    fontWeight: 600,
                                    fontSize: 16,
                                    color: '#36454F',
                                    [theme.breakpoints.down('sm')]: {
                                      fontSize: 16,
                                    },
                                  })}
                                >
                                  {trip.length}
                                  {trip.length === 1 ? ' day ' : ' days '}
                                  itinerary
                                </Typography>
                              )}
                            </Stack>
                          )}
                          <Stack
                            direction="row"
                            width="100%"
                            alignItems="flex-start" // Center vertically
                            sx={{
                              display: 'flex',
                            }}
                          >
                            {((trip && trip.cities && trip.cities.length > 0) ||
                              (trip &&
                                trip.countries &&
                                trip.countries.length > 0)) && (
                              <TourTwoToneIcon
                                sx={theme => ({
                                  width: 25,
                                  height: 25,
                                  marginRight: 1,
                                  color: '#F39C6A',
                                })}
                              />
                            )}
                            {trip &&
                            trip.tripType &&
                            trip.tripType === 'guide' ? (
                              <>
                                {trip &&
                                  trip.cities &&
                                  trip.cities.length > 0 && (
                                    <Typography
                                      sx={{
                                        display: '-webkit-box',
                                        flexWrap: 'wrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        WebkitLineClamp: 2, // Limit to 2 lines
                                        WebkitBoxOrient: 'vertical',
                                      }}
                                    >
                                      {trip.cities.map((city, index) => (
                                        <React.Fragment key={index}>
                                          <Typography
                                            component="span"
                                            sx={theme => ({
                                              fontWeight: 500,
                                              fontSize: 16,
                                              color: '#36454F',
                                              // color: theme.palette.secondary.main,
                                              [theme.breakpoints.down('sm')]: {
                                                fontSize: 16,
                                              },
                                            })}
                                          >
                                            {city?.name}
                                          </Typography>
                                          {index !==
                                            trip.cities!.length - 1 && (
                                            <Typography
                                              component="span"
                                              sx={{
                                                mx: 1,
                                                fontSize: '1.5rem', // Adjust the size as needed
                                                lineHeight: 1,
                                              }}
                                            >
                                              •
                                            </Typography>
                                          )}
                                        </React.Fragment>
                                      ))}
                                    </Typography>
                                  )}
                              </>
                            ) : (
                              <Typography
                                // component="span"
                                sx={{
                                  maxWidth: '100%',
                                  display: '-webkit-box',
                                  flexWrap: 'wrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  WebkitLineClamp: 2, // Limit to 2 lines
                                  WebkitBoxOrient: 'vertical',
                                }}
                              >
                                {trip &&
                                  trip.countries &&
                                  trip.countries.length > 0 &&
                                  trip.countries.map((country, index) => (
                                    <React.Fragment key={index}>
                                      {country && (
                                        <>
                                          <Typography
                                            component="span"
                                            sx={theme => ({
                                              fontWeight: 500,
                                              fontSize: 16,
                                              color: '#36454F',
                                              // color: theme.palette.secondary.main,
                                              [theme.breakpoints.down('sm')]: {
                                                fontSize: 16,
                                              },
                                            })}
                                          >
                                            {country.name}
                                            {trip.countries!.length - 1 !==
                                              index && (
                                              <Typography
                                                component="span"
                                                sx={{
                                                  fontSize: '1.5rem', // Adjust the size as needed
                                                  lineHeight: 1,
                                                }}
                                              >
                                                {' '}
                                                •{' '}
                                              </Typography>
                                            )}
                                          </Typography>
                                        </>
                                      )}
                                    </React.Fragment>
                                  ))}
                              </Typography>
                            )}
                          </Stack>
                        </Box>
                      </Box>
                    </Stack>
                  </Box>
                )}
              </React.Fragment>
            ))}
          </Grid>
        </>
      )}
    </Box>
  );
}

export const TripTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: 18,
  // color: '#36454F',
  [theme.breakpoints.down('sm')]: {
    fontSize: 18,
  },
}));

export const TripLength = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: 16,
  color: '#36454F',
  // color: theme.palette.secondary.main,
  [theme.breakpoints.down('sm')]: {
    fontSize: 16,
  },
}));

export const TripCountry = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: 16,
  marginRight: 5,
  color: '#36454F',
  // color: theme.palette.secondary.main,
  [theme.breakpoints.down('sm')]: {
    fontSize: 16,
  },
}));

export const TripButtons = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: 16,
  marginRight: 5,
  [theme.breakpoints.down('sm')]: {
    fontSize: 16,
  },
}));
