import { useLazyQuery } from '@apollo/client';
import { Spa } from '@mui/icons-material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CreditCardTwoToneIcon from '@mui/icons-material/CreditCardTwoTone';
import DirectionsIcon from '@mui/icons-material/Directions';
import EventTwoToneIcon from '@mui/icons-material/EventTwoTone';
import MapIcon from '@mui/icons-material/Map';
import LocalActivityTwoToneIcon from '@mui/icons-material/LocalActivityTwoTone';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import UpdateTwoToneIcon from '@mui/icons-material/UpdateTwoTone';
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import StarRateTwoToneIcon from '@mui/icons-material/StarRateTwoTone';

import {
  Box,
  Button,
  CardMedia,
  Divider,
  Grid,
  Link,
  Rating,
  Skeleton,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { Maybe, Recommendation } from '../../generated/public_graphql';
import { Place, Step, Trip } from '../../generated/user_graphql';
import { tripsSelector } from '../../store/TripSlice';
import { GetYourGuideIconLetter } from '../../theme-components/Icons';
import Space from '../../theme-components/Spacing';
import { TitleH4, TitleH5 } from '../../theme-components/Typography';
import { unauthorisedClient } from '../../utils/auth';
import {
  formatReviewString,
  formatSecondsToTimeExtraShort,
  formatToCamelCase,
  formatToUpperCase,
  generateGetYourGuideUrl,
  getPlaceMapUrls,
  openLinkInBrowserNewTab,
} from '../../utils/helpers';
import { AddPlaceActivityToDayModal } from '../create-trip/manage-trip-modals/AddPlaceActivityToDay';
import { FETCH_IMAGE_BY_ID } from '../gql-public/getPlaceImage';

import { CardLabel } from '../../theme-components/Lables';
import { PriceInfo } from './PublicPlaceCard';
import { TransportStepToPlaceDetails } from './TransportStepToPlaceDetails';
import {
  setOpenHoursModal,
  setPlaceIdForOpenHours,
} from '../../store/StepSlice';
import { useDispatch } from 'react-redux';
import { publicTripsSelector } from '../../store/PublicTripSlice';
import { AddToTripButton } from './AddToTrip';
import { TripUser } from './TripItinerary';
import {
  DeleteRecommendationButton,
  RecommendationMenu,
} from '../create-trip/manage-trip-modals/DeleteRecommendationModal';
import { PlaceOrActivityMoreInfoModal } from './PlaceMoreInfoModal';
import { EditImagesModal } from '../create-trip/manage-trip-modals/EditAddStepImages';
import { EditRecommendationImagesModal } from '../create-trip/manage-trip-modals/EditAddRecommendationImages';

interface RecommendationProps {
  readonly recommentations: Maybe<Recommendation>[];
  type: 'trip' | 'day' | 'step';
  step?: Step;
  tripUser: TripUser;
}

export function SideScrollRecommendations(props: RecommendationProps) {
  const contentWrapper = useRef<HTMLDivElement | null>(null);
  const { publicTrip } = useSelector(publicTripsSelector);
  const { trip: userTrip } = useSelector(tripsSelector);
  const trip = props.tripUser === 'user' ? userTrip : publicTrip;

  return (
    <Box width="100%">
      {/* <Space size="sm" /> */}
      <Box sx={{ paddingLeft: 1 }}>
        <TitleH4>
          {props.type === 'trip'
            ? 'Related Activities & Places'
            : 'Related Activities & Places'}
        </TitleH4>
      </Box>

      <StyledGrid ref={contentWrapper}>
        {props.recommentations &&
          props.recommentations.length > 0 &&
          props.recommentations.map((recommendation, index) => (
            <>
              {recommendation?.place && (
                <RecommendationCard
                  recommendation={recommendation}
                  recommendationId={recommendation.id!}
                  tripUser={props.tripUser}
                  place={recommendation.place}
                  category={recommendation.place.category}
                  placeId={
                    recommendation.place && recommendation.place
                      ? recommendation.place.id
                      : null
                  }
                  activityId={
                    recommendation.activity && recommendation.activity
                      ? recommendation.activity.id
                      : null
                  }
                  trip={trip}
                  type={'place'}
                  title={
                    recommendation.place.title
                      ? recommendation.place.title
                      : null
                  }
                  imagePath={
                    recommendation?.images?.[0]?.previewPath ||
                    recommendation?.images?.[0]?.mediumPath ||
                    recommendation?.images?.[0]?.smallPath ||
                    recommendation?.images?.[0]?.path ||
                    recommendation?.linkedImages?.[0]?.previewPath ||
                    recommendation?.linkedImages?.[0]?.mediumPath ||
                    recommendation?.linkedImages?.[0]?.smallPath ||
                    recommendation?.linkedImages?.[0]?.path ||
                    null
                  }
                  imageUrl={
                    recommendation.linkedImages &&
                    recommendation.linkedImages &&
                    recommendation.linkedImages[0] &&
                    recommendation.linkedImages[0].imageUrl
                      ? recommendation.linkedImages[0].imageUrl
                      : null
                  }
                  imageId={
                    recommendation.linkedImages &&
                    recommendation.linkedImages &&
                    recommendation.linkedImages[0] &&
                    recommendation.linkedImages[0].id
                      ? recommendation.linkedImages[0].id
                      : null
                  }
                  rating={recommendation.place.rating}
                  noOfReviews={recommendation.place.noOfReviews}
                  minPrice={recommendation.place.minPrice}
                  maxPrice={recommendation.place.maxPrice}
                  priceCategory={recommendation.place.priceCategory}
                  priceCurrency={recommendation.place.priceCurrency}
                  admissionPrice={recommendation.place.admissionPrice}
                />
              )}
              {recommendation?.activity && (
                <RecommendationCard
                  recommendation={recommendation}
                  recommendationId={recommendation.id!}
                  tripUser={props.tripUser}
                  duration={recommendation.activity.durationInMinutes}
                  validFor={recommendation.activity.validForInMinutes}
                  category={recommendation.activity.category}
                  placeId={
                    recommendation.place && recommendation.place
                      ? recommendation.place.id
                      : null
                  }
                  activityId={
                    recommendation.activity && recommendation.activity
                      ? recommendation.activity.id
                      : null
                  }
                  trip={trip}
                  bookingLink={recommendation.activity.bookingLink}
                  type={'activity'}
                  title={
                    recommendation.activity.name
                      ? recommendation.activity.name
                      : null
                  }
                  imagePath={
                    recommendation?.activity.images?.[0]?.previewPath ||
                    recommendation?.activity.images?.[0]?.mediumPath ||
                    recommendation?.activity.images?.[0]?.smallPath ||
                    recommendation?.activity.images?.[0]?.path ||
                    null
                  }
                  imageUrl={
                    recommendation.linkedImages &&
                    recommendation.linkedImages &&
                    recommendation.linkedImages[0] &&
                    recommendation.linkedImages[0].imageUrl
                      ? recommendation.linkedImages[0].imageUrl
                      : null
                  }
                  imageId={
                    recommendation.linkedImages &&
                    recommendation.linkedImages &&
                    recommendation.linkedImages[0] &&
                    recommendation.linkedImages[0].id
                      ? recommendation.linkedImages[0].id
                      : null
                  }
                  rating={recommendation.activity.rating}
                  noOfReviews={recommendation.activity.noOfReviews}
                  minPrice={recommendation.activity.minPrice}
                  maxPrice={recommendation.activity.maxPrice}
                  priceCategory={recommendation.activity.priceCategory}
                  priceCurrency={recommendation.activity.priceCurrency}
                />
              )}
            </>
          ))}
      </StyledGrid>
      {props.recommentations && props.recommentations.length > 2 && (
        <Box sx={{ display: { xs: 'none', sm: 'block', md: 'block' } }}>
          {props.type === 'day' ? (
            <ScrollButtonsDay>
              <Box
                onClick={() => sideScroll(contentWrapper!.current!, 1, 420, -5)}
              >
                <ButtonBackground>
                  <ChevronLeftIcon />
                </ButtonBackground>
              </Box>
              <ButtonBackground
                onClick={() => sideScroll(contentWrapper!.current!, 1, 420, 5)}
              >
                <ChevronRightIcon />
              </ButtonBackground>
            </ScrollButtonsDay>
          ) : (
            <ScrollButtons>
              <Box
                onClick={() => sideScroll(contentWrapper!.current!, 1, 420, -5)}
              >
                <ButtonBackground>
                  <ChevronLeftIcon />
                </ButtonBackground>
              </Box>
              <ButtonBackground
                onClick={() => sideScroll(contentWrapper!.current!, 1, 420, 5)}
              >
                <ChevronRightIcon />
              </ButtonBackground>
            </ScrollButtons>
          )}
          <Box height={200} />
        </Box>
      )}
    </Box>
  );
}

export function SideScrollRecommendationsForStep(props: RecommendationProps) {
  const contentWrapper = useRef<HTMLDivElement | null>(null);
  const { publicTrip } = useSelector(publicTripsSelector);
  const { trip: userTrip } = useSelector(tripsSelector);
  const trip = props.tripUser === 'user' ? userTrip : publicTrip;

  return (
    <Box
      sx={theme => ({
        width: '100%',
        // width: '738px',

        // [theme.breakpoints.down('xl')]: {
        //   width: '638px',
        // },
        // [theme.breakpoints.down('lg')]: {
        //   width: '468px',
        // },
        // [theme.breakpoints.down('md')]: {
        //   width: '400px',
        // },
        // [theme.breakpoints.down('sm')]: {
        //   width: '100%',
        //   marginTop: 0,
        // },
      })}
    >
      <Space size="md" />
      <TitleH4>Nearby Places & Activities</TitleH4>
      <Space size="sm" />
      <StyledGrid ref={contentWrapper}>
        {props.recommentations &&
          props.recommentations.length > 0 &&
          props.recommentations.map((recommendation, index) => (
            <>
              {recommendation?.place && (
                <RecommendationCardForStep
                  recommendation={recommendation}
                  recommendationId={recommendation.id!}
                  tripUser={props.tripUser}
                  step={props.step}
                  place={recommendation.place}
                  category={recommendation.place.category}
                  placeId={
                    recommendation.place && recommendation.place
                      ? recommendation.place.id
                      : null
                  }
                  activityId={
                    recommendation.activity && recommendation.activity
                      ? recommendation.activity.id
                      : null
                  }
                  trip={trip}
                  type={'place'}
                  title={
                    recommendation.place.title
                      ? recommendation.place.title
                      : null
                  }
                  imagePath={
                    recommendation.place?.images?.[0]?.previewPath ||
                    recommendation.place?.images?.[0]?.path ||
                    null
                  }
                  imageUrl={
                    recommendation.place &&
                    recommendation.place.images &&
                    recommendation.place.images[0] &&
                    recommendation.place.images[0].imageUrl
                      ? recommendation.place.images[0].imageUrl
                      : null
                  }
                  imageId={
                    recommendation.place &&
                    recommendation.place.images &&
                    recommendation.place.images[0] &&
                    recommendation.place.images[0].id
                      ? recommendation.place.images[0].id
                      : null
                  }
                  rating={recommendation.place.rating}
                  noOfReviews={recommendation.place.noOfReviews}
                  minPrice={recommendation.place.minPrice}
                  maxPrice={recommendation.place.maxPrice}
                  priceCategory={recommendation.place.priceCategory}
                  priceCurrency={recommendation.place.priceCurrency}
                  admissionPrice={recommendation.place.admissionPrice}
                />
              )}
              {recommendation?.activity && (
                <RecommendationCardForStep
                  recommendation={recommendation}
                  recommendationId={recommendation.id!}
                  tripUser={props.tripUser}
                  step={props.step}
                  duration={recommendation.activity.durationInMinutes}
                  validFor={recommendation.activity.validForInMinutes}
                  category={recommendation.activity.category}
                  placeId={
                    recommendation.place && recommendation.place
                      ? recommendation.place.id
                      : null
                  }
                  activityId={
                    recommendation.activity && recommendation.activity
                      ? recommendation.activity.id
                      : null
                  }
                  trip={trip}
                  bookingLink={recommendation.activity.bookingLink}
                  type={'activity'}
                  title={
                    recommendation.activity.name
                      ? recommendation.activity.name
                      : null
                  }
                  imagePath={
                    recommendation.activity?.images?.[0]?.previewPath ||
                    recommendation.activity?.images?.[0]?.path ||
                    null
                  }
                  imageUrl={
                    recommendation.activity &&
                    recommendation.activity.images &&
                    recommendation.activity.images[0] &&
                    recommendation.activity.images[0].imageUrl
                      ? recommendation.activity.images[0].imageUrl
                      : null
                  }
                  imageId={
                    recommendation.activity &&
                    recommendation.activity.images &&
                    recommendation.activity.images[0] &&
                    recommendation.activity.images[0].id
                      ? recommendation.activity.images[0].id
                      : null
                  }
                  rating={recommendation.activity.rating}
                  noOfReviews={recommendation.activity.noOfReviews}
                  minPrice={recommendation.activity.minPrice}
                  maxPrice={recommendation.activity.maxPrice}
                  priceCategory={recommendation.activity.priceCategory}
                  priceCurrency={recommendation.activity.priceCurrency}
                />
              )}
            </>
          ))}
      </StyledGrid>
      {props.recommentations && props.recommentations.length > 2 && (
        <Box sx={{ display: { xs: 'none', sm: 'block', md: 'block' } }}>
          <ScrollButtonsStep>
            <Box
              onClick={() => sideScroll(contentWrapper!.current!, 1, 420, -5)}
            >
              <ButtonBackgroundStep>
                <ChevronLeftIcon />
              </ButtonBackgroundStep>
            </Box>
            <ButtonBackgroundStep
              onClick={() => sideScroll(contentWrapper!.current!, 1, 420, 5)}
            >
              <ChevronRightIcon />
            </ButtonBackgroundStep>
          </ScrollButtonsStep>
          <Box height={200} />
        </Box>
      )}
    </Box>
  );
}

const sideScroll = (
  element: HTMLDivElement,
  speed: number,
  distance: number,
  step: number,
) => {
  let scrollAmount = 0;
  const slideTimer = setInterval(() => {
    element.scrollLeft += step;
    scrollAmount += Math.abs(step);
    if (scrollAmount >= distance) {
      clearInterval(slideTimer);
    }
  }, speed);

  // Return a cleanup function
  return () => clearInterval(slideTimer);
};

const StyledGrid = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  display: 'flex',
  // flexGrow: 1,
  flexWrap: 'nowrap',
  overflowX: 'auto',
  overflowY: 'hidden',
  width: '100%',
  // width: '738px',

  // [theme.breakpoints.down('xl')]: {
  //   width: '638px',
  // },
  // [theme.breakpoints.down('lg')]: {
  //   width: '468px',
  // },
  // [theme.breakpoints.down('md')]: {
  //   width: '400px',
  // },
  // [theme.breakpoints.down('sm')]: {
  //   width: '100%',
  //   marginTop: 0,
  // },

  [theme.breakpoints.down('md')]: {
    // paddingLeft: 8,
  },
  /* Hide scrollbar for Webkit-based browsers (Chrome, Safari) */
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  /* Hide scrollbar for IE, Edge */
  '-ms-overflow-style': 'none' /* IE and Edge */,
  /* Hide scrollbar for Firefox */
  'scrollbar-width': 'none' /* Firefox */,
}));

const ScrollButtonsStep = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  marginTop: -125,
  marginBottom: -110,
  justifyContent: 'space-between',
}));

const ScrollButtonsDay = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  marginTop: -205,
  marginBottom: -35,
  justifyContent: 'space-between',
}));

const ScrollButtons = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  marginTop: -235,
  marginBottom: 0,
  justifyContent: 'space-between',
}));

const ButtonBackgroundStep = styled(Box)(({ theme }) => ({
  width: 26,
  height: 26,
  margin: 2,
  backgroundColor: 'rgba(255, 243, 219, 0.9)', // Semi-transparent background
  borderRadius: 20,
  position: 'relative',
  padding: 1,
}));

const ButtonBackground = styled(Box)(({ theme }) => ({
  width: 32,
  height: 32,
  margin: 5,
  backgroundColor: '#FFF3DB',
  borderRadius: 20,
  position: 'relative',
  padding: 4,
}));

interface RecommendationPlaceCardProps {
  imagePath: string | null | undefined;
  imageUrl: string | null | undefined;
  imageId: string | null | undefined;
  title: string | null | undefined;
  rating: number | null | undefined;
  noOfReviews: string | null | undefined;
  type: 'place' | 'activity';
  bookingLink?: string | null | undefined;
  trip: Trip | null | undefined;
  placeId?: string | null | undefined;
  activityId?: string | null | undefined;
  duration?: number | null | undefined;
  validFor?: number | null | undefined;
  category?: string | null | undefined;
  minPrice?: number | null | undefined;
  maxPrice?: number | null | undefined;
  admissionPrice?: number | null | undefined;
  priceCurrency?: string | null | undefined;
  priceCategory?: string | null | undefined;
  place?: Place | undefined;
  step?: Step | undefined;
  tripUser: TripUser;
  recommendationId: string;
  recommendation: Recommendation;
}

const RecommendationCard: React.FC<RecommendationPlaceCardProps> = ({
  imagePath,
  imageUrl,
  imageId,
  title,
  rating,
  noOfReviews,
  type,
  bookingLink,
  trip,
  placeId,
  activityId,
  duration,
  validFor,
  category,
  minPrice,
  maxPrice,
  admissionPrice,
  priceCategory,
  priceCurrency,
  place,
  tripUser,
  recommendationId,
  recommendation,
}) => {
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const elementRef = useRef<HTMLDivElement | null>(null);
  const imageRef = useRef<HTMLImageElement | null>(null);
  const [imageSrc, setImageSrc] = useState('');
  const [imageFailedToLoad, setImageFailedToLoad] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [addToDayModal, setAddToDayModal] = useState(false);
  const [moreInfoOpened, setMoreInfoOpened] = useState(false);
  const closeModal = () => {
    setMoreInfoOpened(false);
  };
  const [isEditImages, setIsEditImages] = useState(false);
  const isEditor = window.location.href.includes('editor');

  // const [getImage] = useLazyQuery(FETCH_IMAGE_URL, {
  //   client: unauthorisedClient,
  // });

  // const [getImage] = useLazyQuery(FETCH_IMAGE_BY_ID, {
  //   client: unauthorisedClient,
  // });

  // useEffect(() => {
  //   let isMounted = true;

  //   const fetchImage = async () => {
  //     if (imagePath === null || imagePath === '') {
  //       try {
  //         const response = await getImage({
  //           variables: { image_id: imageId },
  //         });

  //         if (
  //           response.data &&
  //           response.data.fetchFreeImage &&
  //           response.data.fetchFreeImage.imageData
  //         ) {
  //           const base64Data = `data:image/jpeg;base64,${response.data.fetchFreeImage.imageData}`;

  //           if (isMounted) {
  //             setImageSrc(base64Data);
  //             setImageLoaded(true);
  //             // saveImageToSessionStorage(imageUrl, base64Data);
  //           }
  //         } else {
  //           console.error('Error fetching image 3');

  //           return <></>;
  //         }
  //       } catch (err) {
  //         console.error('Error fetching image:', err);
  //         return <></>;
  //       }
  //     } else {
  //       if (!!imagePath) {
  //         setImageLoaded(true);
  //         setImageSrc(imagePath);
  //       }
  //     }
  //   };

  //   fetchImage();

  //   return () => {
  //     isMounted = false;
  //   };

  //   // fixme add path
  // }, [imageUrl, imagePath]);

  const handleCloseModal = () => {
    setIsEditImages(false);
  };

  useEffect(() => {
    // if (imageUrl) {
    //   setImageSrc(imageUrl);
    // } else
    if ((imagePath === null || imagePath === '') && !!imageId) {
      setImageSrc(
        `${process.env.REACT_APP_CONFIG_URL}/getFreeImage/${imageId}`,
      );
    } else {
      if (imagePath !== '' && imagePath !== null && imagePath !== undefined) {
        setImageSrc(imagePath);
      }
    }
  }, [imagePath, imageFailedToLoad, imageId]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    const lastState = { isIntersecting: false };
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Clear any pending timeout
        if (timeout) {
          clearTimeout(timeout);
        }

        // Only update if state actually changed
        if (lastState.isIntersecting !== entry.isIntersecting) {
          timeout = setTimeout(() => {
            setIsVisible(entry.isIntersecting);
            lastState.isIntersecting = entry.isIntersecting;
          }, 150); // Debounce time
        }
      },
      {
        root: null,
        rootMargin: '400px',
        threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
      },
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, []);

  return (
    <Box
      sx={{
        paddingRight: 1,
        display: 'flex',
      }}
    >
      <Box
        onClick={() => {
          if (type === 'place') {
            dispatch(setOpenHoursModal(true));
            dispatch(setPlaceIdForOpenHours(place!.id));
          } else {
            openLinkInBrowserNewTab(
              generateGetYourGuideUrl(
                bookingLink ? bookingLink : 'https://www.getyourguide.com/',
              ),
            );
          }
        }}
        sx={{
          borderRadius: '10px',
          backgroundColor: '#faf5ff',
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'auto',
          position: 'relative',
          flexShrink: 0,
          border: '1px solid #E4DBFF',
          width: 280,
          transition: 'box-shadow 0.3s ease-in-out',
        }}
      >
        {!imageFailedToLoad && imageSrc && (
          <CardMedia
            component="img"
            image={imageSrc}
            // alt={activity.name || ''}
            onLoad={() => {
              setImageLoaded(true);
              setImageFailedToLoad(false);
            }}
            onError={() => {
              setImageFailedToLoad(true);
            }}
            sx={{
              width: '100%',
              height: 150, // Adjusted height for better aspect ratio
              objectFit: 'cover',
              borderTopRightRadius: '10px',
              borderTopLeftRadius: '10px',
            }}
          />
        )}
        {isEditor && !imageSrc && (
          <>
            <Box
              sx={{
                width: '100%',
                height: 150, // Adjusted height for better aspect ratio
                objectFit: 'cover',
                borderTopRightRadius: '10px',
                borderTopLeftRadius: '10px',
                display: 'flex', // Enables flexbox
                alignItems: 'center', // Centers content vertically
                justifyContent: 'center', // Centers content horizontally
                backgroundColor: theme => theme.palette.grey[300],
              }}
            >
              <Button
                variant="text"
                color="secondary"
                onClick={() => {
                  // if (!isModalDisplayed) {
                  //   const scrollPosition = window.scrollY;
                  //   setScrollY(scrollPosition);
                  //   setIsModalDisplayed(!isModalDisplayed);
                  // }
                  setIsEditImages(true);
                }}
              >
                Select Image
              </Button>
            </Box>
          </>
        )}
        {!imageLoaded && !imageFailedToLoad && !!imageId && (
          <Skeleton
            variant="rectangular"
            sx={{
              width: '100%',
              height: 150, // Adjusted height for better aspect ratio
              objectFit: 'cover',
              borderTopRightRadius: '10px',
              borderTopLeftRadius: '10px',
            }}
          />
        )}
        {imageFailedToLoad && !!imageId && (
          <Box
            width="100%"
            sx={theme => ({
              width: '100%',
              height: 150, // Adjusted height for better aspect ratio
              objectFit: 'cover',
              borderTopRightRadius: '10px',
              borderTopLeftRadius: '10px',
              backgroundColor: theme => theme.palette.grey[300],
            })}
            onClick={() => {
              setImageFailedToLoad(false);
            }}
          >
            <Typography
              sx={{
                color: theme => theme.palette.grey[600],
                fontSize: 14,
                fontWeight: 500,
                textAlign: 'center',
                cursor: 'pointer',
              }}
            >
              Tap to reload.
            </Typography>
          </Box>
        )}
        {!!category && <CardLabel text={formatToCamelCase(category)} />}
        {tripUser === 'user' && (
          <DeleteRecommendationButton
            tripId={trip?.id}
            recommendationId={recommendationId}
          />
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            paddingY: 1,
            paddingX: 1,
          }}
        >
          {/* Activity Title */}
          <Typography
            sx={{
              marginBottom: 0.5,
              fontSize: 14,
              fontWeight: 700,
              color: '#001B30',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
            }}
          >
            {title}
          </Typography>

          {/* <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            sx={{
              maxWidth: '100%',
              width: '100%',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: 700,
                color: '#001B30',
                marginBottom: 0.75,
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
              }}
            >
              {title}
            </Typography>

            {tripUser === 'user' && (
              <Box>
                <RecommendationMenu
                  tripId={trip?.id}
                  recommendationId={recommendationId}
                />
              </Box>
            )}
          </Stack> */}

          <Box sx={{ flexGrow: 1 }} />
          <Box pl={0.5}>
            {/* Rating and Reviews */}
            {!!rating && (
              <Stack
                direction="row"
                alignItems="center"
                spacing={0.5}
                sx={{ marginBottom: 0.5 }}
              >
                {/* <Rating
                  name="place-rating"
                  value={rating}
                  precision={0.5}
                  readOnly
                  size="small"
                /> */}
                <StarRateTwoToneIcon
                  sx={theme => ({
                    width: 20,
                    height: 20,
                    marginRight: 1,
                    color: 'rgb(250, 175, 0)',
                    [theme.breakpoints.down('sm')]: {
                      marginRight: 0.5,
                      width: 20,
                      height: 20,
                    },
                  })}
                />
                {!!noOfReviews && (
                  <Typography
                    // variant="span"
                    sx={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: '#6E7191',
                    }}
                  >
                    ~{formatReviewString(noOfReviews)}
                  </Typography>
                )}
              </Stack>
            )}

            <Stack direction="row" alignItems="center" sx={{ mb: 0.5 }}>
              {!!duration && (
                <Stack direction="row" alignItems="center" sx={{ mb: 0.5 }}>
                  <UpdateTwoToneIcon
                    sx={theme => ({
                      width: 20,
                      height: 20,
                      marginRight: 1,
                      color: theme.palette.icon?.main || '#6E7191',
                      [theme.breakpoints.down('sm')]: {
                        marginRight: 0.5,
                        width: 20,
                        height: 20,
                      },
                    })}
                  />
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: 14,
                      color: '#36454F',
                      marginRight: 0.5,
                    }}
                  >
                    Takes:&nbsp;
                    <Typography
                      component="span"
                      sx={{
                        fontWeight: 500,
                        fontSize: 14,
                        color: '#6E7191',
                      }}
                    >
                      {formatSecondsToTimeExtraShort(duration * 60)}
                    </Typography>
                  </Typography>
                </Stack>
              )}

              {!!validFor && !duration && (
                <Stack direction="row" alignItems="center" sx={{ mb: 0.5 }}>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: 14,
                      color: '#36454F',
                      marginRight: 0.5,
                    }}
                  >
                    Valid:&nbsp;
                    <Typography
                      component="span"
                      sx={{
                        fontWeight: 500,
                        fontSize: 14,
                        color: '#6E7191',
                      }}
                    >
                      {formatSecondsToTimeExtraShort(validFor * 60)}
                    </Typography>
                  </Typography>
                </Stack>
              )}

              {type === 'activity' && minPrice && (
                <Stack direction="row" alignItems="center" sx={{ mb: 0.5 }}>
                  <Box
                    sx={{
                      width: 5, // Width of the dot
                      height: 5, // Height of the dot
                      backgroundColor: '#6E7191', // Color of the dot
                      borderRadius: '50%', // Make it circular
                      marginRight: 1, // Horizontal margin for spacing
                      marginLeft: 0.5,
                    }}
                  />
                  <LocalActivityTwoToneIcon
                    sx={theme => ({
                      width: 20,
                      height: 20,
                      marginRight: 1,
                      color: theme.palette.icon?.main || '#6E7191',
                      [theme.breakpoints.down('sm')]: {
                        marginRight: 0.5,
                        width: 20,
                        height: 20,
                      },
                    })}
                  />
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: 14,
                      color: '#36454F',
                      marginRight: 0.5,
                    }}
                  >
                    From:&nbsp;
                    <Typography
                      component="span"
                      sx={{
                        fontWeight: 500,
                        fontSize: 14,
                        color: '#6E7191',
                        marginRight: 0.5,
                      }}
                    >
                      {minPrice}{' '}
                      {priceCurrency && priceCurrency === 'GBP' ? '£' : '£'}
                    </Typography>
                  </Typography>
                </Stack>
              )}

              {type === 'place' &&
                minPrice !== null &&
                minPrice !== undefined && (
                  <PriceInfo
                    minPrice={minPrice}
                    maxPrice={maxPrice}
                    admissionPrice={admissionPrice}
                    priceCategory={priceCategory}
                    priceCurrency={priceCurrency}
                    renderSmall={true}
                  />
                )}
            </Stack>
            {/* Spacer to push buttons to the bottom */}
          </Box>
          {/* Action Buttons */}
          <Stack direction="row" spacing={1} display="flex">
            {type === 'activity' && (
              <>
                <Button
                  variant="contained"
                  onClick={() => {
                    openLinkInBrowserNewTab(
                      generateGetYourGuideUrl(
                        bookingLink
                          ? bookingLink
                          : 'https://www.getyourguide.com/',
                      ),
                    );
                  }}
                  sx={theme => ({
                    // backgroundColor: '#FF5533',
                    borderColor: theme.palette.primary.main,
                    borderRadius: '20px',
                    paddingX: 2,
                    // paddingY: 0.5,
                    textTransform: 'none',
                    fontSize: 14,
                    fontWeight: 600,
                    // '&:hover': {
                    //   backgroundColor: '#e04e2c',
                    // },
                    flexGrow: 1,
                  })}
                  startIcon={<LocalActivityTwoToneIcon />}
                >
                  Book Now
                </Button>
                {tripUser === 'user' ? (
                  <Button
                    variant="outlined"
                    startIcon={<AddCircleTwoToneIcon />}
                    onClick={e => {
                      e.stopPropagation();
                      setAddToDayModal(true);
                    }}
                    sx={theme => ({
                      borderColor: theme.palette.primary.main,
                      color: theme.palette.primary.main,
                      borderRadius: '20px',
                      border: '2px solid',
                      paddingX: 2,
                      textTransform: 'none',
                      fontSize: 14,
                      fontWeight: 600,
                      flexGrow: 1,
                    })}
                  >
                    Add
                  </Button>
                ) : (
                  <Box
                    onClick={e => {
                      e.stopPropagation();
                      // setAddToDayModal(true);
                    }}
                  >
                    <AddToTripButton
                      activityId={activityId ? activityId : undefined}
                      buttonType="outlined"
                      buttonCopy="Save"
                      showDropDownIcon={false}
                      isRenderedOnLockedPublicTrip={false}
                    />
                  </Box>
                )}
              </>
            )}

            {type === 'place' && (
              <>
                {tripUser === 'user' ? (
                  <Button
                    variant="outlined"
                    startIcon={<AddCircleTwoToneIcon />}
                    onClick={e => {
                      e.stopPropagation();
                      setAddToDayModal(true);
                    }}
                    sx={theme => ({
                      // borderColor: '#FF5533',
                      // color: '#FF5533',
                      borderColor: theme.palette.primary.main,
                      color: theme.palette.primary.main,
                      borderRadius: '20px',
                      border: '2px solid',
                      paddingX: 2,
                      // paddingY: 0.5,
                      textTransform: 'none',
                      fontSize: 14,
                      fontWeight: 600,
                      // '&:hover': {
                      //   backgroundColor: 'rgba(255, 85, 51, 0.1)',
                      //   borderColor: '#e04e2c',
                      //   color: '#e04e2c',
                      // },
                      flexGrow: 1,
                      minWidth: 'auto',
                    })}
                  >
                    Add
                  </Button>
                ) : (
                  <Box
                    display="flex"
                    onClick={e => {
                      e.stopPropagation();
                    }}
                    sx={{ flexGrow: 1 }}
                  >
                    <AddToTripButton
                      placeId={placeId ? placeId : undefined}
                      buttonType="primary"
                      buttonCopy="Save"
                      showDropDownIcon={false}
                      isRenderedOnLockedPublicTrip={false}
                    />
                  </Box>
                )}
                <Button
                  startIcon={<InfoTwoToneIcon />}
                  variant="outlined"
                  onClick={e => {
                    e.stopPropagation();
                    gtag('event', 'public-place-more-info-click');
                    setMoreInfoOpened(true);
                  }}
                  sx={theme => ({
                    borderColor: theme.palette.primary.main,
                    color: theme.palette.primary.main,
                    borderRadius: '20px',
                    border: '2px solid',
                    paddingX: 2,
                    // paddingY: 0.5,
                    textTransform: 'none',
                    fontSize: 14,
                    fontWeight: 600,
                    // '&:hover': {
                    //   backgroundColor: 'rgba(255, 85, 51, 0.1)',
                    //   borderColor: '#e04e2c',
                    //   color: '#e04e2c',
                    // },
                    flexGrow: 0,
                    // minWidth: 'auto',
                  })}
                >
                  More info
                </Button>
              </>
            )}
          </Stack>
        </Box>
      </Box>
      {addToDayModal && !!trip && (!!placeId || !!activityId) && (
        <AddPlaceActivityToDayModal
          placeId={placeId ? placeId : undefined}
          activityId={activityId ? activityId : undefined}
          trip={trip}
          onClose={() => {
            setAddToDayModal(false);
          }}
        />
      )}
      {moreInfoOpened && (
        <PlaceOrActivityMoreInfoModal place={place} onClose={closeModal} />
      )}
      {isEditImages && !!trip && (
        <EditRecommendationImagesModal
          recommendation={recommendation}
          tripId={trip.id}
          handleCloseModal={handleCloseModal}
        />
      )}
    </Box>
  );
};

const RecommendationCardForStep: React.FC<RecommendationPlaceCardProps> = ({
  imagePath,
  imageUrl,
  imageId,
  title,
  rating,
  noOfReviews,
  type,
  bookingLink,
  trip,
  placeId,
  activityId,
  duration,
  validFor,
  category,
  minPrice,
  maxPrice,
  admissionPrice,
  priceCategory,
  priceCurrency,
  place,
  step,
  tripUser,
  recommendationId,
}) => {
  // const dispatch = useDispatch();
  // const [isVisible, setIsVisible] = useState(false);
  const [addToDayModal, setAddToDayModal] = useState(false);
  // const elementRef = useRef<HTMLDivElement | null>(null);
  // const imageRef = useRef<HTMLImageElement | null>(null);
  // const [imageSrc, setImageSrc] = useState('');
  // const [imageLoaded, setImageLoaded] = useState(false);
  // const [imageFailedToLoad, setImageFailedToLoad] = useState(false);
  const [moreInfoOpened, setMoreInfoOpened] = useState(false);
  const closeModal = () => {
    setMoreInfoOpened(false);
  };

  // const [getImage] = useLazyQuery(FETCH_IMAGE_URL, {
  //   client: unauthorisedClient,
  // });

  // useEffect(() => {
  //   if (imagePath === null || imagePath === '') {
  //     setImageSrc(
  //       `${process.env.REACT_APP_CONFIG_URL}/getFreeImage/${imageId}`,
  //     );
  //   } else {
  //     if (imagePath !== '' && imagePath !== null && imagePath !== undefined) {
  //       setImageSrc(imagePath);
  //     }
  //   }
  // }, [imagePath, imageFailedToLoad, imageId]);

  // const [getImage] = useLazyQuery(FETCH_IMAGE_BY_ID, {
  //   client: unauthorisedClient,
  // });

  // useEffect(() => {
  //   let isMounted = true;

  //   const fetchImage = async () => {
  //     if (imagePath === null || imagePath === '') {
  //       try {
  //         const response = await getImage({
  //           variables: { image_id: imageId },
  //         });

  //         if (
  //           response.data &&
  //           response.data.fetchFreeImage &&
  //           response.data.fetchFreeImage.imageData
  //         ) {
  //           const base64Data = `data:image/jpeg;base64,${response.data.fetchFreeImage.imageData}`;

  //           if (isMounted) {
  //             setImageSrc(base64Data);
  //             setImageLoaded(true);
  //             // saveImageToSessionStorage(imageUrl, base64Data);
  //           }
  //         } else {
  //           console.error('Error fetching image 4');
  //           return <></>;
  //         }
  //       } catch (err) {
  //         return <></>;
  //       }
  //     } else {
  //       if (!!imagePath) {
  //         setImageLoaded(true);
  //         setImageSrc(imagePath);
  //       }
  //     }
  //   };

  //   fetchImage();

  //   return () => {
  //     isMounted = false;
  //   };

  //   // fixme add path
  // }, [imageUrl, imagePath]);

  // useEffect(() => {
  //   let timeout: NodeJS.Timeout;
  //   const lastState = { isIntersecting: false };
  //   const observer = new IntersectionObserver(
  //     ([entry]) => {
  //       // Clear any pending timeout
  //       if (timeout) {
  //         clearTimeout(timeout);
  //       }

  //       // Only update if state actually changed
  //       if (lastState.isIntersecting !== entry.isIntersecting) {
  //         timeout = setTimeout(() => {
  //           setIsVisible(entry.isIntersecting);
  //           lastState.isIntersecting = entry.isIntersecting;
  //         }, 150); // Debounce time
  //       }
  //     },
  //     {
  //       root: null,
  //       rootMargin: '400px',
  //       threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
  //     },
  //   );

  //   if (elementRef.current) {
  //     observer.observe(elementRef.current);
  //   }

  //   return () => {
  //     if (timeout) {
  //       clearTimeout(timeout);
  //     }
  //     if (elementRef.current) {
  //       observer.unobserve(elementRef.current);
  //     }
  //   };
  // }, []);

  return (
    <Box
      sx={{
        paddingRight: 1,
        display: 'flex',
      }}
    >
      <Box
        onClick={() => {
          if (bookingLink) {
            openLinkInBrowserNewTab(generateGetYourGuideUrl(bookingLink));
          }
        }}
        sx={{
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'auto',
          flexShrink: 0,
          width: 280,
          paddingX: 1,
          paddingY: 1,
          // transition: 'box-shadow 0.3s ease-in-out',
          border: '2px solid #ECE4FF',
          backgroundColor: '#FBF9FF',
          // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          '&:hover': {
            backgroundColor: '#fff',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            sx={{
              maxWidth: '100%',
              width: '100%',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: 700,
                color: '#001B30',
                marginBottom: 0.75,
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
              }}
            >
              {title}
            </Typography>

            {tripUser === 'user' && (
              <Box>
                <RecommendationMenu
                  tripId={trip?.id}
                  recommendationId={recommendationId}
                />
              </Box>
            )}
          </Stack>

          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ paddingLeft: 1 }}>
            {!!rating && (
              <Stack
                direction="row"
                alignItems="center"
                spacing={0.5}
                sx={{ marginBottom: 0.75 }}
              >
                {/* <Rating
                  name="place-rating"
                  value={rating}
                  precision={0.5}
                  readOnly
                  size="small"
                /> */}
                <StarRateTwoToneIcon
                  sx={theme => ({
                    width: 20,
                    height: 20,
                    marginRight: 1,
                    color: 'rgb(250, 175, 0)',
                    [theme.breakpoints.down('sm')]: {
                      marginRight: 0.5,
                      width: 20,
                      height: 20,
                    },
                  })}
                />

                {!!noOfReviews && (
                  // <Typography variant="body2" color="text.secondary">
                  //   (over {formatReviewString(noOfReviews)})
                  // </Typography>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: 14,
                      color: '#36454F',
                      marginRight: 0.5,
                    }}
                  >
                    {rating}&nbsp;
                    <Typography
                      component="span"
                      sx={{
                        fontWeight: 500,
                        fontSize: 14,
                        color: '#6E7191',
                      }}
                    >
                      ~{formatReviewString(noOfReviews)}
                    </Typography>
                  </Typography>
                )}
              </Stack>
            )}

            <Stack
              direction="row"
              alignItems="center"
              sx={theme => ({
                fontWeight: 500,
                fontSize: 14,
                color: '#6E7191',
                marginBottom: 0.75,
              })}
            >
              <TransportStepToPlaceDetails
                currentStep={step}
                endPlace={place}
              />
              {!!category && (
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: 14,
                    color: '#6E7191',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {formatToCamelCase(category)}
                </Typography>
              )}
            </Stack>
            <Stack direction="row" alignItems="center" sx={{ mb: 0.75 }}>
              {!!duration && (
                <Stack direction="row" alignItems="center">
                  <UpdateTwoToneIcon
                    sx={theme => ({
                      width: 20,
                      height: 20,
                      marginRight: 1,
                      color: theme.palette.icon?.main || '#6E7191',
                      [theme.breakpoints.down('sm')]: {
                        marginRight: 0.5,
                        width: 20,
                        height: 20,
                      },
                    })}
                  />
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: 14,
                      color: '#36454F',
                      marginRight: 0.5,
                    }}
                  >
                    Takes:&nbsp;
                    <Typography
                      component="span"
                      sx={{
                        fontWeight: 500,
                        fontSize: 14,
                        color: '#6E7191',
                      }}
                    >
                      {formatSecondsToTimeExtraShort(duration * 60)}
                    </Typography>
                  </Typography>
                </Stack>
              )}

              {!!validFor && !duration && (
                <Stack direction="row" alignItems="center" sx={{ mb: 0.5 }}>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: 14,
                      color: '#36454F',
                      marginRight: 0.5,
                    }}
                  >
                    Valid:&nbsp;
                    <Typography
                      component="span"
                      sx={{
                        fontWeight: 500,
                        fontSize: 14,
                        color: '#6E7191',
                      }}
                    >
                      {formatSecondsToTimeExtraShort(validFor * 60)}
                    </Typography>
                  </Typography>
                </Stack>
              )}

              {type === 'activity' && minPrice && (
                <Stack direction="row" alignItems="center">
                  <Box
                    sx={{
                      width: 5, // Width of the dot
                      height: 5, // Height of the dot
                      backgroundColor: '#6E7191', // Color of the dot
                      borderRadius: '50%', // Make it circular
                      marginRight: 1, // Horizontal margin for spacing
                      marginLeft: 0.5,
                    }}
                  />
                  <LocalActivityTwoToneIcon
                    sx={theme => ({
                      width: 20,
                      height: 20,
                      marginRight: 1,
                      color: theme.palette.icon?.main || '#6E7191',
                      [theme.breakpoints.down('sm')]: {
                        marginRight: 0.5,
                        width: 20,
                        height: 20,
                      },
                    })}
                  />
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: 14,
                      color: '#36454F',
                      marginRight: 0.5,
                    }}
                  >
                    From:&nbsp;
                    <Typography
                      component="span"
                      sx={{
                        fontWeight: 500,
                        fontSize: 14,
                        color: '#6E7191',
                        marginRight: 0.5,
                      }}
                    >
                      {minPrice}{' '}
                      {priceCurrency && priceCurrency === 'GBP' ? '£' : '£'}
                    </Typography>
                  </Typography>
                </Stack>
              )}

              {type === 'place' &&
                minPrice !== null &&
                minPrice !== undefined && (
                  <PriceInfo
                    minPrice={minPrice}
                    maxPrice={maxPrice}
                    admissionPrice={admissionPrice}
                    priceCategory={priceCategory}
                    priceCurrency={priceCurrency}
                    renderSmall={true}
                  />
                )}
            </Stack>
          </Box>

          <Stack direction="row" spacing={1}>
            {type === 'activity' && (
              <>
                <Button
                  variant="contained"
                  onClick={() => {
                    openLinkInBrowserNewTab(
                      generateGetYourGuideUrl(
                        bookingLink
                          ? bookingLink
                          : 'https://www.getyourguide.com/',
                      ),
                    );
                  }}
                  sx={{
                    borderRadius: '20px',
                    paddingX: 2,
                    // paddingY: 0.5,
                    textTransform: 'none',
                    fontSize: 12,
                    fontWeight: 700,
                    flexGrow: 1,
                  }}
                  startIcon={<LocalActivityTwoToneIcon />}
                >
                  Book Now
                </Button>
                {tripUser === 'user' ? (
                  <Button
                    variant="outlined"
                    onClick={e => {
                      e.stopPropagation();
                      setAddToDayModal(true);
                    }}
                    sx={{
                      borderRadius: '20px',
                      border: '2px solid',
                      paddingX: 2,
                      // paddingY: 0.5,
                      textTransform: 'none',
                      fontSize: 12,
                      fontWeight: 700,
                      flexGrow: 1,
                    }}
                  >
                    Add
                  </Button>
                ) : (
                  <>
                    <Box
                      onClick={e => {
                        e.stopPropagation();
                        // setAddToDayModal(true);
                      }}
                    >
                      <AddToTripButton
                        placeId={placeId ? placeId : undefined}
                        buttonType="smallOutlined"
                        buttonCopy="Save"
                        showDropDownIcon={false}
                        isRenderedOnLockedPublicTrip={false}
                      />
                    </Box>
                  </>
                )}
              </>
            )}
          </Stack>
          <Stack direction="row" spacing={1}>
            {type === 'place' && (
              <>
                <Button
                  variant="text"
                  onClick={e => {
                    e.stopPropagation();
                    const { takeMeThereUrl, openGoogleMapsUrl } =
                      getPlaceMapUrls(place!);

                    openLinkInBrowserNewTab(takeMeThereUrl!);
                    gtag('event', 'public-link-nearby-navigate-click');
                  }}
                  sx={{
                    borderRadius: '20px',
                    paddingX: 1,
                    paddingY: 0,
                    textTransform: 'none',
                    fontSize: 12,
                    fontWeight: 700,
                  }}
                >
                  Directions
                </Button>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ borderColor: '#E4DBFF' }} // Customize color
                />
                {tripUser === 'user' ? (
                  <Button
                    variant="text"
                    onClick={e => {
                      e.stopPropagation();
                      setAddToDayModal(true);
                    }}
                    sx={{
                      borderRadius: '20px',
                      paddingX: 1,
                      paddingY: 0,
                      textTransform: 'none',
                      fontSize: 12,
                      fontWeight: 700,
                    }}
                  >
                    Add
                  </Button>
                ) : (
                  <Box
                    onClick={e => {
                      e.stopPropagation();
                      // setAddToDayModal(true);
                    }}
                  >
                    <AddToTripButton
                      placeId={placeId ? placeId : undefined}
                      buttonType="smallLink"
                      buttonCopy="Save"
                      showDropDownIcon={false}
                      isRenderedOnLockedPublicTrip={false}
                    />
                  </Box>
                )}
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ borderColor: '#E4DBFF' }} // Customize color
                />
                <Button
                  variant="text"
                  onClick={() => {
                    gtag('event', 'public-place-more-info-click');
                    setMoreInfoOpened(true);
                  }}
                  sx={{
                    borderRadius: '20px',
                    paddingX: 1,
                    paddingY: 0,
                    textTransform: 'none',
                    fontSize: 12,
                    fontWeight: 700,
                    flexGrow: 1,
                  }}
                >
                  More info
                </Button>
              </>
            )}
          </Stack>
          {type === 'place' && <Space size="xs" />}
        </Box>
      </Box>
      {addToDayModal && !!trip && (!!placeId || !!activityId) && (
        <AddPlaceActivityToDayModal
          placeId={placeId ? placeId : undefined}
          activityId={activityId ? activityId : undefined}
          trip={trip}
          onClose={() => {
            setAddToDayModal(false);
          }}
        />
      )}
      {moreInfoOpened && (
        <PlaceOrActivityMoreInfoModal place={place} onClose={closeModal} />
      )}{' '}
    </Box>
  );
};
