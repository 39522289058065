import DescriptionTwoToneIcon from '@mui/icons-material/DescriptionTwoTone';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import MapsHomeWorkTwoToneIcon from '@mui/icons-material/MapsHomeWorkTwoTone';
import TodayTwoToneIcon from '@mui/icons-material/TodayTwoTone';
import {
  Box,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';

import { Body1, TitleH3 } from '../../../theme-components/Typography';
import { VerticalFlex, HorizontalFlex } from '../../helpers/flex';
import { RenderHtmlFromResponseNoMargin } from '../../RenderHtmlFromResponse';
import { PublicPlaceCard } from '../../trip-details/PublicPlaceCard';

import { DragabbleDaySteps } from './DraggableDayStep';
import {
  Clone,
  ContainedHandle,
  Container,
  ContainerDay,
  Handle,
  Item,
} from './Styles';
import { PublicPlaceCardNew } from '../../trip-details/place-details/PublicPlaceCardNew';

export function DraggableItineraryDays(props: {
  existingTripDays;
  tripType: string;
}) {
  const [collapsedSteps, setCollapsedSteps] = useState(false);

  return (
    <>
      <Box
        sx={theme => ({
          position: 'fixed', // Sticks to a fixed position
          bottom: '16px', // Distance from the bottom of the screen
          left: '75%', // Center horizontally relative to the viewport
          transform: 'translateX(-50%)', // Offset to truly center the element
          borderRadius: '20px', // Make the element circular
          backgroundColor: theme.palette.grey[900], // MUI primary color (or use any custom color)
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          paddingRight: 3,
          paddingLeft: 1,
          paddingY: 1,
          zIndex: 1000,
        })}
      >
        <FormGroup>
          <FormControlLabel
            onClick={() => {
              gtag('event', 'collapse-details');
              setCollapsedSteps(!collapsedSteps);
            }}
            control={
              <Switch
                size="small"
                checked={collapsedSteps}
                onChange={() => setCollapsedSteps(!collapsedSteps)}
                sx={{
                  '& .MuiSwitch-switchBase': {
                    color: 'white', // Handle color when unchecked
                  },
                  '& .MuiSwitch-switchBase.Mui-checked': {
                    color: 'primary.main', // Handle color when checked
                  },
                  '& .MuiSwitch-track': {
                    backgroundColor: 'white', // Track color when unchecked
                  },
                  '& .MuiSwitch-track.Mui-checked': {
                    backgroundColor: 'primary.main', // Track color when checked
                  },
                }}
              />
            }
            labelPlacement="start"
            label={
              <Typography
                onClick={() => {
                  gtag('event', 'collapse-details');
                  setCollapsedSteps(!collapsedSteps);
                }}
                sx={{
                  fontWeight: 700,
                  color: 'white',
                  whiteSpace: 'nowrap',
                  marginRight: 1,
                }}
              >
                {/* {collapsedSteps ? 'Show details' : 'Hide details'} */}
                Hide details
              </Typography>
            }
          />
        </FormGroup>
      </Box>
      <Droppable
        droppableId="existingDays"
        type="itineraryDay"
        isDropDisabled={true}
      >
        {(provided, snapshot) => (
          <>
            <ContainerDay
              ref={provided.innerRef}
              $isDraggingOver={snapshot.isDraggingOver}
            >
              {props.existingTripDays.map((day, index) => (
                <Box key={index}>
                  <Draggable
                    key={day.id.replace('_temp', '')}
                    draggableId={day.id.replace('_temp', '')}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <>
                        <Item
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          $isDragging={snapshot.isDragging}
                          style={provided.draggableProps.style}
                        >
                          <Stack width="100%" direction="column">
                            <Stack
                              width="100%"
                              direction="row"
                              alignItems="flex-start"
                            >
                              <ContainedHandle {...provided.dragHandleProps}>
                                <DragIndicatorIcon
                                  sx={theme => ({
                                    marginTop: 1.5,
                                    width: 25,
                                    height: 25,
                                  })}
                                />
                              </ContainedHandle>
                              <Stack
                                width="100%"
                                direction="column"
                                sx={{
                                  position: 'relative',
                                  overflow: 'hidden',
                                  borderRadius: '20px',
                                  border: '2px solid rgba(211, 211, 211, 0.4)', // Light gray with 50% opacity
                                  padding: 1,
                                  paddingLeft: 1.5,
                                  marginBottom: 1.5,
                                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.07)',
                                }}
                              >
                                <Stack
                                  direction="row"
                                  justifyContent="flex-start"
                                  alignItems="center" // Optional: Aligns items vertically at the center
                                  sx={{
                                    width: '100%', // Optional: Ensures the Stack takes up the full width
                                  }}
                                >
                                  <TodayTwoToneIcon
                                    sx={theme => ({
                                      marginLeft: 0,
                                      marginRight: 1,
                                      width: 25,
                                      height: 25,
                                      alignSelf: 'center',
                                      color: '#F39C6A',
                                      [theme.breakpoints.down('sm')]: {
                                        marginLeft: 0,
                                      },
                                    })}
                                  />
                                  <TitleH3 color="#001B30">
                                    {'Day ' + day.dayNumber}
                                  </TitleH3>
                                </Stack>
                                {day.description &&
                                day.description !== '' &&
                                day.description !== '<p></p>' ? (
                                  <Stack
                                    direction="row"
                                    width="100%"
                                    sx={theme => ({
                                      [theme.breakpoints.down('sm')]: {
                                        // paddingLeft: '40px',
                                      },
                                    })}
                                  >
                                    <DescriptionTwoToneIcon
                                      sx={theme => ({
                                        marginRight: 1,
                                        width: 25,
                                        height: 25,
                                        color: '#F39C6A',
                                      })}
                                    />
                                    <Box
                                      sx={{
                                        marginTop: '-0.5em',
                                        marginBottom: '-0.5em',
                                      }}
                                    >
                                      <Body1>
                                        <RenderHtmlFromResponseNoMargin
                                          content={day.description}
                                          limitToLines={3}
                                        />
                                      </Body1>
                                    </Box>
                                  </Stack>
                                ) : (
                                  <Stack direction="row" sx={theme => ({})}>
                                    {day &&
                                      day.cities &&
                                      day.cities.length > 0 && (
                                        <MapsHomeWorkTwoToneIcon
                                          sx={theme => ({
                                            marginRight: 1,
                                            width: 25,
                                            height: 25,
                                            alignSelf: 'center',
                                            color: '#F39C6A',
                                          })}
                                        />
                                      )}
                                    <Typography
                                      sx={theme => ({
                                        fontWeight: 500,
                                        fontSize: 18,
                                        color: '#003366',
                                        [theme.breakpoints.down('sm')]: {
                                          fontSize: 16,
                                        },
                                      })}
                                    >
                                      {day &&
                                        day.cities &&
                                        day.cities.length > 0 &&
                                        day.cities.map((city, i) => (
                                          <React.Fragment key={i}>
                                            {city && (
                                              <>
                                                {city.cityName}
                                                {i !==
                                                  day.cities!.length - 1 && (
                                                  <>{', '}</>
                                                )}
                                              </>
                                            )}
                                          </React.Fragment>
                                        ))}
                                    </Typography>
                                  </Stack>
                                )}
                              </Stack>
                            </Stack>
                            <DragabbleDaySteps
                              day={day}
                              collapsedSteps={collapsedSteps}
                              tripType={props.tripType}
                            />
                          </Stack>
                        </Item>
                        {snapshot.isDragging && (
                          <DayClone
                            day={day}
                            collapsedSteps={collapsedSteps}
                            tripType={props.tripType}
                          />
                        )}
                      </>
                    )}
                  </Draggable>
                </Box>
              ))}
            </ContainerDay>
          </>
        )}
      </Droppable>
    </>
  );
}

function DayClone(props: { day; collapsedSteps: boolean; tripType: string }) {
  return (
    <Clone>
      <Item>
        <VerticalFlex>
          <HorizontalFlex>
            <Handle>
              <DragIndicatorIcon />
            </Handle>
            <TitleH3 color="#001B30">{'Day ' + props.day.dayNumber}</TitleH3>
          </HorizontalFlex>
          {props.day.steps &&
            props.day.steps.map((step, index) => (
              <Box key={index}>
                {step && (
                  <Item key={index}>
                    <Container>
                      <VerticalFlex>
                        <HorizontalFlex>
                          <Handle>
                            <DragIndicatorIcon
                              sx={theme => ({
                                width: 25,
                                height: 25,
                              })}
                            />
                            {/* <DragIndicatorIcon /> */}

                            {/* <CreateTripStepTitle step={step} showEdit={false} /> */}
                          </Handle>
                        </HorizontalFlex>
                        <Box
                          sx={{
                            flexGrow: 1, // Allows the PublicPlaceCard to take up the remaining space
                            minWidth: 0, // Ensures the content can shrink and not overflow
                          }}
                        >
                          <PublicPlaceCardNew
                            step={step}
                            // tripId={props.tripId}
                            showEdit={false}
                            collapsedDetails={props.collapsedSteps}
                            tripType={props.tripType}
                            tripUser="public"
                          />
                        </Box>
                      </VerticalFlex>
                    </Container>
                  </Item>
                )}
              </Box>
            ))}
        </VerticalFlex>
      </Item>
    </Clone>
  );
}
