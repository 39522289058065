import { useLazyQuery, useMutation } from '@apollo/client';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import { Divider, Typography } from '@mui/material';
import { Box, fontWeight, Stack } from '@mui/system';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Day, Maybe, Step } from '../../generated/user_graphql';
import { publicTripsSelector } from '../../store/PublicTripSlice';
import { addTransport } from '../../store/TransportSlice';
import { tripsSelector } from '../../store/TripSlice';
import { LoadingDots } from '../../theme-components/Loader';
import { QUERY_TRANSPORT_STEP_DETAILS } from '../gql-public/getTransportStepDetailsMutation';
import {
  formatSecondsToTime,
  formatSecondsToTimeShort,
  openLinkInBrowserNewTab,
} from '../../utils/helpers';
import UnauthorizedApolloProvider from '../../UnAuthorizedApolloProvider';
import HotelTwoToneIcon from '@mui/icons-material/HotelTwoTone';
import { LinkButton } from '../../theme-components/Buttons';
import { unauthorisedClient } from '../../utils/auth';
import { daysSelector } from '../../store/DaySlice';
import {
  setOpenHoursModal,
  setPlaceIdForOpenHours,
} from '../../store/StepSlice';
import { PlaceOrActivityMoreInfoModal } from './PlaceMoreInfoModal';

interface TripDayProps {
  dayId: string;
  isFirstDirection: boolean;
  isLastDirection: boolean;
  tripUser: string;
  tripType: string;
}

export const AddAccomodationDirections: React.FC<TripDayProps> = ({
  dayId,
  isFirstDirection,
  isLastDirection,
  tripUser,
  tripType,
}) => {
  const { publicTrip } = useSelector(publicTripsSelector);
  const { trip: userTrip } = useSelector(tripsSelector);

  const trip = tripUser === 'user' ? userTrip : publicTrip;

  // Do not use on guides
  if (tripType === 'guide') {
    return <ReturnEmpty copy="DO NOTHING not showing on guides" />;
  }
  // Ensure trip, itinerary, and days exist before trying to access them
  if (!trip || !trip.itinerary || !trip.itinerary.days)
    return <ReturnEmpty copy="DO NOTHING no trip details" />;

  // Find the current day by ID
  const currentDay = trip.itinerary.days.find(day => day?.id === dayId) ?? null;
  if (!currentDay) return <ReturnEmpty copy="DO NOTHING no current day" />; // If no current day, return nothing

  // Check if any step in the current day is accommodation
  const currentDayHasAccommodation = currentDay.steps?.some(
    step => step?.type === 'CATEGORY_ACCOMMODATION',
  );

  const lastAccommodationOnCurrentDay = currentDay.steps
    ?.slice() // Create a shallow copy so the original array is not modified
    .reverse() // Reverse the array to start from the last element
    .find(step => step?.type === 'CATEGORY_ACCOMMODATION');

  // Find the closest previous day with accommodation, if any
  const accomodationOnOneOfThePreviousDays = trip.itinerary.days
    .slice(0, currentDay.dayNumber - 1) // Get days before current
    .reverse() // Reverse to start checking from the closest previous day
    .find(day =>
      day?.steps?.some(step => step?.type === 'CATEGORY_ACCOMMODATION'),
    );

  // Retrieve the accommodation step from the previous day, if it exists
  const previousAccommodationStep = accomodationOnOneOfThePreviousDays?.steps
    ?.slice() // Create a shallow copy so the original array is not modified
    .reverse() // Reverse the array to start from the last element
    .find(step => step?.type === 'CATEGORY_ACCOMMODATION');

  // Get first and last steps, ensuring they exist
  const firstStep = currentDay.steps ? currentDay.steps[0] : null;
  const lastStep = currentDay.steps
    ? currentDay.steps[currentDay.steps.length - 1]
    : null;

  // Handle first day logic fisrt step
  if (
    currentDay.dayNumber === 1 &&
    !currentDayHasAccommodation &&
    isFirstDirection &&
    currentDay.steps &&
    currentDay.steps.length > 0
  ) {
    return (
      <ShowAddHotel
        copy="SHOW TO ADD HOTEL - First day without accomodation"
        closestStep={0}
        isFirstDirection={true}
        isLastDirection={false}
      />
    );
  } else if (
    currentDay.dayNumber === 1 &&
    !currentDayHasAccommodation &&
    isFirstDirection &&
    currentDay.steps &&
    currentDay.steps.length <= 0
  ) {
    return (
      <ReturnEmpty copy="DO NOTHING user did not even add enough steps yet" />
    );
  }

  if (
    currentDay.dayNumber === 1 &&
    currentDayHasAccommodation &&
    isFirstDirection
  ) {
    return <ReturnEmpty copy="DO NOTHING First step got accomodation" />;
  }

  // Handle first day logic last step
  if (
    currentDay.dayNumber === 1 &&
    !currentDayHasAccommodation &&
    isLastDirection &&
    currentDay.steps &&
    currentDay.steps.length > 0
  ) {
    return (
      <ShowAddHotel
        copy="SHOW TO ADD HOTEL - First day without accomodation"
        closestStep={currentDay.steps[currentDay.steps.length - 1]}
        isFirstDirection={false}
        isLastDirection={true}
      />
    );
  } else if (
    currentDay.dayNumber === 1 &&
    !currentDayHasAccommodation &&
    isLastDirection &&
    currentDay.steps &&
    currentDay.steps.length <= 0
  ) {
    return (
      <ReturnEmpty copy="DO NOTHING Not enough steps, this will ba handeled by first step" />
    );
  }

  if (
    currentDay.dayNumber === 1 &&
    currentDayHasAccommodation &&
    isLastDirection &&
    currentDay.steps &&
    currentDay.steps.length > 0 &&
    currentDay.steps[currentDay.steps.length - 1]?.type ===
      'CATEGORY_ACCOMMODATION'
  ) {
    return <ReturnEmpty copy="DO NOTHING Last step accomodation" />;
  }

  if (
    currentDay.dayNumber === 1 &&
    currentDayHasAccommodation &&
    isLastDirection &&
    currentDay.steps &&
    currentDay.steps.length > 0 &&
    currentDay.steps[currentDay.steps.length - 1]?.type !==
      'CATEGORY_ACCOMMODATION'
  ) {
    return (
      <AccomodationTransportStepDetails
        copy="CALCULATE last"
        firstStep={currentDay.steps[currentDay.steps.length - 1]}
        nextStep={lastAccommodationOnCurrentDay}
        dayId={currentDay.id}
        isFirstDirection={isFirstDirection}
        isLastDirection={isLastDirection}
      />
    );
  }

  // Handle last day logic
  if (
    currentDay.dayNumber === trip.itinerary.days.length &&
    !currentDayHasAccommodation &&
    isFirstDirection
  ) {
    if (previousAccommodationStep) {
      if (firstStep) {
        return (
          <AccomodationTransportStepDetails
            copy="CALCULATE first"
            firstStep={previousAccommodationStep}
            nextStep={firstStep}
            dayId={currentDay.id}
            isFirstDirection={isFirstDirection}
            isLastDirection={isLastDirection}
          />
        );
      } else {
        return (
          <ReturnEmpty copy="DO NOTHING Last day first step no steps do not show anything" />
        );
      }
    } else {
      return (
        <ReturnEmpty
          copy="DO NOTHING Last day first step do not show anything as no previous
          accomodation"
        />
      );
    }
  }

  if (currentDay.dayNumber === trip.itinerary.days.length && isLastDirection) {
    return (
      <ReturnEmpty copy="DO NOTHING Last day last step do not show anything" />
    );
  }

  // Handle any days in between
  if (currentDayHasAccommodation) {
    if (isFirstDirection) {
      // TODO logic to check how long to get to this step from previous accomodation
      if (
        currentDay.steps &&
        currentDay.steps.length > 0 &&
        currentDay.steps[0]?.type === 'CATEGORY_ACCOMMODATION'
      ) {
        return (
          <ReturnEmpty copy="DO NOTHING Middle step where accomodation is FIRST step do nothing" />
        );
      }

      if (previousAccommodationStep) {
        return (
          <AccomodationTransportStepDetails
            copy="CALCULATE first middle "
            firstStep={previousAccommodationStep}
            nextStep={firstStep}
            dayId={currentDay.id}
            isFirstDirection={isFirstDirection}
            isLastDirection={isLastDirection}
          />
        );
      } else {
        // TODO check if there are any steps and add accomodation
        <ReturnEmpty copy="DO NOTHING Middle day with accomodation, do not show anything" />;
      }
    }

    if (isLastDirection) {
      if (
        currentDay.steps &&
        currentDay.steps.length > 0 &&
        currentDay.steps[currentDay.steps.length - 1]?.type ===
          'CATEGORY_ACCOMMODATION'
      ) {
        return (
          <ReturnEmpty copy="DO NOTHING Middle step where accomodation is last step do nothing" />
        );
      } else if (lastStep) {
        return (
          <AccomodationTransportStepDetails
            copy="CALCULATE last"
            firstStep={lastStep}
            nextStep={lastAccommodationOnCurrentDay}
            dayId={currentDay.id}
            isFirstDirection={isFirstDirection}
            isLastDirection={isLastDirection}
          />
        );
      } else {
        return <ReturnEmpty copy="SHOULD NOT HAPPEN" />;
      }
    }
  }

  if (!currentDayHasAccommodation) {
    if (previousAccommodationStep) {
      if (isFirstDirection && !firstStep) {
        return <ReturnEmpty copy="DO NOTHING EMPTY DAY first step" />;
      }
      if (isFirstDirection && firstStep) {
        return (
          <AccomodationTransportStepDetails
            copy="CALCULATE first"
            firstStep={previousAccommodationStep}
            nextStep={firstStep}
            dayId={currentDay.id}
            isFirstDirection={isFirstDirection}
            isLastDirection={isLastDirection}
          />
        );
      }
      if (isLastDirection && lastStep) {
        return (
          <AccomodationTransportStepDetails
            copy="CALCULATE last"
            firstStep={lastStep}
            nextStep={previousAccommodationStep}
            dayId={currentDay.id}
            isFirstDirection={isFirstDirection}
            isLastDirection={isLastDirection}
          />
        );
      }
      if (isLastDirection && !lastStep) {
        return <ReturnEmpty copy="DO NOTHING EMPTY DAY last step" />;
      }
    } else {
      if (
        isFirstDirection &&
        currentDay.steps &&
        currentDay.steps.length >= 0
      ) {
        return (
          <ShowAddHotel
            copy="SHOW TO ADD HOTEL - middle day first step"
            closestStep={currentDay.steps[0]}
            isFirstDirection={true}
            isLastDirection={false}
          />
        );
      } else if (
        isFirstDirection &&
        currentDay.steps &&
        currentDay.steps.length < 0
      ) {
        return (
          <ReturnEmpty copy="DO NOTHING not enough steps to show add accomodation as first step" />
        );
      }
      if (isLastDirection && currentDay.steps && currentDay.steps.length > 0) {
        return (
          <ShowAddHotel
            copy="SHOW TO ADD HOTEL - middle step last step"
            closestStep={currentDay.steps[currentDay.steps.length - 1]}
            isFirstDirection={false}
            isLastDirection={true}
          />
        );
      } else if (
        isLastDirection &&
        currentDay.steps &&
        currentDay.steps.length <= 0
      ) {
        return (
          <ReturnEmpty copy="DO NOTHING not enough steps to show add accomodation as last step" />
        );
      }
    }
  }

  return <ReturnEmpty copy="SHOULD NOT HAPPEN -> END of function" />;
};

interface EmptyProps {
  copy: string;
}

const ReturnEmpty: React.FC<EmptyProps> = ({ copy }) => {
  // return <>{copy}</>;
  return null;
};

// For debugging
// interface AddHotelProps {
//   copy: string;
// }

// const ShowAddHotel: React.FC<AddHotelProps> = ({ copy }) => {
//   // return <>{copy}</>;
//   return null;
// };

// those 2 are for debugging
// interface CalculateProps {
//   copy: string;
//   firstStep: Maybe<Step> | undefined;
//   nextStep: Maybe<Step> | undefined;
//   dayId: string;
// }
// const AccomodationTransportStepDetails: React.FC<CalculateProps> = ({
//   copy,
//   firstStep,
//   nextStep,
//   dayId,
// }) => {
//   return <>{copy}</>;
// };

const AccomodationTransportStepDetails = ({
  copy,
  firstStep,
  nextStep,
  dayId,
  isFirstDirection,
  isLastDirection,
}) => {
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const [firstStepStayClick, setFirstStepStayClick] = useState(false);
  const componentRef = useRef<HTMLDivElement | null>(null); // Make sure ref is typed

  const [moreInfoOpened, setMoreInfoOpened] = useState(false);
  const closeModal = () => {
    setMoreInfoOpened(false);
  };

  const { days } = useSelector(daysSelector);
  const currentDay = days.find(day => day?.id === dayId);

  const [getTransportStep, { data, loading: loadingTransport, error }] =
    useLazyQuery(QUERY_TRANSPORT_STEP_DETAILS, {
      client: unauthorisedClient,
    });

  // Intersection Observer Effect
  useEffect(() => {
    if (firstStep && nextStep) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            setIsVisible(true); // Set visibility state to true
            observer.unobserve(entry.target); // Stop observing once visible
          }
        },
        {
          root: null, // Viewport is root
          rootMargin: '1000px', // Trigger when 300px below the viewport
          threshold: 0.1, // Trigger when 10% of the element is visible
        },
      );

      if (componentRef.current) {
        observer.observe(componentRef.current);
      } else {
      }

      return () => {
        if (componentRef.current) {
          observer.disconnect();
        }
      };
    }
  }, [firstStep, nextStep, currentDay?.steps, days]); // Dependencies include firstStep and nextStep

  // API call when component becomes visible
  useEffect(() => {
    if (isVisible && firstStep && nextStep) {
      if (
        firstStep.latitude &&
        firstStep.latitude !== 0 &&
        firstStep.longitude &&
        firstStep.longitude !== 0 &&
        nextStep.latitude &&
        nextStep.latitude !== 0 &&
        nextStep.longitude &&
        nextStep.longitude !== 0
      ) {
        getTransportStep({
          variables: {
            start_step_id: firstStep.id,
            end_step_id: nextStep.id,
          },
        });
      }
    }
  }, [
    isVisible,
    getTransportStep,
    firstStep,
    nextStep,
    currentDay?.steps,
    days,
  ]); // Dependencies include isVisible

  if (loadingTransport) {
    return (
      <>
        <Box mt={0} ref={componentRef} sx={{ paddingY: 1 }}>
          <LoadingDots />
        </Box>
      </>
    );
  }

  if (error || (data && data.getDirections === null) || !data) {
    return (
      <>
        <Box mt={0} ref={componentRef} />
      </>
    );
  }

  // Early returns for loading, error, or invalid data
  if (!error && (!firstStep || !nextStep)) {
    return (
      <>
        {/* {copy} ----- no first or last step */}
        <Box mt={0} ref={componentRef} />
      </>
    );
  }

  let drivingTime = 0;
  let walkingTime = 0;

  if (data?.getDirections?.[0]?.durationMinutes) {
    if (data.getDirections[0].transportType === 'driving') {
      drivingTime = data.getDirections[0].durationMinutes;
    }
    if (data.getDirections[0].transportType === 'walking') {
      walkingTime = data.getDirections[0].durationMinutes;
    }
  }

  if (data?.getDirections?.[1]?.durationMinutes) {
    if (data.getDirections[1].transportType === 'driving') {
      drivingTime = data.getDirections[1].durationMinutes;
    }
    if (data.getDirections[1].transportType === 'walking') {
      walkingTime = data.getDirections[1].durationMinutes;
    }
  }

  // Handle case where both driving and walking times are 0
  if (drivingTime === 0 && walkingTime === 0) {
    return (
      <>
        {/* {copy} ----- driving and valking both 0 */}
        <Box mt={0} ref={componentRef} />
      </>
    );
  } else {
    const stepId = firstStep.id;
    if (walkingTime > 0 && walkingTime < 1800) {
      const transportTime = walkingTime;
      dispatch(addTransport({ dayId, stepId, transportTime }));
    }
    if (drivingTime > 0 && (walkingTime > 1800 || walkingTime === 0)) {
      const transportTime = drivingTime;
      dispatch(addTransport({ dayId, stepId, transportTime }));
    }
  }

  return (
    <Box
      alignItems="center"
      ref={componentRef}
      sx={theme => ({
        width: '100%',
      })}
    >
      <Box>
        {isLastDirection && (
          <Divider
            orientation="vertical"
            sx={theme => ({
              padding: 0,
              width: '2px', // Set the thickness of the vertical divider
              backgroundColor: '#f1eaff',
              color: '#f1eaff', // Set the color of the divider
              margin: '0 auto', // Center the divider itself
              position: 'relative', // Ensure the children and divider are properly aligned
              display: 'flex',
              justifyContent: 'center', // Center the content vertically with the divider
              alignItems: 'center',
              height: '15px',
              border: 'none',
              '& .MuiDivider-wrapper': {
                padding: 0, // Remove padding from the wrapper
                margin: 0, // Adjust margin as needed
              },
            })}
            textAlign="center"
          />
        )}
        <Box
          sx={theme => ({
            padding: 0,
            position: 'relative', // Ensure the children and divider are properly aligned
            display: 'flex',
            justifyContent: 'center', // Center the content vertically with the divider
            alignItems: 'center',
          })}
        >
          <Box
            sx={theme => ({
              fontWeight: 500,
              fontSize: 14,
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
              color: '#6E7191',
              padding: theme.spacing(0.75, 2),
              backgroundColor: '#faf5ff',
              borderRadius: theme.shape.borderRadius,
              border: `2px solid #f1eaff`,
              display: 'inline-block',
            })}
            onClick={e => {
              e.stopPropagation();
              gtag('event', 'open-hotel-modal-from-transport-start-step');

              if (isFirstDirection) {
                setMoreInfoOpened(true);
                // dispatch(setPlaceIdForOpenHours(firstStep.place.id));
                setFirstStepStayClick(true);
                // dispatch(setOpenHoursModal(true));
              }
              if (isLastDirection) {
                setMoreInfoOpened(true);
                // dispatch(setPlaceIdForOpenHours(nextStep.place.id));
                setFirstStepStayClick(false);
                // dispatch(setOpenHoursModal(true));
              }
            }}
          >
            {walkingTime > 0 && walkingTime < 1800 && (
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <DirectionsWalkIcon sx={{ width: 22, height: 22 }} />
                <Typography sx={{ fontWeight: 500 }}>
                  {formatSecondsToTimeShort(walkingTime)}
                </Typography>
                {isFirstDirection && (
                  <Typography
                    sx={theme => ({
                      fontWeight: 600,
                      color: theme.palette.primary.main,
                      textDecoration: 'underline',
                      textDecorationColor: theme.palette.primary.light,
                    })}
                  >
                    {' '}
                    from your
                  </Typography>
                )}
                {isLastDirection && (
                  <Typography
                    sx={theme => ({
                      fontWeight: 600,
                      color: theme.palette.primary.main,
                      textDecoration: 'underline',
                      textDecorationColor: theme.palette.primary.light,
                    })}
                  >
                    {' '}
                    to your
                  </Typography>
                )}
                <HotelTwoToneIcon
                  sx={theme => ({
                    width: 22,
                    height: 22,
                    color: theme.palette.primary.main,
                  })}
                />
              </Stack>
            )}
            {drivingTime > 0 && (walkingTime > 1800 || walkingTime === 0) && (
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <DirectionsCarIcon sx={{ width: 22, height: 22 }} />
                <Typography sx={{ fontWeight: 500 }}>
                  {formatSecondsToTimeShort(drivingTime)}
                </Typography>
                {isFirstDirection && (
                  <Typography
                    sx={theme => ({
                      fontWeight: 600,
                      color: theme.palette.primary.main,
                      textDecoration: 'underline',
                      textDecorationColor: theme.palette.primary.light,
                    })}
                  >
                    {' '}
                    from your
                  </Typography>
                )}
                {isLastDirection && (
                  <Typography
                    sx={theme => ({
                      fontWeight: 600,
                      color: theme.palette.primary.main,
                      textDecoration: 'underline',
                      textDecorationColor: theme.palette.primary.light,
                    })}
                  >
                    {' '}
                    to your
                  </Typography>
                )}
                <HotelTwoToneIcon
                  sx={theme => ({
                    width: 22,
                    height: 22,
                    color: theme.palette.primary.main,
                  })}
                />

                {isFirstDirection && drivingTime > 9000 && (
                  <>
                    <Box
                      sx={{
                        paddingLeft: 1,
                        paddingRight: 1,
                        paddingY: 0,
                      }}
                    >
                      <Divider
                        orientation="vertical"
                        sx={theme => ({
                          padding: 0,
                          width: '2px', // Set the thickness of the vertical divider
                          backgroundColor: '#e4d9ff',
                          color: '#e4d9ff', // Set the color of the divider
                          margin: '0 auto', // Center the divider itself
                          position: 'relative', // Ensure the children and divider are properly aligned
                          display: 'flex',
                          justifyContent: 'center', // Center the content vertically with the divider
                          alignItems: 'center',
                          height: '15px',
                          border: 'none',

                          '& .MuiDivider-wrapper': {
                            padding: 0, // Remove padding from the wrapper
                            margin: 0, // Adjust margin as needed
                          },
                        })}
                        textAlign="center"
                      />
                    </Box>

                    <Typography
                      color="primary"
                      sx={{ fontWeight: 600 }}
                      onClick={() => {
                        openLinkInBrowserNewTab(
                          `https://www.booking.com/searchresults.en-gb.html?place_id_lat=${nextStep.latitude}&place_id_lon=${nextStep.longitude}&search_selected=true`,
                        );
                        gtag('event', 'book-closer-button');
                      }}
                    >
                      Book closer
                    </Typography>
                  </>
                )}
                {isLastDirection && drivingTime > 9000 && (
                  <>
                    <Box
                      sx={{
                        paddingLeft: 1,
                        paddingRight: 1,
                        paddingY: 0,
                      }}
                    >
                      <Divider
                        orientation="vertical"
                        sx={theme => ({
                          padding: 0,
                          width: '2px',
                          backgroundColor: '#e4d9ff',
                          color: '#e4d9ff',
                          margin: '0 auto',
                          position: 'relative',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '15px',
                          border: 'none',
                          '& .MuiDivider-wrapper': {
                            padding: 0,
                            margin: 0,
                          },
                        })}
                        textAlign="center"
                      />
                    </Box>
                    <LinkButton
                      url={`https://www.booking.com/searchresults.en-gb.html?place_id_lat=${firstStep.latitude}&place_id_lon=${firstStep.longitude}&search_selected=true`}
                      type="external"
                      prominent={true}
                    >
                      Book closer
                    </LinkButton>
                  </>
                )}
              </Stack>
            )}
          </Box>
        </Box>
        {isFirstDirection && (
          <Divider
            orientation="vertical"
            sx={theme => ({
              padding: 0,
              width: '2px', // Set the thickness of the vertical divider
              backgroundColor: '#f1eaff',
              color: '#f1eaff', // Set the color of the divider
              margin: '0 auto', // Center the divider itself
              position: 'relative', // Ensure the children and divider are properly aligned
              display: 'flex',
              justifyContent: 'center', // Center the content vertically with the divider
              alignItems: 'center',
              height: '15px',
              border: 'none',
              '& .MuiDivider-wrapper': {
                padding: 0, // Remove padding from the wrapper
                margin: 0, // Adjust margin as needed
              },
            })}
            textAlign="center"
          />
        )}
      </Box>

      {moreInfoOpened && firstStepStayClick && (
        <PlaceOrActivityMoreInfoModal
          place={firstStep.place}
          onClose={closeModal}
        />
      )}
      {moreInfoOpened && !firstStepStayClick && (
        <PlaceOrActivityMoreInfoModal
          place={nextStep.place}
          onClose={closeModal}
        />
      )}
    </Box>
  );
};

const ShowAddHotel = ({
  copy,
  closestStep,
  isFirstDirection,
  isLastDirection,
}) => {
  const componentRef = useRef<HTMLDivElement | null>(null);
  let bookingUrl = 'https://www.booking.com/';

  if (closestStep) {
    if (!!closestStep.country) {
      const newCountry = closestStep.country.replace(/ /g, '_');
      bookingUrl = `hhttps://www.booking.com/searchresults.en-gb.html?ss=${newCountry}&#map_opened`;
    }

    if (!!closestStep.city) {
      const newCity = closestStep.city.replace(/ /g, '_');
      bookingUrl = `hhttps://www.booking.com/searchresults.en-gb.html?ss=${newCity}&#map_opened`;
    }
    if (!!closestStep.city && !!closestStep.country) {
      const newCity = closestStep.city.replace(/ /g, '_');
      const newCountry = closestStep.country.replace(/ /g, '_');
      bookingUrl = `hhttps://www.booking.com/searchresults.en-gb.html?ss=${newCity}_${newCountry}&#map_opened`;
    }

    if (!!closestStep.latitude && !!closestStep.longitude) {
      bookingUrl = `https://www.booking.com/searchresults.en-gb.html?place_id_lat=${closestStep.latitude}&place_id_lon=${closestStep.longitude}&search_selected=true`;
    }
  }

  return (
    <Box
      alignItems="center"
      ref={componentRef}
      sx={theme => ({
        width: '100%',
      })}
    >
      <Box>
        {isLastDirection && (
          <Divider
            orientation="vertical"
            sx={theme => ({
              padding: 0,
              width: '2px', // Set the thickness of the vertical divider
              backgroundColor: '#f1eaff',
              color: '#f1eaff', // Set the color of the divider
              margin: '0 auto', // Center the divider itself
              position: 'relative', // Ensure the children and divider are properly aligned
              display: 'flex',
              justifyContent: 'center', // Center the content vertically with the divider
              alignItems: 'center',
              height: '15px',
              border: 'none',
              '& .MuiDivider-wrapper': {
                padding: 0, // Remove padding from the wrapper
                margin: 0, // Adjust margin as needed
              },
            })}
            textAlign="center"
          />
        )}
        <Box
          sx={theme => ({
            padding: 0,
            position: 'relative', // Ensure the children and divider are properly aligned
            display: 'flex',
            justifyContent: 'center', // Center the content vertically with the divider
            alignItems: 'center',
          })}
        >
          <Box
            sx={theme => ({
              fontWeight: 500,
              fontSize: 14,
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
              color: '#6E7191',
              padding: theme.spacing(0.75, 2),
              backgroundColor: '#faf5ff',
              borderRadius: theme.shape.borderRadius,
              border: `2px solid #f1eaff`,
              display: 'inline-block',
            })}
          >
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <HotelTwoToneIcon sx={{ width: 22, height: 22 }} />
              <Typography sx={{ fontWeight: 500 }}>
                Haven't book yet?
              </Typography>
              <Box
                sx={{
                  paddingLeft: 1,
                  paddingRight: 1,
                  paddingY: 0,
                }}
              >
                <Divider
                  orientation="vertical"
                  sx={theme => ({
                    padding: 0,
                    width: '2px',
                    backgroundColor: '#e4d9ff',
                    color: '#e4d9ff',
                    margin: '0 auto',
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '15px',
                    border: 'none',
                    '& .MuiDivider-wrapper': {
                      padding: 0,
                      margin: 0,
                    },
                  })}
                  textAlign="center"
                />
              </Box>
              <LinkButton url={bookingUrl} type="external" prominent={true}>
                Explore
              </LinkButton>
            </Stack>
          </Box>
        </Box>
        {/* {isLastDirection && <Box sx={{ marginBottom: 2 }} />} */}
        {isFirstDirection && (
          <Divider
            orientation="vertical"
            sx={theme => ({
              padding: 0,
              width: '2px', // Set the thickness of the vertical divider
              backgroundColor: '#f1eaff',
              color: '#f1eaff', // Set the color of the divider
              margin: '0 auto', // Center the divider itself
              position: 'relative', // Ensure the children and divider are properly aligned
              display: 'flex',
              justifyContent: 'center', // Center the content vertically with the divider
              alignItems: 'center',
              height: '15px',
              border: 'none',
              '& .MuiDivider-wrapper': {
                padding: 0, // Remove padding from the wrapper
                margin: 0, // Adjust margin as needed
              },
            })}
            textAlign="center"
          />
        )}
      </Box>
    </Box>
  );
};
