import { useQuery } from '@apollo/client';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import HistoryToggleOffTwoToneIcon from '@mui/icons-material/HistoryToggleOffTwoTone';
import TourTwoToneIcon from '@mui/icons-material/TourTwoTone';
import {
  Box,
  CardMedia,
  Grid,
  Typography,
  styled,
  Skeleton,
  Stack,
} from '@mui/material';
import { Maybe } from 'graphql/jsutils/Maybe';
import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import AuthorizedApolloProvider from '../../AuthorizedApolloProvider';
import { Trip } from '../../generated/public_graphql';
import { useUserData } from '../../hooks/useUserData';
import {
  ProminentOrangeButton,
  TextButton,
} from '../../theme-components/Buttons';
import { CardLabel } from '../../theme-components/Lables';
import { TitleH1, TitleH2 } from '../../theme-components/Typography';
import { unauthorisedClient } from '../../utils/auth';
import { CountriesWithTripsList } from '../country/CountriesWithTrips';
import { LikeTrip } from '../favourite/likeTrip';
import { QUERY_COUNTRIES_TRIPS } from '../gql-public/countriesTripsQuery';
import { QUERY_TRIPS } from '../gql-public/tripsListQuery';
import { TripCountry, TripLength, TripTitle } from '../user-account/UserTrips';

import { GradientContainer } from './HomeHeader';
import { ContainedUserProfile } from '../trip-details/TripDetails';

interface PropsTripsList {
  readonly trips: Maybe<Trip>[];
}

const StyledGrid = styled(Grid)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: 5,
  width: '100%',
  flexWrap: 'nowrap', // Prevent wrapping of items
  overflowY: 'auto', // Allows vertical scrolling
  alignItems: 'stretch',
  [theme.breakpoints.down('md')]: {
    paddingLeft: 8,
  },
}));

const ScrollButtons = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  marginTop: -240,
  marginBottom: 0,
  cursor: 'pointer',
  justifyContent: 'space-between',
}));

const ButtonBackground = styled(Box)(({ theme }) => ({
  width: 40,
  height: 40,
  margin: 5,
  backgroundColor: '#FFF3DB',
  borderRadius: 20,
  position: 'relative',
  padding: 8,
}));

const TripImage = styled(CardMedia)(({ theme }) => ({
  borderRadius: '20px',
  height: 270,
  width: 400,
  [theme.breakpoints.down('sm')]: {
    height: 250,
    width: 340,
  },
}));
const TripImageLanding = styled(CardMedia)(({ theme }) => ({
  borderTopRightRadius: '20px',
  borderTopLeftRadius: '20px',
  height: 230,
  width: 400,
  [theme.breakpoints.down('sm')]: {
    height: 220,
    width: 340,
  },
}));

const MoreInfoBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row', // Already in row, no change needed
  flexWrap: 'wrap', // Keeps wrapping if there are too many items
  // justifyContent: 'center', // Horizontally centers items
  alignItems: 'center', // Vertically centers items
  marginBottom: theme.spacing(1),
  // marginTop: theme.spacing(1),
}));

export function HomeTrips(props: { showExplore?: boolean }) {
  const navigate = useNavigate();
  // const [isMounted, setIsMounted] = useState(true);

  const { loading, error, data } = useQuery(QUERY_TRIPS, {
    client: unauthorisedClient,
  });
  const { data: dataCountries } = useQuery(QUERY_COUNTRIES_TRIPS, {
    client: unauthorisedClient,
  });

  // useEffect(() => {
  //   setIsMounted(true);
  //   return () => setIsMounted(false); // Set flag to false on unmount
  // }, []);

  // if (loading) {
  //   return <LoadingCards />;
  // }

  if (error) {
    return (
      <div>
        Couldn't load trips, please contact support at help@rooutie.com or
        refresh
      </div>
    );
  }

  return (
    <>
      {props.showExplore && (
        <CountriesWithTripsList showExplore={props.showExplore} />
      )}

      <GradientContainer gradient="linear-gradient(180deg, #F9E7E1 0%, #FFFFFF 100%)">
        {/* <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}> */}
        <Box>
          <TitleH2>Adventure awaits</TitleH2>
        </Box>
        {loading ? (
          <LoadingCards />
        ) : (
          <>
            {error || !data || !data.tripsList ? (
              <div>
                Couldn't load trips, please contact support at help@rooutie.com
                or refresh
              </div>
            ) : (
              <Box
                sx={theme => ({
                  marginRight: -2,
                  marginLeft: -2,
                })}
              >
                <Trips trips={data.tripsList} />
              </Box>
            )}
          </>
        )}
      </GradientContainer>
    </>
  );
}

function LoadingCards() {
  const loadingCards = [1, 2, 3, 4, 5, 6, 7];

  return (
    <StyledGrid
      container
      key={1}
      sx={{
        paddingBottom: 1,
        width: '100%',
        flexWrap: 'nowrap', // Prevent wrapping of items
        overflowY: 'auto', // Allows vertical scrolling
      }}
    >
      {loadingCards.map(index => (
        <Box
          key={index}
          sx={theme => ({
            borderRadius: '20px',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
            border: 'none',
            cursor: 'pointer',
            marginRight: 1,
            marginLeft: 1,
            marginBottom: 2,
            backgroundColor: '#FFFFFF',
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
          })}
        >
          <Box
            sx={theme => ({
              justifyContent: 'flex-start',
              borderRadius: '20px',
              padding: 0,
              margin: 0,
              // position: 'relative',
              backgroundColor: '#FFFFFF',
              height: 270,
              width: 400,
              [theme.breakpoints.down('sm')]: {
                height: 250,
                width: 340,
              },
            })}
            key={index}
          >
            <Skeleton
              height="60%"
              variant="rectangular"
              sx={{
                // position: 'absolute',
                marginBottom: 1,
                borderTopLeftRadius: '20px',
                borderTopRightRadius: '20px',
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                padding: 0,
                margin: 0,
                display: 'block',
              }}
            />
            <Skeleton
              sx={{
                padding: 0,
                margin: 1,
              }}
            />
            <Skeleton
              sx={{
                padding: 0,
                margin: 1,
              }}
            />
            <Skeleton
              sx={{
                padding: 0,
                margin: 1,
              }}
            />
          </Box>
        </Box>
      ))}
    </StyledGrid>
  );
}

function Trips(props: PropsTripsList) {
  useUserData();
  const navigate = useNavigate();
  const contentWrapper = useRef<HTMLDivElement | null>(null);

  return (
    <>
      <StyledGrid container ref={contentWrapper}>
        {props.trips &&
          props.trips.length > 0 &&
          props.trips.map((trip, index) => (
            <Box sx={{ paddingRight: 2, paddingBottom: 2 }}>
              <Box
                key={index}
                boxShadow={1}
                onClick={() => {
                  navigate('/trip/' + trip!.id);
                }}
                sx={theme => ({
                  borderRadius: '20px',
                  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
                  border: 'none',
                  cursor: 'pointer',
                  // marginRight: 1,
                  // marginLeft: 1,
                  // marginBottom: 2,
                  // width: 400,
                  backgroundColor: '#FFFFFF',
                  display: 'flex',
                  flexDirection: 'column',
                  position: 'relative',
                  [theme.breakpoints.down('sm')]: {
                    // width: 340,
                  },
                })}
              >
                {trip!.images && trip!.images.length > 0 ? (
                  <TripImage
                    image={
                      trip!.images?.find(image => image!.main)?.path ||
                      trip!.images[0]?.path ||
                      ''
                    }
                  />
                ) : (
                  <TripImage image={'/assets/image/placeholder-svg.svg'} />
                )}
                <CardLabel
                  text={
                    trip && trip.tripType && trip.tripType === 'guide'
                      ? 'Guide'
                      : 'Itinerary'
                  }
                />
                <LikeTrip tripId={trip!.id} />
                <Box
                  sx={{
                    position: 'absolute',
                    width: '100%',
                    bottom: 0, // Align the content to the bottom
                    left: 0,
                    right: 0,
                    background: 'rgba(255, 255, 255, 0.7)', // Semi-transparent white background
                    backdropFilter: 'blur(3px)', // Blur effect on the background
                    paddingY: 1,
                    paddingX: 2,
                    paddingBottom: 1.5, // Adjust the padding to fit better
                    borderRadius: '0 0 20px 20px', // Rounded corners at the bottom
                  }}
                >
                  <TripTitle>{trip!.name}</TripTitle>
                  <Stack
                    direction="row"
                    alignItems="center" // Center vertically
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap', // This allows the countries to wrap onto the next line
                    }}
                  >
                    <>
                      {trip && trip.tripType && trip.tripType === 'guide' ? (
                        <>
                          {trip && trip.cities && trip.cities.length > 0 && (
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                              }}
                            >
                              {trip.cities.map((city, index) => (
                                <React.Fragment key={index}>
                                  <TripCountry>{city?.name}</TripCountry>
                                  {index !== trip.cities!.length - 1 && (
                                    <Box
                                      sx={{
                                        width: 5,
                                        height: 5,
                                        backgroundColor: 'black',
                                        borderRadius: '50%',
                                        mx: 1,
                                      }}
                                    />
                                  )}
                                </React.Fragment>
                              ))}
                            </Stack>
                          )}
                        </>
                      ) : (
                        <>
                          {trip &&
                            trip.itineraryCountries &&
                            trip.itineraryCountries.length > 0 &&
                            trip.itineraryCountries.map((country, index) => (
                              <React.Fragment key={index}>
                                {country && (
                                  <>
                                    {trip.length && index === 0 && (
                                      <TripLength>
                                        {trip.length}
                                        {trip.length === 1 ? ' day' : ' days'}
                                      </TripLength>
                                    )}
                                    <Box
                                      sx={{
                                        width: 5, // Width of the dot
                                        height: 5, // Height of the dot
                                        backgroundColor: 'black', // Color of the dot
                                        borderRadius: '50%', // Make it circular
                                        mx: 1, // Horizontal margin for spacing
                                      }}
                                    />

                                    <TripCountry>
                                      {country.countryName}
                                    </TripCountry>
                                  </>
                                )}
                              </React.Fragment>
                            ))}
                        </>
                      )}
                    </>
                  </Stack>
                  {/* </StyledCardContent>
                   */}
                </Box>
              </Box>
            </Box>
          ))}
      </StyledGrid>
      <Box sx={{ display: { xs: 'none', sm: 'block', md: 'block' } }}>
        <ScrollButtons>
          <Box
            onClick={() => sideScroll(contentWrapper.current!, 25, 100, -10)}
          >
            <ButtonBackground>
              <ChevronLeftIcon />
            </ButtonBackground>
          </Box>
          <Box onClick={() => sideScroll(contentWrapper.current!, 25, 100, 10)}>
            <ButtonBackground>
              <ChevronRightIcon />
            </ButtonBackground>
          </Box>
        </ScrollButtons>
        <Box height={200} />
      </Box>
    </>
  );
}

function LandingTripsItems(props: PropsTripsList) {
  useUserData();
  const navigate = useNavigate();
  const contentWrapper = useRef<HTMLDivElement | null>(null);

  return (
    <Box>
      <Box
        ref={contentWrapper}
        sx={theme => ({
          paddingTop: theme.spacing(1),
          display: 'flex',
          flexWrap: 'nowrap',
          overflowX: 'auto',
          overflowY: 'hidden',
          width: '100%',

          [theme.breakpoints.down('md')]: { paddingLeft: 2 },
          /* Hide scrollbar for Webkit-based browsers (Chrome, Safari) */
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          /* Hide scrollbar for IE, Edge */
          '-ms-overflow-style': 'none' /* IE and Edge */,
          /* Hide scrollbar for Firefox */
          'scrollbar-width': 'none' /* Firefox */,
        })}
      >
        {props.trips &&
          props.trips.length > 0 &&
          props.trips.map((trip, index) => (
            // <Box sx={{ paddingRight: 2 }}>
            //   <Box
            //     key={index}
            //     boxShadow={1}
            //     onClick={() => {
            //       navigate('/trip/' + trip!.id);
            //     }}
            //     sx={theme => ({
            //       borderRadius: '20px',
            //       border: 'none',
            //       cursor: 'pointer',
            //       display: 'flex',
            //       flexDirection: 'column',
            //       position: 'relative',
            //       height: 380,
            //       width: 400,
            //       [theme.breakpoints.down('sm')]: {
            //         height: 340,
            //         width: 340,
            //       },
            //     })}
            //   >
            //     <Box>
            //       {trip!.images && trip!.images.length > 0 ? (
            //         <TripImageLanding
            //           image={
            //             trip!.images?.find(image => image!.main)?.path ||
            //             trip!.images[0]?.path ||
            //             ''
            //           }
            //         />
            //       ) : (
            //         <TripImageLanding
            //           image={'/assets/image/placeholder-svg.svg'}
            //         />
            //       )}
            //     </Box>
            //     <CardLabel
            //       text={
            //         trip && trip.tripType && trip.tripType === 'guide'
            //           ? 'Guide'
            //           : 'Itinerary'
            //       }
            //     />
            //     <LikeTrip tripId={trip!.id} />
            //     <Box
            //       sx={{
            //         display: 'flex',
            //         flexDirection: 'column',
            //         justifyContent: 'space-between',
            //         width: '100%',
            //         height: 150,
            //         paddingTop: 1,
            //         paddingX: 2,
            //         paddingBottom: 1.5, // Adjust the padding to fit better
            //         borderRadius: '0 0 20px 20px', // Rounded corners at the bottom
            //       }}
            //     >
            //       <Typography
            //         sx={theme => ({
            //           fontWeight: 600,
            //           fontSize: 18,
            //           overflow: 'hidden',
            //           display: '-webkit-box',
            //           WebkitLineClamp: 2,
            //           WebkitBoxOrient: 'vertical',
            //           textOverflow: 'ellipsis',
            //           maxHeight: '40px', // Adjust this value as needed
            //           [theme.breakpoints.down('sm')]: {
            //             fontSize: 18,
            //           },
            //         })}
            //       >
            //         {trip!.name}
            //       </Typography>
            //       <Stack
            //         direction="row"
            //         alignItems="center" // Center vertically
            //         sx={{
            //           display: 'flex',
            //           flexWrap: 'wrap', // This allows the countries to wrap onto the next line
            //         }}
            //       >
            //         <>
            //           {trip && trip.tripType && trip.tripType === 'guide' ? (
            //             <>
            //               {trip && trip.cities && trip.cities.length > 0 && (
            //                 <Stack
            //                   direction="row"
            //                   alignItems="center"
            //                   sx={{
            //                     display: 'flex',
            //                     flexWrap: 'wrap',
            //                   }}
            //                 >
            //                   {trip.cities.map((city, index) => (
            //                     <React.Fragment key={index}>
            //                       <TripCountry>{city?.name}</TripCountry>
            //                       {index !== trip.cities!.length - 1 && (
            //                         <Box
            //                           sx={{
            //                             width: 5,
            //                             height: 5,
            //                             backgroundColor: 'black',
            //                             borderRadius: '50%',
            //                             mx: 1,
            //                           }}
            //                         />
            //                       )}
            //                     </React.Fragment>
            //                   ))}
            //                 </Stack>
            //               )}
            //             </>
            //           ) : (
            //             <>
            //               {trip &&
            //                 trip.itineraryCountries &&
            //                 trip.itineraryCountries.length > 0 &&
            //                 trip.itineraryCountries.map((country, index) => (
            //                   <React.Fragment key={index}>
            //                     {country && (
            //                       <>
            //                         {trip.length && index === 0 && (
            //                           <TripLength>
            //                             {trip.length}
            //                             {trip.length === 1 ? ' day' : ' days'}
            //                           </TripLength>
            //                         )}
            //                         <Box
            //                           sx={{
            //                             width: 5, // Width of the dot
            //                             height: 5, // Height of the dot
            //                             backgroundColor: 'black', // Color of the dot
            //                             borderRadius: '50%', // Make it circular
            //                             mx: 1, // Horizontal margin for spacing
            //                           }}
            //                         />

            //                         <TripCountry>
            //                           {country.countryName}
            //                         </TripCountry>
            //                       </>
            //                     )}
            //                   </React.Fragment>
            //                 ))}
            //             </>
            //           )}
            //         </>
            //       </Stack>
            //     </Box>
            //     {/* </StyledCardContent>
            //      */}
            //   </Box>
            // </Box>
            <LandingTripsItemsCards trip={trip} />
            // <></>
          ))}
      </Box>
      <Box sx={{ display: { xs: 'none', sm: 'block', md: 'block' } }}>
        <ScrollButtons>
          <Box onClick={() => sideScroll(contentWrapper!.current!, 1, 800, -5)}>
            <ButtonBackground>
              <ChevronLeftIcon />
            </ButtonBackground>
          </Box>
          <ButtonBackground
            onClick={() => sideScroll(contentWrapper!.current!, 1, 800, 5)}
          >
            <ChevronRightIcon />
          </ButtonBackground>
        </ScrollButtons>
        <Box height={200} />
      </Box>
    </Box>
  );
}

const sideScroll = (
  element: HTMLDivElement,
  speed: number,
  distance: number,
  step: number,
) => {
  if (element) {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);

    // Return a cleanup function
    return () => clearInterval(slideTimer);
  }
};

export function LandingTrips() {
  const navigate = useNavigate();
  // const [isMounted, setIsMounted] = useState(true);

  const { loading, error, data } = useQuery(QUERY_TRIPS, {
    client: unauthorisedClient,
  });
  // const { data: dataCountries } = useQuery(QUERY_COUNTRIES_TRIPS, {
  //   client: unauthorisedClient,
  // });

  // useEffect(() => {
  //   setIsMounted(true);
  //   return () => setIsMounted(false); // Set flag to false on unmount
  // }, []);

  // if (loading) {
  //   return <LoadingCards />;
  // }

  if (error) {
    return (
      <div>
        Couldn't load trips, please contact support at help@rooutie.com or
        refresh
      </div>
    );
  }

  return (
    <Box
      sx={{
        // position: 'absolute', // Position the Box absolutely
        // top: 0,
        // left: 0,
        width: '100vw', // Full viewport width
        // height: '100vh', // Full viewport height or any desired height
        overflowX: 'scroll', // Enable horizontal scrolling
        // whiteSpace: 'nowrap', // Prevent line breaks to keep elements in a single row
        // backgroundColor: '#3A271D', // Background color for visibility
        // width: '100%',
        height: '100%',
        //         backgroundImage: `
        //   radial-gradient(circle at 10% 20%, rgba(58, 39, 29, 0.4) 0%, rgba(58, 39, 29, 0) 15%),
        //   radial-gradient(circle at 30% 20%, rgba(58, 39, 29, 0.5) 0%, rgba(58, 39, 29, 0) 20%),
        //   radial-gradient(circle at 40% 20%, rgba(58, 39, 29, 0.6) 0%, rgba(58, 39, 29, 0) 25%),
        //   linear-gradient(180deg, rgba(58, 39, 29, 0.2) 0%, rgba(58, 39, 29, 0.3) 100%)
        // `,
        //         backgroundSize: 'cover',
      }}
    >
      {/* <GradientContainer gradient="linear-gradient(180deg, #F9E7E1 0%, #FFFFFF 100%)"> */}
      <Box
        sx={theme => ({
          width: '100%',
          // background:
          //   gradient || 'linear-gradient(180deg, #FFF3DB 0%, #FFFFFF 100%)',
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(2),
          paddingRight: theme.spacing(4),
          paddingLeft: theme.spacing(4),
          // borderRadius: theme.shape.borderRadius * 2,
          [theme.breakpoints.down('md')]: {
            paddingRight: theme.spacing(2),
            paddingLeft: theme.spacing(2),
            // borderRadius: 0,
          },
        })}
      >
        {/* <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}> */}
        {/* <Box>
          <TitleH2>Adventure awaits</TitleH2>
        </Box> */}
        <Box
          sx={theme => ({
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            marginBottom: 3,
            [theme.breakpoints.down('sm')]: {
              marginBottom: 1,
            },
          })}
        >
          <TitleH1 sx={{ textAlign: 'center' }}>Your Adventure Awaits</TitleH1>
        </Box>
        {loading ? (
          <LoadingCards />
        ) : (
          <>
            {error || !data || !data.tripsList ? (
              <div>
                Couldn't load trips, please contact support at help@rooutie.com
                or refresh
              </div>
            ) : (
              <Box
                sx={theme => ({
                  marginRight: -2,
                  marginLeft: -2,
                })}
              >
                <LandingTripsItems trips={data.tripsList} />
              </Box>
            )}
          </>
        )}
        {/* </GradientContainer> */}
      </Box>
    </Box>
  );
}

interface LandingTripsItemsCardsProps {
  trip: Maybe<Trip> | null;
}

const LandingTripsItemsCards: React.FC<LandingTripsItemsCardsProps> = ({
  trip,
}) => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        paddingRight: 1,
        display: 'flex',
      }}
    >
      <Box
        onClick={() => {
          navigate('/trip/' + trip!.id);
        }}
        sx={{
          borderRadius: '10px',
          backgroundColor: 'rgba(250, 245, 255, 0.5)',
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'auto',
          position: 'relative',
          cursor: 'pointer',
          flexShrink: 0,
          border: '1px solid #E4DBFF',
          width: 320,
          transition: 'box-shadow 0.3s ease-in-out',
        }}
      >
        <CardMedia
          component="img"
          image={
            trip?.images?.find(image => image!.main)?.mediumPath ||
            trip?.images?.find(image => image!.main)?.previewPath ||
            trip?.images?.find(image => image!.main)?.path ||
            trip?.images?.find(image => image!.main)?.smallPath ||
            (trip?.images && trip?.images[0]?.mediumPath) ||
            (trip?.images && trip?.images[0]?.previewPath) ||
            (trip?.images && trip?.images[0]?.path) ||
            (trip?.images && trip?.images[0]?.smallPath) ||
            '/assets/image/placeholder-svg.svg'
          }
          sx={{
            width: '100%',
            height: 180, // Adjusted height for better aspect ratio
            objectFit: 'cover',
            borderTopRightRadius: '10px',
            borderTopLeftRadius: '10px',
          }}
        />
        <CardLabel
          text={
            trip && trip.tripType && trip.tripType === 'guide'
              ? 'Guide'
              : 'Itinerary'
          }
        />
        <LikeTrip tripId={trip!.id} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            paddingY: 1.5,
            paddingX: 1.5,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
            }}
          >
            <Typography
              sx={{
                marginBottom: 0.5,
                fontSize: 18,
                fontWeight: 700,
                color: '#001B30',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
              }}
            >
              {trip && trip.name}
            </Typography>
          </Box>

          {trip && trip.userDetails && (
            <ContainedUserProfile tripUser={trip.userDetails} />
          )}

          {trip && trip.tripType && trip.tripType === 'guide' ? (
            <></>
          ) : (
            <Stack direction={'row'} alignItems={'center'} mb={0.5}>
              <HistoryToggleOffTwoToneIcon
                sx={theme => ({
                  width: 25,
                  height: 25,
                  marginRight: 1,
                  color: '#F39C6A',
                  [theme.breakpoints.down('sm')]: {},
                })}
              />
              {trip && trip.length && trip.length > 0 && (
                <Typography
                  sx={theme => ({
                    fontWeight: 600,
                    fontSize: 16,
                    color: '#36454F',
                    [theme.breakpoints.down('sm')]: {
                      fontSize: 16,
                    },
                  })}
                >
                  {trip.length}
                  {trip.length === 1 ? ' day ' : ' days '}
                  itinerary
                </Typography>
              )}
            </Stack>
          )}
          <Stack
            direction="row"
            width="100%"
            alignItems="flex-start" // Center vertically
            sx={{
              display: 'flex',
            }}
          >
            {((trip && trip.cities && trip.cities.length > 0) ||
              (trip && trip.countries && trip.countries.length > 0)) && (
              <TourTwoToneIcon
                sx={theme => ({
                  width: 25,
                  height: 25,
                  marginRight: 1,
                  color: '#F39C6A',
                })}
              />
            )}
            {trip && trip.tripType && trip.tripType === 'guide' ? (
              <>
                {trip && trip.cities && trip.cities.length > 0 && (
                  <Typography
                    sx={{
                      display: '-webkit-box',
                      flexWrap: 'wrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      WebkitLineClamp: 2, // Limit to 2 lines
                      WebkitBoxOrient: 'vertical',
                    }}
                  >
                    {trip.cities.map((city, index) => (
                      <React.Fragment key={index}>
                        <Typography
                          component="span"
                          sx={theme => ({
                            fontWeight: 500,
                            fontSize: 16,
                            color: '#36454F',
                            // color: theme.palette.secondary.main,
                            [theme.breakpoints.down('sm')]: {
                              fontSize: 16,
                            },
                          })}
                        >
                          {city?.name}
                        </Typography>
                        {index !== trip.cities!.length - 1 && (
                          <Typography
                            component="span"
                            sx={{
                              mx: 1,
                              fontSize: '1.5rem', // Adjust the size as needed
                              lineHeight: 1,
                            }}
                          >
                            •
                          </Typography>
                        )}
                      </React.Fragment>
                    ))}
                  </Typography>
                )}
              </>
            ) : (
              <Typography
                // component="span"
                sx={{
                  maxWidth: '100%',
                  display: '-webkit-box',
                  flexWrap: 'wrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  WebkitLineClamp: 2, // Limit to 2 lines
                  WebkitBoxOrient: 'vertical',
                }}
              >
                {trip &&
                  trip.countries &&
                  trip.countries.length > 0 &&
                  trip.countries.map((country, index) => (
                    <React.Fragment key={index}>
                      {country && (
                        <>
                          <Typography
                            component="span"
                            sx={theme => ({
                              fontWeight: 500,
                              fontSize: 16,
                              color: '#36454F',
                              // color: theme.palette.secondary.main,
                              [theme.breakpoints.down('sm')]: {
                                fontSize: 16,
                              },
                            })}
                          >
                            {country.name}
                            {trip.countries!.length - 1 !== index && (
                              <Typography
                                component="span"
                                sx={{
                                  fontSize: '1.5rem', // Adjust the size as needed
                                  lineHeight: 1,
                                }}
                              >
                                {' '}
                                •{' '}
                              </Typography>
                            )}
                          </Typography>
                        </>
                      )}
                    </React.Fragment>
                  ))}
              </Typography>
            )}
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};
