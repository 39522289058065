import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  useLazyQuery,
} from '@apollo/client';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import AuthorizedApolloProvider from '../../AuthorizedApolloProvider';
import { FullScreenBusAnimation } from '../../components/animations/BusAnimation';
import { QUERY_GET_TRIP } from '../../components/gql-public/tripQuery';
import { RouteContent, RoutePage, RouteTitle } from '../../components/route';
import {
  ContainedUserProfile,
  TripDetails,
} from '../../components/trip-details/TripDetails';
import { TripHeaderImages } from '../../components/trip-details/TripHeader';
import { TripItinerary } from '../../components/trip-details/TripItinerary';
import { TripMap } from '../../components/trip-details/TripMap';
import { User } from '../../generated/user_graphql';
import { setPublicTrip } from '../../store/PublicTripSlice';
import { setUserTrips } from '../../store/TripSlice';
import { setUser } from '../../store/UserSlice';
import { useAuth } from '../auth/firebase';
import { QUERY_GET_USER } from '../gql-user/user';
import { QUERY_GET_USER_TRIPS_WITH_DAYS_ONLY } from '../gql-user/userTripsQuery';

import { MapDrawer } from './MapDrawer';
import DaysSidebar from './TripSideNavigation';
import { getSafeAreaInsetTop } from '../../utils/helpers';
import { unauthorisedClient } from '../../utils/auth';
import ShareTripModal from '../user-account/ShareTripModal';
import { QUERY_GET_FULL_SHARED_TRIP } from '../gql-public/sharedTipQuery';
import { CheckoutModal } from '../payment/PaymentCheckoutModal';
import SignUp from '../auth/SignUp';
import { TripPayOverlay } from '../payment/PayOverlay';
import CopyTripBox from './CopyTripBox';

export default function PublicTrip() {
  gtag('event', 'public-trip-page');
  const { idToken, isAuthenticated, isLoading } = useAuth();
  const { id } = useParams<{ id: string }>();
  const { trip_id } = useParams<{ trip_id: string }>();
  const { shared_id } = useParams<{ shared_id: string }>();
  const dispatch = useDispatch();
  const [selectedStepId, setSelectedStepId] = useState(null);
  const [selectedDayId, setSelectedDayId] = useState('');
  const [stepInView, setStepInView] = useState('');

  const stepRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const dayRefs = useRef<Record<string, HTMLDivElement | null>>({});

  const scrollPositionRef = useRef<number>(0);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [getUserTrips, { data: userTripsData, loading: loadingUserTrips }] =
    useLazyQuery(QUERY_GET_USER_TRIPS_WITH_DAYS_ONLY, {});

  const [getUser, { data: userData, loading: loadingUser }] = useLazyQuery<{
    getUser: User;
  }>(QUERY_GET_USER, {});

  useEffect(() => {
    if (!isLoading && isAuthenticated && idToken) {
      const fetchUserTrips = async () => {
        await getUserTrips();
        await getUser();
      };

      fetchUserTrips();
    }
  }, [isAuthenticated, idToken, isLoading]);

  useEffect(() => {
    if (
      userTripsData &&
      userTripsData.userTripsWithDaysOnly &&
      userTripsData.userTripsWithDaysOnly.length > 0
    ) {
      dispatch(setUserTrips(userTripsData.userTripsWithDaysOnly));
    }
    if (userData && userData.getUser) {
      dispatch(setUser(userData.getUser));
    }
  }, [userTripsData, userData]); // dependencies for useEffect

  // Get trip data
  const [getTrip, { data, error, loading }] = useLazyQuery(QUERY_GET_TRIP, {
    client: unauthorisedClient,
    variables: { trip_id: id },
  });

  const [
    getSharedTrip,
    { data: sharedData, error: sharedError, loading: sharedLoading },
  ] = useLazyQuery(QUERY_GET_FULL_SHARED_TRIP, {
    client: unauthorisedClient,
    variables: { trip_id: trip_id, shared_trip_id: shared_id },
  });

  useEffect(() => {
    if (id) {
      getTrip();
    }
  }, [id]);

  useEffect(() => {
    if (shared_id && trip_id) {
      getSharedTrip();
    }
  }, [shared_id, trip_id]);

  useEffect(() => {
    if (data && data.trip) {
      dispatch(setPublicTrip(data.trip));
    }

    if (sharedData && sharedData.fullSharedTrip) {
      dispatch(setPublicTrip(sharedData.fullSharedTrip));
    }
  }, [sharedData, data, dispatch]);

  const scrollToStep = (stepId: string) => {
    if (stepId) {
      const stepRef = stepRefs.current[stepId];
      if (stepRef) {
        stepRef.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
        setTimeout(() => {
          stepRef.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }, 1000);
      }
    }
  };

  const scrollToDay = (dayId: string) => {
    // if (!dayIdSetThroughScroll) {
    if (dayId) {
      const dayRef = dayRefs.current[dayId];
      const safeAreaTop = getSafeAreaInsetTop();
      if (dayRef) {
        if (isSmallScreen) {
          dayRef.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          setTimeout(() => {
            dayRef.scrollIntoView({
              behavior: 'auto',
              block: 'start',
            });
          }, 1000);
          setTimeout(() => {
            window.scrollBy({
              top: -(60 + safeAreaTop),
              left: 0,
              behavior: 'auto',
            });
          }, 1100);
        } else {
          dayRef.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          setTimeout(() => {
            dayRef.scrollIntoView({
              behavior: 'auto',
              block: 'start',
            });
          }, 1000);
          setTimeout(() => {
            window.scrollBy({
              top: -75, // Scroll down 300px
              left: 0, // No horizontal scrolling
              behavior: 'auto', // Optional: 'auto' for instant scrolling or 'smooth' for smooth scrolling
            });
          }, 1100);
        }
        // }
      }
    }
  };

  // useEffect(() => {
  //   console.log('RESET------------> 1');

  //   // Restore the scroll position if it's stored
  //   if (
  //     scrollPositionRef.current &&
  //     (selectedStepId === null || selectedDayId === null)
  //   ) {
  //     window.scrollTo(0, scrollPositionRef.current);
  //   }
  // }, [selectedStepId, selectedDayId]);

  const handleMarkerClick = stepId => {
    setSelectedStepId(stepId);
    scrollToStep(stepId);
  };

  const handleCloseClick = () => {
    scrollPositionRef.current = window.scrollY;
    setSelectedStepId(null);
  };

  const handleMenuDayClick = dayId => {
    // setSelectedStepId(null);
    setSelectedDayId(dayId);
    scrollToDay(dayId);
  };

  const handleMenuStepClick = stepId => {
    // setOpenSideMenu(false);
    setSelectedStepId(stepId);
    scrollToStep(stepId);
  };

  const handleCardInView = (stepId: string) => {
    setStepInView(stepId);
  };

  const handleDayInView = (dayId: string) => {
    setSelectedDayId(dayId);
  };

  if (
    loading ||
    loadingUserTrips ||
    loadingUser ||
    sharedLoading ||
    isLoading
  ) {
    if (
      !loading &&
      !loadingUserTrips &&
      !loadingUser &&
      !sharedLoading &&
      !isLoading
    ) {
      setTimeout(() => {
        return null;
      }, 1000);
    }
    return <FullScreenBusAnimation copy="Almost there!" />;
  }

  if (
    ((!id && !shared_id && !trip_id) ||
      error ||
      sharedError ||
      (!data && !sharedData)) &&
    !loading &&
    !sharedLoading
  ) {
    return (
      <>
        <RoutePage>
          <RouteTitle>Trip</RouteTitle>
          <RouteContent>
            Trip not found, contact support help@rooutie.com
          </RouteContent>
        </RoutePage>
      </>
    );
  }

  return (
    <>
      <MapDrawer
        tripOwner="public"
        onMarkerClick={handleMarkerClick}
        onCloseClick={handleCloseClick}
        onDayClick={handleMenuDayClick}
        clickedDayId={selectedDayId}
        stepInView={stepInView}
        mapId="trip"
      />
      {data?.trip && (
        <ShareTripModal
          tripId={data?.trip.id}
          sharableId={data?.trip.sharableId}
          published={data?.trip.published}
          shareButton="floating"
        />
      )}
      {sharedData?.fullSharedTrip && (
        <ShareTripModal
          tripId={sharedData?.fullSharedTrip.id}
          sharableId={sharedData?.fullSharedTrip.sharableId}
          published={sharedData?.fullSharedTrip.published}
          shareButton="floating"
        />
      )}

      <Stack
        direction="row"
        alignItems="left"
        sx={{
          width: '100%',
        }}
      >
        <Stack
          direction="row"
          alignItems="left"
          sx={{
            width: 'calc(100vw - 232px)',
            [theme.breakpoints.down('md')]: {
              width: '100%',
            },
          }}
        >
          {!isSmallScreen && (
            <Box
              sx={theme => ({
                display: 'block',
                width: '50%',
                [theme.breakpoints.down(1040)]: {
                  width: '44%',
                },
                [theme.breakpoints.down('md')]: {
                  width: '47%',
                },
                [theme.breakpoints.down('sm')]: {
                  display: 'none',
                },
              })}
            >
              <TripMap
                tripOwner="public"
                onMarkerClick={handleMarkerClick}
                onCloseClick={handleCloseClick}
                onDayClick={handleMenuDayClick}
                clickedDayId={selectedDayId}
                stepInView={stepInView}
              />
            </Box>
          )}
          <Box
            sx={theme => ({
              marginTop: 2,
              display: 'block',
              width: '50%',
              [theme.breakpoints.down(1040)]: {
                width: '56%',
              },
              [theme.breakpoints.down('md')]: {
                width: '53%',
                paddingRight: 1.5,
              },
              [theme.breakpoints.down('sm')]: {
                width: '100%',
                marginTop: 0,
                paddingRight: 0,
              },
            })}
          >
            <TripHeaderImages tripType="public" />
            <Box
              sx={{
                width: '100%',
                marginTop: 0.5,
                paddingLeft: 1.5,
                [theme.breakpoints.down('sm')]: {
                  paddingRight: 1.5,
                },
              }}
            >
              <CopyTripBox tripUser="public" />
            </Box>

            <Stack
              direction="row"
              alignItems="left"
              sx={{
                width: '100%',
                paddingLeft: 1.5,
                [theme.breakpoints.down('sm')]: {
                  paddingRight: 1.5,
                },
              }}
            >
              <Stack
                direction="column"
                alignItems="top"
                sx={theme => ({
                  width: '100%',
                  [theme.breakpoints.down('sm')]: {
                    width: '100%',
                  },
                })}
              >
                <TripDetails tripUser="public" />
                <Box position="relative">
                  <TripItinerary
                    tripUser="public"
                    selectedStepId={selectedStepId}
                    stepRefs={stepRefs}
                    dayRefs={dayRefs}
                    onCardInView={handleCardInView}
                    onDayInView={handleDayInView}
                  />
                  {data?.trip?.tripAccess?.fullViewGranted !== true &&
                    data?.trip?.priceId && (
                      <>
                        <TripPayOverlay trip={data?.trip} />
                      </>
                    )}
                  {data?.fullSharedTrip?.tripAccess?.fullViewGranted !== true &&
                    data?.fullSharedTrip?.priceId && (
                      <>
                        <TripPayOverlay trip={sharedData?.fullSharedTrip} />
                      </>
                    )}
                </Box>
              </Stack>
            </Stack>
          </Box>
        </Stack>
        {data?.trip?.itinerary?.days &&
          data?.trip?.itinerary?.days.length > 0 && (
            <>
              <DaysSidebar
                days={data.trip.itinerary.days}
                onDayClick={handleMenuDayClick}
                onStepClick={handleMenuStepClick}
                tripType={
                  data?.trip?.tripType ? data.trip.tripType : 'itinerary'
                }
                trip={data?.trip}
                currentDayId={selectedDayId}
                stepInView={stepInView}
                numberOfHiddenDays={
                  data?.trip?.priceId && data?.trip?.length > 1
                    ? data.trip.length - 1
                    : undefined
                }
              />
              {/* {checkoutOpen && (
                <>
                  {isAuthenticated ? (
                    <CheckoutModal
                      tripId={data.trip.id}
                      handleCloseModal={() => {
                        setCheckoutOpen(false);
                      }}
                    />
                  ) : (
                    <>
                      <SignUp
                        showInModal={true}
                        loginDefault={false}
                        open={signUpOpened}
                        onClose={() => {
                          setSignUpOpened(false);
                        }}
                      />
                    </>
                  )}
                </>
              )} */}
            </>
          )}
        {sharedData?.fullSharedTrip?.itinerary?.days &&
          sharedData?.fullSharedTrip?.itinerary?.days.length > 0 && (
            <DaysSidebar
              days={sharedData.fullSharedTrip.itinerary.days}
              onDayClick={handleMenuDayClick}
              onStepClick={handleMenuStepClick}
              tripType={
                sharedData?.fullSharedTrip?.tripType
                  ? sharedData.fullSharedTrip.tripType
                  : 'itinerary'
              }
              trip={sharedData?.fullSharedTrip}
              currentDayId={selectedDayId}
              stepInView={stepInView}
              numberOfHiddenDays={
                sharedData?.fullSharedTrip?.price_id
                  ? sharedData.fullSharedTrip.length
                  : undefined
              }
            />
          )}
      </Stack>
    </>
  );
}
