import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import CardTravelTwoToneIcon from '@mui/icons-material/CardTravelTwoTone';
import FavoriteTwoToneIcon from '@mui/icons-material/FavoriteTwoTone';
import TravelExploreTwoToneIcon from '@mui/icons-material/TravelExploreTwoTone';
import {
  Box,
  Container,
  BottomNavigation,
  BottomNavigationAction,
  Divider,
  Typography,
  Link,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { TextButton } from '../theme-components/Buttons';
import { isRunningStandalone } from '../utils/helpers';
import { RoutePage } from './route';
import { LogoName } from './AppBar';
import Space from '../theme-components/Spacing';
import { Spa } from '@mui/icons-material';
import { useAuth } from './auth/firebase';
import SignUp from './auth/SignUp';

export default function AppFooter() {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md')); // Detect if the screen size is mobile
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detect if the screen size is mobile

  return (
    <>
      {isTablet ? (
        <>
          <BottomTabBar />
        </>
      ) : (
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            backgroundColor: '#f9f9f9',
          }}
        >
          <Divider
            sx={{
              width: '100%',
            }}
          />
          <RoutePage>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                paddingBottom: 2,
              }}
            >
              <Box
                sx={theme => ({
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'left',
                  fontSize: '14px',
                  color: '#595959',
                  maxWidth: '60%',
                })}
              >
                <LogoName>rooutie</LogoName>
                <Typography sx={{ fontSize: 12, fontWeight: 500 }}>
                  71-75, Shelton Street, Covent Garden, London, WC2H 9JQ
                </Typography>
                <Space size="xs" />
                <Typography sx={{ fontSize: 12, fontWeight: 500 }}>
                  © 2024 ROOUTIE LTD
                </Typography>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'left',
                }}
              >
                <Space size="xs" />
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 'bold',
                    color: '#3F3D56',
                  }}
                >
                  About
                </Typography>
                <Link
                  href="/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    textDecoration: 'none',
                    color: '#595959',
                    fontWeight: 500,
                    fontSize: 12,
                  }}
                >
                  Terms of Use
                </Link>
                <Space size="xs" />
                <Link
                  href="/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    textDecoration: 'none',
                    color: '#595959',
                    fontWeight: 500,
                    fontSize: 12,
                  }}
                >
                  Privacy Policy
                </Link>
                <Space size="xs" />
                <Link
                  href="/cookies"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    textDecoration: 'none',
                    color: '#595959',
                    fontWeight: 500,
                    fontSize: 12,
                  }}
                >
                  Cookies Policy
                </Link>
                <Space size="xs" />
                <Link
                  href="/disclaimer"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    textDecoration: 'none',
                    color: '#595959',
                    fontWeight: 500,
                    fontSize: 12,
                  }}
                >
                  Disclaimer
                </Link>
                <Space size="xs" />
                <Link
                  href="mailto:help@rooutie.com"
                  sx={{
                    textDecoration: 'none',
                    color: '#595959',
                    fontWeight: 500,
                    fontSize: 12,
                  }}
                >
                  Contact Us
                </Link>
              </Box>
            </Box>
          </RoutePage>
        </Box>
      )}
    </>
  );
}

const BottomTabBar = () => {
  const [value, setValue] = useState(0); // State to manage active tab
  const navigate = useNavigate(); // React Router navigation hook
  const theme = useTheme();
  const { isLoading, isAuthenticated } = useAuth();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detect if the screen size is mobile
  const isEditor = window.location.href.includes('/editor/');
  const isTrip = window.location.href.includes('/trip/');
  const isPreview = window.location.href.includes('/preview/');
  const [showSignUpModal, setShowSignUpModal] = useState<boolean>(false);

  const isStandalone = isRunningStandalone();
  // Handle tab change and navigation
  const handleChange = (event, newValue) => {
    if (isAuthenticated) {
      setValue(newValue);
      if (newValue === 0) {
        navigate('/explore'); // Navigate to "My Account" (Home)
      } else if (newValue === 1) {
        navigate('/favourite'); // Navigate to "Help"
      } else if (newValue === 2) {
        navigate('/my-trips'); // Navigate to "Main" (Explore)
      } else if (newValue === 3) {
        navigate('/account'); // Navigate to "Help"
      }
    } else if (!isLoading) {
      if (newValue === 0) {
        navigate('/explore'); // Navigate to "My Account" (Home)
      } else {
        setShowSignUpModal(true); // Navigate to "Help"
      }
    }
  };

  let width = '100%';

  if ((isEditor || isTrip || isPreview) && !isMobile) {
    width = '53%';
  }
  return (
    <Box
      sx={{
        width: width,
        position: 'fixed',
        bottom: 0,
        right: 0,
        zIndex: 960,
        paddingTop: 1,
        backgroundColor: '#fffaf5',
        transform: 'translateZ(0)', // Fix for iOS
        willChange: 'transform', // Helps with rendering stability
        overflow: 'visible',
        borderTop: '1px solid', // Add top border
        borderColor: 'rgba(0, 0, 0, 0.12)', // Customize the color of the border
      }}
    >
      <BottomNavigation
        value={value}
        onChange={handleChange}
        // sx={{
        //   height: isStandaone
        //     ? 'calc(60px + env(safe-area-inset-bottom))'
        //     : '60px ',
        //   backgroundColor: '#fffaf5',
        //   paddingBottom: isStandaone
        //     ? 'calc(env(safe-area-inset-bottom))'
        //     : '10px ',
        //   // paddingTop: '10px',
        // }}
        sx={{
          height: isStandalone
            ? 'calc(50px + env(safe-area-inset-bottom, 0px))' // Fallback to 0px
            : '60px',
          backgroundColor: '#fffaf5',
          paddingBottom: isStandalone
            ? 'calc(env(safe-area-inset-bottom, 0px))' // Fallback to 0px
            : '10px',
        }}
      >
        <BottomNavigationAction
          label="Explore"
          showLabel={true}
          sx={theme => ({
            fontWeight: 700,
            color:
              value === 0
                ? theme.palette.primary.main
                : theme.palette.icon.main,
          })}
          icon={
            <TravelExploreTwoToneIcon
              sx={theme => ({
                height: 30,
                width: 30,
                color:
                  value === 0
                    ? theme.palette.primary.main
                    : theme.palette.icon.main,
              })}
            />
          }
        />

        <BottomNavigationAction
          label="Favourite"
          showLabel={true}
          sx={theme => ({
            fontWeight: 700,
            color:
              value === 1
                ? theme.palette.primary.main
                : theme.palette.icon.main,
          })}
          icon={
            <FavoriteTwoToneIcon
              sx={theme => ({
                height: 30,
                width: 30,
                color:
                  value === 1
                    ? theme.palette.primary.main
                    : theme.palette.icon.main,
              })}
            />
          }
        />
        <BottomNavigationAction
          label="My trips"
          showLabel={true}
          sx={theme => ({
            fontWeight: 700,
            color:
              value === 2
                ? theme.palette.primary.main
                : theme.palette.icon.main,
          })}
          icon={
            <CardTravelTwoToneIcon
              sx={theme => ({
                height: 30,
                width: 30,
                color:
                  value === 2
                    ? theme.palette.primary.main
                    : theme.palette.icon.main,
              })}
            />
          }
        />
        <BottomNavigationAction
          label="Account"
          showLabel={true}
          sx={theme => ({
            fontWeight: 700,
            color:
              value === 3
                ? theme.palette.primary.main
                : theme.palette.icon.main,
          })}
          icon={
            <AccountCircleTwoToneIcon
              sx={theme => ({
                height: 30,
                width: 30,
                color:
                  value === 3
                    ? theme.palette.primary.main
                    : theme.palette.icon.main,
              })}
            />
          }
        />
      </BottomNavigation>
      <SignUp
        showInModal={true}
        open={showSignUpModal}
        loginDefault={false}
        onClose={() => setShowSignUpModal(false)}
      />
    </Box>
  );
};
