import { Box, Container } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

import AuthorizedApolloProvider from '../AuthorizedApolloProvider';
import { useAuth } from '../components/auth/firebase';
import { CreateUserTrip } from '../components/create-trip/CreateUserTrip';
import { RoutePage } from '../components/route';
import { LoaderFullScreen } from '../theme-components/Loader';
import { isRunningStandalone } from '../utils/helpers';
import { useLazyQuery } from '@apollo/client';
import { User } from '../generated/user_graphql';
import { QUERY_GET_USER } from '../components/gql-user/user';
import { QUERY_GET_USER_TRIPS_WITH_DAYS_ONLY } from '../components/gql-user/userTripsQuery';

export default function CreateTripRoute() {
  const { isLoading } = useAuth();
  const [isStandalone, setIsStandalone] = useState<boolean>(false);

  useEffect(() => {
    // Run isRunningStandalone only after the component has mounted
    if (typeof window !== 'undefined') {
      const result = isRunningStandalone();
      setIsStandalone(result);
    }
  }, []); // Empty dependency array ensures this runs only after initial render (on mount)

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = ''; // Restore scroll
    document.body.style.position = ''; // Reset positioning
  }, []);

  if (isLoading) {
    return <LoaderFullScreen />;
  }

  return (
    <Box
      sx={theme => ({
        width: '100%',
        paddingTop: '60px',
        marginTop: '-60px',
        height: '100%',
        backgroundColor: 'rgba(255, 248, 238, 0.7)',
      })}
    >
      <Box sx={{ minHeight: '100vh', paddingBottom: 10, width: '100%' }}>
        <CreateUserTrip />
        {isStandalone && (
          <Box
            sx={{ paddingBottom: 'calc(130px + env(safe-area-inset-bottom))' }}
          />
        )}
      </Box>
    </Box>
  );
}
