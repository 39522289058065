import AccessTimeTwoToneIcon from '@mui/icons-material/AccessTimeTwoTone';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CreditCardTwoToneIcon from '@mui/icons-material/CreditCardTwoTone';
import DirectionsIcon from '@mui/icons-material/Directions';
import EventTwoToneIcon from '@mui/icons-material/EventTwoTone';
import HistoryToggleOffTwoToneIcon from '@mui/icons-material/HistoryToggleOffTwoTone';
import InfoIcon from '@mui/icons-material/Info'; // Import the Info icon
import LanguageIcon from '@mui/icons-material/Language';
import LocalActivityTwoToneIcon from '@mui/icons-material/LocalActivityTwoTone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MapIcon from '@mui/icons-material/Map';
import PhoneIcon from '@mui/icons-material/Phone';
import PlaceTwoToneIcon from '@mui/icons-material/PlaceTwoTone';
import UpdateTwoToneIcon from '@mui/icons-material/UpdateTwoTone';
import WatchLaterTwoToneIcon from '@mui/icons-material/WatchLaterTwoTone';
import ContentCopyTwoToneIcon from '@mui/icons-material/ContentCopyTwoTone';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import StarTwoToneIcon from '@mui/icons-material/StarTwoTone';

import {
  Box,
  Typography,
  Link,
  Stack,
  styled,
  Tooltip,
  IconButton,
  Grid,
  Button,
  Divider,
  Rating,
} from '@mui/material';
import Modal from '@mui/material/Modal';
import zIndex from '@mui/material/styles/zIndex';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Activity,
  Maybe,
  Place,
  PlaceHours,
  Step,
} from '../../generated/user_graphql';
import {
  setOpenHoursModal,
  setPlaceIdForOpenHours,
  stepsSelector,
} from '../../store/StepSlice';
import { LinkButton, ResponsiveChip } from '../../theme-components/Buttons';
import {
  CustomBookingComBlueIcon,
  GetYourGuideIcon,
} from '../../theme-components/Icons';
import Space from '../../theme-components/Spacing';
import TextDescription, {
  Body1,
  TitleH3,
  TitleH4,
} from '../../theme-components/Typography';
import {
  formatReviewString,
  formatSecondsToTime,
  openLinkInBrowserNewTab,
} from '../../utils/helpers';
import GoogleIcon from '../icons/GoogleIcon';
import { RenderHtmlFromResponseNoMargin } from '../RenderHtmlFromResponse';
import { CenteredModal, MoreDetailsModalPaper } from '../styling/modal';

import { PriceInfo } from './PublicPlaceCard';
import PlaceCardCTAs from './PublicPlaceCardCTA';
import { ImageCard } from './StepImages';
import ActivityCardCTAs from './PublicActivityCardCTA';
import SocialLinksRow from './place-details/SocialLinks';

interface PlaceMoreInfoModalProps {
  step?: Maybe<Step>;
  place?: Maybe<Place>;
  activity?: Maybe<Activity>;
  onClose?: () => void;
  showCloseButton?: boolean;
}

export const PlaceOrActivityMoreInfoModal: React.FC<
  PlaceMoreInfoModalProps
> = ({ place, activity, onClose }) => {
  if (!place && !activity) {
    return <></>;
  }

  return (
    <CenteredModal
      open={true}
      onClose={onClose}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent dark background
          backdropFilter: 'blur(10px)', // Apply the blur effect
        },
      }}
    >
      <MoreDetailsModalPaper>
        <PlaceOrActivityMoreInfo
          place={place}
          activity={activity}
          onClose={onClose}
          showCloseButton={true}
        />
      </MoreDetailsModalPaper>
    </CenteredModal>
  );
};

export const PlaceOrActivityMoreInfo: React.FC<PlaceMoreInfoModalProps> = ({
  step,
  place,
  activity,
  onClose,
  showCloseButton,
}) => {
  const [copied, setCopied] = useState(false);
  const handleCopy = () => {
    if (place && place.address) {
      navigator.clipboard.writeText(place.address);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } // Reset copy state after 2 seconds
  };

  if (!place && !activity && !step) {
    if (onClose) {
      onClose();
    }
  }

  const weekDayOrder = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];

  let orderedHours: Maybe<PlaceHours>[] = [];
  if (place && place.openingHours && place.openingHours.length > 0) {
    orderedHours = [...place.openingHours];
    orderedHours = orderedHours.sort(
      (a, b) =>
        weekDayOrder.indexOf(a?.weekDay ?? '') -
        weekDayOrder.indexOf(b?.weekDay ?? ''),
    );
  }

  return (
    <Box sx={{ backgroundColor: '#fffaf5' }}>
      <Stack
        justifyContent="space-between"
        width="100%"
        direction="row"
        sx={{
          // display: 'block',
          position: showCloseButton ? 'sticky' : 'relative',
          top: 0,
          backgroundColor: 'inherit',
          zIndex: 100,
          paddingX: 2,
          paddingTop: showCloseButton ? 2 : 1,
          paddingBottom: 0.5,
          boxShadow: showCloseButton
            ? '0px 4px 8px rgba(0, 0, 0, 0.07)'
            : '0px 0px 0px rgba(0, 0, 0, 0)',
        }}
      >
        <Box>
          {showCloseButton ? (
            <>
              {step && step.title ? (
                <TitleH3>{step.title}</TitleH3>
              ) : (
                <>
                  {place && <TitleH3>{place.title}</TitleH3>}
                  {activity && <TitleH3>{activity.name}</TitleH3>}
                </>
              )}
            </>
          ) : (
            <>
              {step && step.title ? (
                <TitleH4>{step.title}</TitleH4>
              ) : (
                <>
                  {place && <TitleH4>{place.title}</TitleH4>}
                  {activity && <TitleH4>{activity.name}</TitleH4>}
                </>
              )}
            </>
          )}
        </Box>
        {showCloseButton && (
          <Box>
            <IconButton
              onClick={onClose}
              sx={
                {
                  // position: 'absolute',
                  // top: 0,
                  // right: 0,
                  // color: '#36454F',
                }
              }
            >
              <CancelTwoToneIcon
                sx={theme => ({
                  width: 30,
                  height: 30,
                  marginTop: -1,
                  color: theme.palette.icon.main,
                })}
              />
            </IconButton>
          </Box>
        )}
      </Stack>
      <Stack
        direction={'column'}
        spacing={0.5}
        sx={{
          // display: 'block',
          // position: 'sticky',
          // top: 0,
          // backgroundColor: 'inherit',
          // zIndex: 100,
          backgroundColor: 'inherit',
          paddingX: 2,
          paddingY: showCloseButton ? 2 : 0,
        }}
      >
        <Box>
          {place?.rating && (
            <>
              <Stack direction="row" alignItems="center">
                {/* <Box
                  sx={theme => ({
                    width: 25,
                    height: 25,
                    marginRight: 0.5,
                    color: '#AB88F3',
                    display: 'flex', // Enable flexbox layout
                    alignItems: 'center', // Vertically center content
                    justifyContent: 'center', // Horizontally center content
                    [theme.breakpoints.down('sm')]: {},
                  })}
                > */}
                <StarTwoToneIcon
                  sx={theme => ({
                    width: 20,
                    height: 20,
                    marginRight: 0.5,

                    // color: 'rgb(250, 175, 0)',
                    color: '#9E7FEF',
                  })}
                />
                {/* </Box> */}
                {place.rating && (
                  <Stack direction="row" spacing={0.5}>
                    <>
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: 14,
                          alignSelf: 'center',
                          color: '#36454F',
                          marginRight: 0.5,
                        }}
                      >
                        {place.rating}{' '}
                      </Typography>
                      {place.noOfReviews && (
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: 14,
                            alignSelf: 'center',
                            color: '#6E7191',
                            marginRight: 0.5,
                          }}
                        >
                          ~{formatReviewString(place.noOfReviews)}
                        </Typography>
                      )}
                    </>
                  </Stack>
                )}
              </Stack>
            </>
          )}
          {activity?.rating && (
            <>
              <Stack direction="row" alignItems="center">
                {/* <Box
                  sx={theme => ({
                    width: 25,
                    height: 25,
                    marginRight: 0.5,
                    color: '#AB88F3',
                    display: 'flex', // Enable flexbox layout
                    alignItems: 'center', // Vertically center content
                    justifyContent: 'center', // Horizontally center content
                    [theme.breakpoints.down('sm')]: {},
                  })}
                > */}
                <StarTwoToneIcon
                  sx={theme => ({
                    width: 20,
                    height: 20,
                    marginRight: 0.5,

                    // color: 'rgb(250, 175, 0)',
                    color: '#9E7FEF',
                  })}
                />
                {/* </Box> */}
                {activity.rating && (
                  <Stack direction="row" spacing={0.5}>
                    {activity.noOfReviews && (
                      <>
                        <Typography
                          sx={{
                            fontWeight: 600,
                            fontSize: 14,
                            alignSelf: 'center',
                            color: '#36454F',
                            marginRight: 0.5,
                          }}
                        >
                          {activity.rating}{' '}
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: 14,
                            alignSelf: 'center',
                            color: '#6E7191',
                            marginRight: 0.5,
                          }}
                        >
                          ~{formatReviewString(activity.noOfReviews)}
                        </Typography>
                      </>
                    )}
                  </Stack>
                )}
              </Stack>
            </>
          )}
        </Box>
        {place && (
          <PriceInfo
            minPrice={place.minPrice}
            maxPrice={place.maxPrice}
            admissionPrice={place.admissionPrice}
            priceCategory={place.priceCategory}
            priceCurrency={place.priceCurrency}
          />
        )}
        {activity && !!activity.durationInMinutes && (
          <>
            <Stack direction="row" alignItems="center">
              <UpdateTwoToneIcon
                sx={theme => ({
                  width: 20,
                  height: 20,
                  marginRight: 0.5,
                  // color: theme.palette.icon.main,
                  color: '#9E7FEF',
                })}
              />
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: 14,
                  alignSelf: 'center',
                  color: '#36454F',
                  marginRight: 0.5,
                }}
              >
                Takes:&nbsp;
                <Typography
                  component="span"
                  sx={{
                    fontWeight: 500,
                    fontSize: 14,
                    alignSelf: 'center',
                    color: '#6E7191',
                    marginRight: 0.5,
                  }}
                >
                  {formatSecondsToTime(activity.durationInMinutes * 60)}
                </Typography>
              </Typography>
            </Stack>
          </>
        )}
        {activity && (
          <PriceInfo
            minPrice={activity.minPrice}
            maxPrice={activity.maxPrice}
            admissionPrice={null}
            priceCategory={activity.priceCategory}
            priceCurrency={activity.priceCurrency}
          />
        )}
        {activity &&
          !!activity.validForInMinutes &&
          !activity.durationInMinutes && (
            <>
              <Stack direction="row" alignItems="center" sx={{ mb: 0.5 }}>
                <UpdateTwoToneIcon
                  sx={theme => ({
                    width: 20,
                    height: 20,
                    marginRight: 1,
                    // color: theme.palette.icon.main,
                    color: '#9E7FEF',
                  })}
                />
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: 14,
                    alignSelf: 'center',
                    color: '#36454F',
                    marginRight: 0.5,
                  }}
                >
                  Valid:&nbsp;
                  <Typography
                    component="span"
                    sx={{
                      fontWeight: 500,
                      fontSize: 14,
                      alignSelf: 'center',
                      color: '#6E7191',
                      marginRight: 0.5,
                    }}
                  >
                    {formatSecondsToTime(activity.validForInMinutes * 60)}
                  </Typography>
                </Typography>
              </Stack>
            </>
          )}
        {place?.checkInTime && (
          <>
            <Stack direction="row" alignItems="center">
              <HistoryToggleOffTwoToneIcon
                sx={theme => ({
                  width: 20,
                  height: 20,
                  marginRight: 0.5,
                  // color: theme.palette.icon.main,
                  color: '#9E7FEF',
                })}
              />
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: 14,
                  alignSelf: 'center',
                  color: '#36454F',
                  marginRight: 0.5,
                }}
              >
                Check In:
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: 14,
                  alignSelf: 'center',
                  color: '#6E7191',
                  marginRight: 0.5,
                }}
              >
                {place.checkInTime && place.checkInTime}
              </Typography>
            </Stack>
          </>
        )}
        {place?.checkOutTime && (
          <>
            <Stack direction="row" alignItems="center">
              <UpdateTwoToneIcon
                sx={theme => ({
                  width: 20,
                  height: 20,
                  marginRight: 0.5,
                  // color: theme.palette.icon.main,
                  color: '#9E7FEF',
                })}
              />
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: 14,
                  alignSelf: 'center',
                  color: '#36454F',
                  marginRight: 0.5,
                }}
              >
                Check Out:
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: 14,
                  alignSelf: 'center',
                  color: '#6E7191',
                  marginRight: 0.5,
                }}
              >
                {place.checkInTime && place.checkOutTime}
              </Typography>
            </Stack>
          </>
        )}
        {place && place.website && place.website !== '' && (
          <>
            <Stack direction="row" alignItems="center">
              <LanguageIcon
                sx={theme => ({
                  width: 20,
                  height: 20,
                  marginRight: 0.5,
                  // color: theme.palette.icon.main,
                  color: '#9E7FEF',
                })}
              />
              <Box
                onClick={() => {
                  openLinkInBrowserNewTab(place!.website!);
                  gtag('event', 'public-ext-link-website');
                }}
                sx={{
                  fontWeight: 600,
                  fontSize: 14,
                  alignSelf: 'center',
                  color: '#36454F',
                  textDecoration: 'underline',
                  textDecorationColor: '#B0B0B0', // Lighter color for the underline
                  cursor: 'pointer',
                }}
              >
                Visit website
              </Box>
            </Stack>
          </>
        )}
        {/* Contact Details */}
        {place && place && place.phoneNumber && (
          <>
            <Stack direction="row" alignItems="center">
              <PhoneIcon
                sx={theme => ({
                  width: 20,
                  height: 20,
                  marginRight: 0.5,
                  // color: theme.palette.icon.main,
                  color: '#9E7FEF',
                })}
              />
              <Link
                href={`tel:${place && place.phoneNumber}`}
                sx={{
                  fontWeight: 600,
                  fontSize: 14,
                  alignSelf: 'center',
                  color: '#36454F',
                  textDecoration: 'underline',
                  textDecorationColor: '#B0B0B0',
                }}
              >
                {place.phoneNumber}
              </Link>
            </Stack>
          </>
        )}
        {/* Address */}
        {place && place && place.address && (
          <>
            <Stack direction="row" alignItems="flex-start">
              <LocationOnIcon
                sx={theme => ({
                  width: 20,
                  height: 20,
                  marginRight: 0.5,
                  // color: theme.palette.icon.main,
                  color: '#9E7FEF',
                })}
              />
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: 14,
                  alignSelf: 'center',
                  color: '#36454F',
                }}
              >
                {place.address}
              </Typography>
              <IconButton
                onClick={handleCopy}
                sx={{
                  padding: 0,
                  marginLeft: 1,
                  color: '#36454F',
                }}
              >
                <ContentCopyTwoToneIcon
                  sx={theme => ({
                    alignSelf: 'center',
                    width: 18,
                    height: 18,
                    // marginTop: 0.25,
                    color: theme.palette.icon.main,
                  })}
                />
              </IconButton>
            </Stack>
          </>
        )}
        {place?.bookingLink && (
          <>
            <Stack direction="row" alignItems="center">
              <EventTwoToneIcon
                sx={theme => ({
                  width: 20,
                  height: 20,
                  marginRight: 0.5,
                  // color: theme.palette.icon.main,
                  color: '#9E7FEF',
                })}
              />

              <LinkButton
                url={place.bookingLink}
                type="external"
                prominent={true}
                sx={{ fontSize: 14 }}
              >
                Check availability
              </LinkButton>
            </Stack>
          </>
        )}
        {activity?.bookingLink && (
          <>
            <Stack direction="row" alignItems="center">
              <EventTwoToneIcon
                sx={theme => ({
                  width: 20,
                  height: 20,
                  marginRight: 0.5,
                  // color: theme.palette.icon.main,
                  color: '#9E7FEF',
                })}
              />
              <LinkButton
                url={activity.bookingLink}
                type="external"
                prominent={true}
                sx={{ fontSize: 14 }}
              >
                Check availability
              </LinkButton>
            </Stack>
          </>
        )}
        {activity && activity.bookingLink && activity.bookingLink !== '' && (
          <>
            <Stack direction="row" alignItems="center">
              <LanguageIcon
                sx={theme => ({
                  width: 20,
                  height: 20,
                  marginRight: 0.5,
                  // color: theme.palette.icon.main,
                  color: '#9E7FEF',
                })}
              />
              <Box
                onClick={() => {
                  openLinkInBrowserNewTab(activity!.bookingLink!);
                  gtag('event', 'public-ext-link-website');
                }}
                sx={{
                  fontWeight: 600,
                  fontSize: 14,
                  alignSelf: 'center',
                  color: '#36454F',
                  textDecoration: 'underline',
                  textDecorationColor: '#B0B0B0', // Lighter color for the underline
                  cursor: 'pointer',
                }}
              >
                Visit website
              </Box>
            </Stack>
          </>
        )}
        {orderedHours && orderedHours.length > 0 && (
          <Box>
            <Stack direction="row">
              <WatchLaterTwoToneIcon
                sx={theme => ({
                  width: 20,
                  height: 20,
                  marginRight: 0.5,
                  // color: theme.palette.icon?.main,
                  color: '#9E7FEF',
                })}
              />
              {/* <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: 14,
                  alignSelf: 'center',
                  color: '#36454F',
                  marginRight: 0.5,
                }}
              >
                Opening Hours
              </Typography> */}
              <Box>
                {orderedHours &&
                  orderedHours.map((hours, index) => (
                    <Stack direction="row" alignItems="center" key={index}>
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: 14,
                          alignSelf: 'center',
                          color: '#36454F',
                          marginRight: 0.5,
                          minWidth: '100px', // Set a minimum width for the day
                        }}
                      >
                        {hours && hours.weekDay}:
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: 14,
                          alignSelf: 'center',
                          color: '#6E7191',
                          marginRight: 0.5,
                        }}
                      >
                        {hours && formatAmPmSpacing(hours.hours)}
                      </Typography>
                    </Stack>
                  ))}
              </Box>
            </Stack>
          </Box>
        )}
        {/* {place && !activity && (
          <PlaceCardCTAs place={place} hideMoreInfo={true} />
        )} */}
        <SocialLinksRow
          step={step}
          place={place}
          activity={activity}
          displayedInItinerary={false}
        />
        {/* {activity && !place && (
          <ActivityCardCTAs activity={activity} hideMoreInfo={true} />
        )}

        {!place && !activity && step && step.latitude && step.longitude && (
          <PlaceCardCTAs
            lat={step.latitude}
            lng={step.longitude}
            hideMoreInfo={true}
          />
        )} */}
        {step && step.images && step.images.length > 0 ? (
          <>
            <Grid container spacing={1}>
              {step.images.slice(0, 6).map((image, index) => (
                <Grid item xs={6} sm={4} key={image!.path}>
                  <Box
                    sx={{
                      position: 'relative',
                      width: '100%',
                      overflow: 'hidden', // Hide anything outside the box
                      borderRadius: '10px', // Optional: to add rounded corners
                    }}
                  >
                    <ImageCard
                      imageUrl={image && image.path ? image.path : ''}
                      path={image && image.path ? image.path : ''}
                      imageId={image && image.id ? image.id : ''}
                      title={'Place image'}
                      index={index}
                    />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </>
        ) : (
          <>
            {!place?.category?.toLowerCase().includes('car_rental') &&
              !place?.category?.toLowerCase().includes('airport') && (
                <>
                  {place && place.images && place.images.length > 0 && (
                    <Grid container spacing={1}>
                      {place.images.slice(0, 6).map((image, index) => (
                        <>
                          {image && (
                            <>
                              {(!!image.path ||
                                !!image.previewPath ||
                                !!image.mediumPath ||
                                !!image.smallPath ||
                                image.fromOwner === true) && (
                                <Grid item xs={6} sm={4} key={image!.path}>
                                  <Box
                                    sx={{
                                      position: 'relative',
                                      width: '100%',
                                      overflow: 'hidden', // Hide anything outside the box
                                      borderRadius: '10px', // Optional: to add rounded corners
                                    }}
                                  >
                                    <ImageCard
                                      imageUrl={
                                        image && image.imageUrl
                                          ? image.imageUrl
                                          : ''
                                      }
                                      imageId={
                                        image && image.id ? image.id : ''
                                      }
                                      path={
                                        image?.previewPath ||
                                        image?.mediumPath ||
                                        image?.smallPath ||
                                        image?.path ||
                                        ''
                                      }
                                      title={
                                        place?.title
                                          ? place.title
                                          : 'Place image'
                                      }
                                      index={index}
                                    />
                                  </Box>
                                </Grid>
                              )}
                            </>
                          )}
                        </>
                      ))}
                    </Grid>
                  )}
                </>
              )}

            {activity && activity.images && activity.images.length > 0 && (
              <Grid container spacing={1}>
                {activity.images.slice(0, 6).map((image, index) => (
                  <Grid item xs={6} sm={4} key={image!.path}>
                    <Box
                      sx={{
                        position: 'relative',
                        width: '100%',
                        overflow: 'hidden', // Hide anything outside the box
                        borderRadius: '10px', // Optional: to add rounded corners
                      }}
                    >
                      <ImageCard
                        imageUrl={image && image.imageUrl ? image.imageUrl : ''}
                        path={image && image.path ? image.path : ''}
                        imageId={image && image.id ? image.id : ''}
                        title={
                          activity?.name ? activity.name : 'Activity image'
                        }
                        index={index}
                      />
                    </Box>
                  </Grid>
                ))}
              </Grid>
            )}
          </>
        )}
        {(step?.description ||
          place?.aiDescription ||
          place?.description ||
          activity?.aiDescription ||
          activity?.description) && (
          <>
            <Space size="sm" />
            <Divider sx={{ width: '100%', mx: 'auto' }} />
            <Space size="xs" />
          </>
        )}
        {step && step.description ? (
          <Body1>
            <RenderHtmlFromResponseNoMargin content={step.description} />
          </Body1>
        ) : (
          <>
            {place && place.aiDescription ? (
              <Body1>
                <RenderHtmlFromResponseNoMargin content={place.aiDescription} />
              </Body1>
            ) : (
              <>
                {place && place.description && (
                  <Body1>
                    <RenderHtmlFromResponseNoMargin
                      content={place.description}
                    />
                  </Body1>
                )}
              </>
            )}
            {activity && activity.aiDescription ? (
              <Body1>
                <RenderHtmlFromResponseNoMargin
                  content={activity.aiDescription}
                />
              </Body1>
            ) : (
              <>
                {activity && activity.description && (
                  <Body1>
                    <RenderHtmlFromResponseNoMargin
                      content={activity.description}
                    />
                  </Body1>
                )}
              </>
            )}
          </>
        )}
      </Stack>
    </Box>
  );
};

export const TodaysHours = ({ place }) => {
  const dispatch = useDispatch();
  const [moreInfoOpenedId, setMoreInfoOpenedId] = useState('');

  if (!place || !place.openingHours || place.openingHours.length === 0) {
    return null;
  }

  // Get the current day of the week
  const today = new Date().toLocaleString('en-us', { weekday: 'long' });
  // Find today's opening hours
  const todayHours = place.openingHours.find(hours => hours.weekDay === today);

  return (
    <Stack
      direction="row"
      alignItems="center"
      onClick={() => {
        gtag('event', 'add-trip-start-date-button');
        setMoreInfoOpenedId(place.id);
      }}
    >
      <AccessTimeTwoToneIcon
        sx={theme => ({
          width: 20,
          height: 20,
          marginRight: 1,
          // color: '#9E7FEF',
          // color: theme.palette.icon.main,
          color: '#9E7FEF',
        })}
      />
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: 14,
          alignSelf: 'center',
          color: '#36454F',
          marginRight: 0.5,
        }}
      >
        {today}:
      </Typography>
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: 14,
          alignSelf: 'center',
          color: '#6E7191',
          marginRight: 0.5,
          textDecoration: 'underline',
        }}
      >
        {todayHours && formatAmPmSpacing(todayHours.hours)}
      </Typography>
      {/* <TextButton
        onClick={() => {
          gtag('event', 'add-trip-start-date-button');
          setMoreInfoOpenedId(place.id);
        }}
      >
        see more
      </TextButton>*/}
      {place.id === moreInfoOpenedId && (
        <>
          {place && (
            <PlaceOrActivityMoreInfoModal
              place={place}
              onClose={() => setMoreInfoOpenedId('')}
            />
          )}
        </>
      )}
    </Stack>
  );
};

export const formatAmPmSpacing = (str: string): string => {
  if (!str) return str;

  let formattedStr = str;

  // Insert space before 'am' or 'pm' if directly preceded by a digit
  formattedStr = formattedStr.replace(/(\d)(am|pm)/gi, '$1 $2');

  // Insert space after 'am' or 'pm' if directly followed by a digit
  formattedStr = formattedStr.replace(/(am|pm)(\d)/gi, '$1 $2');

  return formattedStr;
};

function truncateWithEllipsis(str, maxLength = 18) {
  if (str.length > maxLength) {
    return str.substring(0, maxLength) + '...';
  }
  return str;
}

function cleanUrl(url) {
  const prefix = 'www.';
  if (url.startsWith('https://')) {
    url = url.slice(8); // Length of "https://"
  } else if (url.startsWith('http://')) {
    url = url.slice(7); // Length of "http://"
  }

  if (url.includes('/')) {
    const index = url.indexOf('/');
    if (index === -1) {
      if (!url.startsWith(prefix)) {
        url = prefix + url;
      }
      return url;
    }
    url = url.substring(0, index);
    if (!url.startsWith(prefix)) {
      url = prefix + url;
    }

    return url;
  }
  if (!url.startsWith(prefix)) {
    url = prefix + url;
  }
  return url;
}

const TextButton = styled(Box)(({ theme }) => ({
  padding: 0,
  margin: 0,
  fontWeight: 600,
  fontSize: 14,
  display: 'flex', // Enable flexbox layout
  alignItems: 'center', // Vertically center content
  justifyContent: 'center', // Horizontally center content
  color: theme.palette.primary.main, // Use custom color if provided
  [theme.breakpoints.down('sm')]: {
    fontSize: 12,
  },
}));
